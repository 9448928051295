import React from 'react';
import { useUserPermissions } from '@EngagementsActiveNumbers/useUserPermissions';
import DataRowSidebar from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/DataRowSidebar';
import CommentsSidebar from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/CommentsSidebar';
import CancelOrderModal from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/CancelOrderModal';
import EditRowSidebarForm from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EditRowSidebarForm';
import EscalationSupportModal from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EscalationSupportModal';

const ActiveNumbersTableModals = () => {
  const { isVisibleOrderDataSidebar } = useUserPermissions();

  return (
    <>
      <EditRowSidebarForm />
      <EscalationSupportModal />
      <CancelOrderModal />
      <CommentsSidebar />
      {isVisibleOrderDataSidebar && <DataRowSidebar />}
    </>
  );
};

export default ActiveNumbersTableModals;
