import paths from 'core/routing/paths';
import { IRoutingConfigItem } from 'core/routing/models';
import WirelineEngagementsSowData from '@EngagementsSowData/index';
import SowDataContractRequestView from '@EngagementsSowData/SowDataContractRequestView';
import SowDataContractRequestCreate from '@EngagementsSowData/SowDataContractRequestCreate';
import WirelineEngagementsCreate from 'pages/WirelineEngagements/WirelineEngagementsCreate';
import RequestOrderFormView from '@EngagementsSowData/RequestOrderForm/RequestOrderFormView';
import WirelineEngagementsMainPage from 'pages/WirelineEngagements/WirelineEngagementsMainPage';
import RequestOrderFormCreate from '@EngagementsSowData/RequestOrderForm/RequestOrderFormCreate';
import RenewalSowDataContractRequestView from '@EngagementsRenewalSowData/RenewalSowDataContractRequestView';
import RenewalSowDataContractRequestCreate from '@EngagementsRenewalSowData/RenewalSowDataContractRequestCreate';
import WirelineEngagementsActiveNumbers from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsActiveNumbers';
import WirelineEngagementsRenewalSowData from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsRenewalSowData';

const wirelineEngagementsRoutes = [
  {
    path: paths.wirelineEngagementsList,
    component: WirelineEngagementsMainPage,
  },
  {
    path: paths.wirelineEngagementsCreate,
    component: WirelineEngagementsCreate,
  },
  {
    path: paths.wirelineEngagementsEditWithId,
    component: WirelineEngagementsCreate,
  },
  {
    path: paths.wirelineEngagementsSowData,
    component: WirelineEngagementsSowData,
  },
  {
    path: paths.wirelineEngagementsSowDataContractForm,
    component: SowDataContractRequestCreate,
  },
  {
    path: paths.wirelineEngagementsRenewalSowContractForm,
    component: RenewalSowDataContractRequestCreate,
  },
  {
    path: paths.wirelineEngagementsSowContractFormView,
    component: SowDataContractRequestView,
  },
  {
    path: paths.wirelineEngagementsRenewalContractFormView,
    component: RenewalSowDataContractRequestView,
  },
  {
    path: paths.wirelineEngagementsRequestOrder,
    component: RequestOrderFormCreate,
  },
  {
    path: paths.wirelineEngagementsRequestOrderView,
    component: RequestOrderFormView,
  },
  {
    path: paths.wirelineEngagementsActiveNumbers,
    component: WirelineEngagementsActiveNumbers,
  },
  {
    path: paths.wirelineEngagementsRenewalSow,
    component: WirelineEngagementsRenewalSowData,
  },
] as IRoutingConfigItem[];

export default wirelineEngagementsRoutes;
