/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { patchContractSpecialistActions } from 'core/api/contractManagment.api';

const initialState = {
  isLoading: false,
};

export const contractSpecialistAction = createAsyncThunk(
  'contractSpecialistAction',
  async (payload: {bundleId: number, email: string}, { rejectWithValue }) => {
    try {
      await patchContractSpecialistActions(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const contractSpecialistSlice = createSlice({
  name: 'contractSpecialist',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        contractSpecialistAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(contractSpecialistAction.rejected, contractSpecialistAction.fulfilled),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const contractSpecialistReducer = contractSpecialistSlice.reducer;
