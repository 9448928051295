import React from 'react';
import EngagementAccepted from '@EngagementsSowData/EngagementAccepted';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import OrdersActionsMenu from '@EngagementsSowData/SowDataOrdersActions/OrdersActionsMenu';
import { useSowDataActionsButtonPermissions } from '@EngagementsSowData/SowDataOrdersActions/useSowDataActionsButtonPermissions';
// eslint-disable-next-line no-unused-vars
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

interface ISowDataOrdersActionsProps {
  selectedOrders: ISowDataListItem[],
  setOrderAction: (orderAction: OrderActions) => void
  setIsActionsModalOpen: (isModalOpen: boolean) => void
}

const SowDataOrdersActions: React.FC<ISowDataOrdersActionsProps> = ({
  selectedOrders, setIsActionsModalOpen, setOrderAction,
}) => {
  const {
    isEngagementAcceptedVisible,
  } = useSowDataActionsButtonPermissions();

  return (
    <>
      <OrdersActionsMenu
        setOrderAction={setOrderAction}
        selectedOrders={selectedOrders}
        setIsActionsModalOpen={setIsActionsModalOpen}
      />
      {isEngagementAcceptedVisible && <EngagementAccepted />}
    </>
  );
};

export default SowDataOrdersActions;
