import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ButtonMenu from 'shared/ButtonMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTableContext } from 'shared/Table/TableContext';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { checkIfOrdersIsAttTSD } from '@EngagementsSowData/utilities';
// eslint-disable-next-line no-unused-vars
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import SowDataActionsRequest from '@EngagementsSowData/SowDataOrdersActions/OrdersActionsMenu/SowDataActionsRequest';
import SowDataActionsProvide from '@EngagementsSowData/SowDataOrdersActions/OrdersActionsMenu/SowDataActionsProvide';
import { useSowDataActionsButtonPermissions } from '@EngagementsSowData/SowDataOrdersActions/useSowDataActionsButtonPermissions';
import { checkIfOrdersRequestTypeIsNonCotractSupport } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';

import './SowDataActionsButton.scss';

interface IOrdersActionsMenuProps {
  selectedOrders: ISowDataListItem[],
  setIsActionsModalOpen: (isModalOpen: boolean) => void
  setOrderAction: (orderAction: OrderActions) => void
}

const OrdersActionsMenu: React.FC<IOrdersActionsMenuProps> = ({
  selectedOrders, setIsActionsModalOpen, setOrderAction,
}) => {
  const { rowSelection } = useTableContext();

  const {
    isProvideActionsHidden,
    isRequestActionsHidden,
    isUnavailableActionsBuntton,
  } = useSowDataActionsButtonPermissions();

  const hasRowNonAttTSD = checkIfOrdersIsAttTSD(selectedOrders);
  const hasRowRequestTypeIsNonContractOrder = checkIfOrdersRequestTypeIsNonCotractSupport(
    selectedOrders,
  );

  const isActionsBtnDisabled = isUnavailableActionsBuntton
  || hasRowNonAttTSD
  || hasRowRequestTypeIsNonContractOrder
  || isEmpty(rowSelection);

  return (
    <ButtonMenu
      buttonVariant="text"
      tooltipTitle="Actions"
      isDisabled={isActionsBtnDisabled}
      buttonContent={<MoreHorizIcon />}
      menuClassName="sow-menu-actions"
    >
      <>
        {
            !isRequestActionsHidden
              && (
                <SowDataActionsRequest
                  selectedOrders={selectedOrders}
                  setOrderAction={setOrderAction}
                  setIsActionsModalOpen={setIsActionsModalOpen}
                />
              )
          }
        {
            !isProvideActionsHidden
              && (
                <SowDataActionsProvide
                  selectedOrders={selectedOrders}
                  setOrderAction={setOrderAction}
                  setIsActionsModalOpen={setIsActionsModalOpen}
                />
              )
          }
      </>
    </ButtonMenu>
  );
};

export default OrdersActionsMenu;
