import React, { ReactElement, ReactNode, useState } from 'react';
import cx from 'classnames';
import {
  Box, Button, Drawer, IconButton, SxProps, Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDialog from 'shared/ConfirmDialog';
import Loader from 'shared/Loader';

import './CustomSidebar.scss';

interface ICustomSidebar {
  children: ReactNode,
  customBottomBlock?: JSX.Element | undefined;
  isOpen: boolean;
  onCloseSideBar: (isOpen: boolean)=> void;
  title: string;
  areButtonsVisible?: boolean;
  onClickSave?: () => void;
  isConfirmDialog?: boolean;
  preloaderComponent?: ReactElement;
  isLoading?: boolean;
  className?: string;
  topAction?: JSX.Element | undefined;
  drawerStyle?: SxProps<Theme>;
}

const CustomSidebar: React.FC<ICustomSidebar > = ({
  children,
  customBottomBlock,
  areButtonsVisible,
  isOpen,
  onCloseSideBar,
  title,
  onClickSave,
  isConfirmDialog,
  preloaderComponent,
  isLoading,
  className,
  topAction,
  drawerStyle,
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const onCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const onConfirmDialogConfirm = () => {
    onCloseConfirmDialog();
    onCloseSideBar(false);
  };

  const onClose = () => {
    if (isConfirmDialog) {
      setIsConfirmDialogOpen(true);
    } else {
      onCloseSideBar(false);
    }
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="right"
        open={isOpen}
        className={cx('custom-sidebar', className)}
        sx={drawerStyle as SxProps<Theme>}
      >
        <Box className="custom-sidebar__top">
          <Box className="custom-sidebar__title-wrap">
            <h3 className="custom-sidebar__title">
              {title}
            </h3>
            {topAction && topAction}
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
        {isLoading && preloaderComponent}
        <Box className="custom-sidebar__content">
          {children}
        </Box>
        {areButtonsVisible && (
        <Box className="custom-sidebar__buttons">
          {customBottomBlock || (
            <>
              <Button
                className="custom-sidebar__button"
                variant="contained"
                color="primary"
                onClick={onClickSave}
              >
                Save
              </Button>
              <Button
                className="custom-sidebar__button"
                onClick={onClose}
                variant="outlined"
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
        )}
      </Drawer>
      <ConfirmDialog
        confirmLabel="Leave"
        visible={isConfirmDialogOpen}
        onCancel={onCloseConfirmDialog}
        onConfirm={onConfirmDialogConfirm}
      />
    </>
  );
};

CustomSidebar.defaultProps = {
  customBottomBlock: undefined,
  areButtonsVisible: true,
  onClickSave: () => {},
  isConfirmDialog: true,
  preloaderComponent: <Loader />,
  isLoading: false,
  className: '',
  topAction: undefined,
  drawerStyle: {},
};

export default CustomSidebar;
