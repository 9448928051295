export const patchRenewalSowMessage = {
  error: 'Failed to update renewal SOW Date',
  success: 'Renewal Sow Data successfully updated',
};

export const getRenewalSowMessage = {
  error: 'Failed to fetch renewal SOW Date item',
};

export const editRenewalSowTitle = 'Edit Renewal SOW Date';
export const viewRenewalSowTitle = 'View Renewal SOW Date';

export const includedFields = ['product'];
