import { ITableCell } from 'shared/Table/Table.models';

export interface IContract {
  id: number;
  bundle: number;
  order: number;
  companyName: string;
  sellerFullname: string;
  contractSpecialistFullname: string;
  engagementId: number;
  engagementType: string;
  requestType: string;
  commissionType: string;
  acceptedDatetime: string;
  product: string;
  customerName: string,
  contractBundleId?: number,
  customerId: number,
}

export type ITableCellContract = ITableCell<IContract>;

export enum EngagementType {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED',
}

export enum RequestType {
  QUOTE = 'QUOTE',
  CONTRACT = 'CONTRACT',
  ORDER = 'ORDER',
  RENEWAL_QUOTE = 'RENEWAL_QUOTE',
  RENEWAL_CONTRACT = 'RENEWAL_CONTRACT',
}

export enum CommissionType {
  NEW = 'NEW',
  RE_ORDER = 'RE_ORDER',
  RENEWAL = 'RENEWAL',
  DOWNGRADE = 'DOWNGRADE',
  UPGRADE = 'UPGRADE',
  CANCELED = 'CANCELED',
}

export enum RequestActionTypes {
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  CONTRACT_SPECIALIST = 'CONTRACT_SPECIALIST'
}
