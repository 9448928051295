/* eslint-disable no-param-reassign */
import { postOrderCancelById } from 'core/api/sales.api';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { IOrderCancel } from '@EngagementsSowData/salesSow.model';

export interface ICancelOrder {
  isLoading: boolean
}

const initialState: ICancelOrder = {
  isLoading: false,
};

export const cancelOrderAction = createAsyncThunk(
  'cancelOrderAction',
  async ({ id, params }: { id: string | number, params: IOrderCancel },
    { rejectWithValue }) => {
    try {
      await postOrderCancelById(id, params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const cancelOrderSlice = createSlice({
  name: 'requestBundlesSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        cancelOrderAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(cancelOrderAction.rejected, cancelOrderAction.fulfilled),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const cancelOrderReducer = cancelOrderSlice.reducer;
