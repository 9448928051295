import React from 'react';
import { useFormikContext } from 'formik';
import CustomModal from 'shared/CustomModal';
import { orderActionsTitle } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import OrderItem from 'pages/WirelineEngagements/shared/ContractForm/RequestContractModal/OrderItem';
import { IContractRequestOrders } from 'pages/WirelineEngagements/shared/ContractForm/contractForm.model';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

interface IRequestContractModalProps {
  isModalOpen: boolean;
  selectedOrders: IContractRequestOrders;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

const RequestContractModal: React.FC<IRequestContractModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  selectedOrders,
}) => {
  const { submitForm } = useFormikContext();

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onConfirm = () => {
    submitForm();
    onClose();
  };

  const orderList = (
    <div>
      {selectedOrders.map((order) => (
        <OrderItem key={order.id} order={order} />
      ))}
    </div>
  );

  return (
    <CustomModal
      areButtonsVisible
      cancelButtonText="No"
      onCloseModal={onClose}
      confirmButtonText="Yes"
      isModalOpen={isModalOpen}
      onClickConfirm={onConfirm}
      title={orderActionsTitle[OrderActions.REQUEST_CONTRACTS]}
    >
      {orderList}
    </CustomModal>
  );
};

export default RequestContractModal;
