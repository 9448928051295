import React from 'react';
import { useFormikContext } from 'formik';
import { useAppSelector } from 'core/store';
import CustomModal from 'shared/CustomModal';
import OrderModalItem from 'shared/OrderModalItem';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';
import { ICancelOrderRowData } from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrderModalForm.model';

import './CancelOrderModal.scss';

interface ICancelOrderModalProps {
  title: string;
  reasonLabel: string;
  cleanActiveRow: () => void;
  activeRow: ICancelOrderRowData;
}

const CancelOrderModal: React.FC<ICancelOrderModalProps> = ({
  title,
  activeRow,
  reasonLabel,
  cleanActiveRow,
}) => {
  const isLoading = useAppSelector((state) => state.wirelineEngagements.cancelOrder.isLoading);
  const { dirty, isSubmitting, submitForm } = useFormikContext();

  const withLeaveConfirmation = dirty && !isSubmitting;

  const { product, customerLocation } = activeRow || {};

  const onClose = () => {
    cleanActiveRow();
  };

  return (
    <CustomModal
      title={title}
      maxWidth="504px"
      areButtonsVisible
      isLoading={isLoading}
      cancelButtonText="No"
      onCloseModal={onClose}
      confirmButtonText="Yes"
      isModalOpen={!!activeRow}
      onClickConfirm={submitForm}
      isConfirmDialog={withLeaveConfirmation}
    >
      <div
        className="cancel-order__modal"
      >
        <OrderModalItem
          product={product}
          location={customerLocation}
        />
        <p
          className="cancel-order__modal__reason-title"
        >
          {reasonLabel}
        </p>
        <FormikTextareaField
          fieldName="text"
        />
      </div>
    </CustomModal>
  );
};

export default CancelOrderModal;
