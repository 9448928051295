import React from 'react';
import { useFormikContext } from 'formik';
import { useAppSelector } from 'core/store';
import CustomModal from 'shared/CustomModal';
import OrderModalItem from 'shared/OrderModalItem';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';

import './DeclineOrderModal.scss';

interface IDeclineOrderModalProps {
  title: string;
  reasonLabel: string;
  cleanActiveRow: () => void;
  activeRow: IRenewalSowDataItem;
}

const DeclineOrderModal: React.FC<IDeclineOrderModalProps> = ({
  title,
  activeRow,
  reasonLabel,
  cleanActiveRow,
}) => {
  const isLoading = useAppSelector((state) => state.wirelineEngagements.declineOrder.isLoading);
  const { dirty, isSubmitting, submitForm } = useFormikContext();

  const withLeaveConfirmation = dirty && !isSubmitting;

  const { product, customerLocation } = activeRow || {};

  return (
    <CustomModal
      title={title}
      maxWidth="504px"
      areButtonsVisible
      isLoading={isLoading}
      cancelButtonText="No"
      confirmButtonText="Yes"
      isModalOpen={!!activeRow}
      onClickConfirm={submitForm}
      onCloseModal={cleanActiveRow}
      isConfirmDialog={withLeaveConfirmation}
    >
      <div className="decline-order__modal">
        <OrderModalItem
          product={product}
          location={customerLocation}
        />
        <div
          className="decline-order__modal__reason-title"
        >
          {reasonLabel}
        </div>
        <FormikTextareaField
          fieldName="text"
        />
      </div>
    </CustomModal>
  );
};

export default DeclineOrderModal;
