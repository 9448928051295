export const mockBookingDataList = [
  {
    id: 1,
    requestType: 'CONTRACT',
    customerName: 'Josh',
    sellerFullname: 'Peter Parker',
    companyName: 'Apple',
    requestCreatedDate: '2022-01-08T15:55:29.045013Z',
    requestAcceptedDate: '2023-10-08T15:55:29.045013Z',
    contractSpecialist: 'Angel',
    canceledDate: '2024-02-08T15:55:29.045013Z',
    completedDate: '2024-03-08T15:55:29.045013Z',
  },
  {
    id: 2,
    requestType: 'QUOTE',
    customerName: 'Lana',
    sellerFullname: 'Laura Lincoln',
    companyName: 'BBC',
    requestCreatedDate: '2020-01-08T15:55:29.045013Z',
    requestAcceptedDate: '2020-10-08T15:55:29.045013Z',
    contractSpecialist: 'Michael',
    canceledDate: '2023-11-08T15:55:29.045013Z',
    completedDate: '2024-05-08T15:55:29.045013Z',
  },
  {
    id: 3,
    requestType: 'ORDER',
    customerName: 'Kim',
    sellerFullname: 'Peter',
    companyName: 'Indesit',
    requestCreatedDate: '2024-03-08T15:55:29.045013Z',
    requestAcceptedDate: '2024-04-08T15:55:29.045013Z',
    contractSpecialist: 'Kate',
    canceledDate: '2024-12-08T15:52:29.045013Z',
    completedDate: '2024-12-08T15:53:29.045013Z',
  },
];

export const mockBookingDataResponse = {
  count: mockBookingDataList.length,
  results: mockBookingDataList,
};
