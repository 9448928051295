import React from 'react';
import Table from 'shared/Table';
import { useAppSelector } from 'core/store';
import { TRow } from 'shared/Table/Table.models';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import RenewalSowOrdersActions from '@EngagementsRenewalSowData/RenewalSowOrdersActions';
import { useRenewalSowPermissions } from '@EngagementsRenewalSowData/useRenewalSowPermission';
import { colDefOptionSelect, colDefOptionActionsTwoIcons, colDefOptionActionsSingleIcon } from 'shared/Table/constants';
import RenewalSowDataTableRowActions from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableRowActions';
import RenewalSowDataTableModals from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/RenewalSowDataTableModals';
import {
  defaultColumnPinning,
  muiTableContainerProps,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

const RenewalSowDataTable = () => {
  const {
    isAvailableTableActions,
    isAvailableSomeRowAction,
    isAvailableTwoRowActions,
  } = useRenewalSowPermissions();
  const isRenewalSowLoading = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.isLoading,
  );

  const renewalSowData = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.results,
  );

  const renewalSowCount = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.count,
  );

  const actionsButton = isAvailableTableActions ? <RenewalSowOrdersActions /> : undefined;

  return (
    <>
      <Table
        showGlobalFilter
        enableStickyHeader
        className="wireline-table"
        showColumnFilters={false}
        rowCount={renewalSowCount}
        actionsButton={actionsButton}
        isLoading={isRenewalSowLoading}
        rows={renewalSowData as unknown as TRow[]}
        initialColumnPinning={defaultColumnPinning}
        muiTableContainerProps={muiTableContainerProps}
        renderRowActions={({ row: { original } }) => (
          <RenewalSowDataTableRowActions
            row={original as unknown as IRenewalSowDataItem}
          />
        )}
        displayColumnDefOptions={{
          ...(isAvailableTableActions && colDefOptionSelect),
          ...(isAvailableSomeRowAction && colDefOptionActionsSingleIcon),
          ...(isAvailableTwoRowActions && colDefOptionActionsTwoIcons),
        }}
      />
      <RenewalSowDataTableModals />
    </>
  );
};
export default RenewalSowDataTable;
