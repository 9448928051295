import React, { memo } from 'react';
import { IOrderDataSidebarItem } from 'core/models/order.models';
import OrderDataItem from 'pages/WirelineEngagements/shared/OrderDataSidebar/OrderDataItem';

import './OrderDataList.scss';

interface IOrderDataListProps {
  data: IOrderDataSidebarItem[]
}

const OrderDataList: React.FC<IOrderDataListProps> = ({
  data,
}) => (
  <div className="order-data-list">
    {data.map(
      (item) => (<OrderDataItem key={item.label} data={item} />),
    )}
  </div>
);

export default memo(OrderDataList);
