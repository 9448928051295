export enum BoolEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum ROLE_STATUS {
  FUTURE = 'FUTURE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  RESOLVED_PENDING_COMPLETION = 'RESOLVED_PENDING_COMPLETION',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
}

export enum COMMISSION_TYPE {
  NEW = 'NEW',
  RENEWAL = 'RENEWAL',
  RE_ORDER = 'RE_ORDER',
  DOWNGRADE = 'DOWNGRADE',
  UPGRADE = 'UPGRADE',
  CANCELED = 'CANCELED',
}

export interface ITableColumnMetaItem {
  // eslint-disable-next-line no-restricted-globals
  name: string,
  label: string,
}

export interface IParsedSchemaDataItem {
  [key: string]: {
    label: string
    description?: string
  }
}

export enum OrderActions {
  REQUEST_QUOTES = 'requestQuotes',
  PROVIDE_QUOTES = 'provideQuotes',
  REQUEST_CONTRACTS = 'requestContracts',
  PROVIDE_CONTRACTS = 'provideContracts',
  REQUEST_ORDERS = 'requestOrders',
  PROVIDE_ORDERS = 'provideOrders',
}

export interface ICommonOrderData {
  sellerRequestType: string
}
