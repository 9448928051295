import React from 'react';
import cx from 'classnames';
import StatusCell from 'shared/Table/shared/StatusCell';
import { StatusCellClasses } from 'shared/Table/shared/StatusCell/model';
import RequestProvidedDate from 'shared/Table/shared/RequestProvidedDate';
import convertDataFromFormToTable from 'core/utilities/convertDataFromFormToTable';
// eslint-disable-next-line no-unused-vars
import { EngagementType, ITableCellContract } from 'pages/ContractManagment/ContractManagement.models';
import { providedBodyCellStyle, providedHeadCellStyle } from 'pages/InsideSales/InsideSalesList/constants';
import RequestTypeCell from 'pages/ContractManagment/ContractManagementList/ContractManagementTable/RequestTypeCell';
import {
  RequestTypeOptions,
  CommissionTypeLabels,
  EngagementTypeLabels,
  EngagementTypeOptions,
} from 'pages/ContractManagment/constants';

export const columns = [
  {
    header: 'Request accepted',
    accessorKey: 'acceptedAt',
    minSize: 100,
    enableColumnFilter: false,
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    header: 'Engagement type',
    accessorKey: 'engagementType',
    Cell: ({ row }: ITableCellContract) => {
      if (!row) {
        return null;
      }

      const className = cx({
        [StatusCellClasses.RED]: row.original.engagementType === EngagementType.PENDING,
        [StatusCellClasses.GREEN]: row.original.engagementType === EngagementType.IN_PROGRESS,
        [StatusCellClasses.PRIMARY]: row.original.engagementType === EngagementType.COMPLETED,
        [StatusCellClasses.DARK]: row.original.engagementType === EngagementType.CANCELED,
        [StatusCellClasses.SECONDARY]: row.original.engagementType === EngagementType.DECLINED,
      });

      return (
        <StatusCell className={className as StatusCellClasses}>
          {EngagementTypeLabels[row.original.engagementType]}
        </StatusCell>
      );
    },
    filterVariant: 'select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(EngagementTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select status' },
  },
  {
    header: 'Request type',
    filterVariant: 'select',
    accessorKey: 'requestType',
    filterFn: 'equals',
    minSize: 200,
    filterSelectOptions: convertDataFromFormToTable(RequestTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select status' },
    Cell: ({ row }) => (
      <RequestTypeCell rowData={row.original} />
    ),
  },
  {
    header: 'Commission type',
    accessorKey: 'commissionType',
    minWidth: '100px',
    sortDescFirst: false,
    enableColumnFilter: false,
    Cell: ({ row }: ITableCellContract) => (
      <StatusCell className={StatusCellClasses.DARK as StatusCellClasses}>
        {CommissionTypeLabels[row.original.commissionType]}
      </StatusCell>
    ),
  },
  {
    header: 'Product',
    accessorKey: 'product',
    minWidth: '100px',
    sortDescFirst: false,
    enableColumnFilter: false,
  },
  {
    header: 'Customer name',
    accessorKey: 'customerName',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter Customer Name' },
    sortDescFirst: false,
  },
  {
    header: 'Seller',
    accessorKey: 'sellerFullname',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter Seller' },
    sortDescFirst: false,
  },
  {
    header: 'Contract Specialist',
    accessorKey: 'contractSpecialistFullname',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter Contract Specialist' },
    sortDescFirst: false,
  },
];
