import React, { ReactNode } from 'react';
import CustomMenuItem from 'shared/ButtonMenu/CustomMenuItem';
import { IMenuItem } from 'shared/ButtonMenu/ButtonMenu.model';
import { useButtonMenuContext } from 'shared/ButtonMenu/ButtonMenuContext';

interface IMenuItemsProps {
  menuItems: IMenuItem[]
}

const GroupMenuItems: React.FC<IMenuItemsProps> = ({
  menuItems,
}) => {
  const { onClose } = useButtonMenuContext();

  return (
    <>
      {menuItems
        .map(({
          icon,
          label,
          onClick,
          isDisabled,
        }) => (
          <CustomMenuItem
            key={label}
            label={label}
            icon={icon}
            onClose={onClose}
            onAction={onClick}
            isDisabled={isDisabled}
          />
        ))}
    </>
  );
};

export default GroupMenuItems;
