import React, { useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import {
  map, get, set, cloneDeep,
} from 'lodash';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { EngagementType } from 'pages/InsideSales/model';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { IServerValidationError } from 'core/services/HTTPService.models';
import HeaderActions from 'pages/WirelineEngagements/shared/HeaderActions';
import { useUserPermissions } from '@EngagementsSowData/useUserPermissions';
import EditRowSidebar from 'pages/WirelineEngagements/shared/EditRowSidebar';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { getSalesSowItemAction, clearGetSalsesSowItemData } from 'core/actions/Sales/GetSalesSowItem';
import { patchSalesSowItemAction, clearEditSalsesSowItemData } from 'core/actions/Sales/EditSalesSowItem';
import { isOrderAttTSD, getParsedRowData } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { dynamicProductSchemaName } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsSowData/constants';
import { addAdditionalFieldsToOrder, omitAdditionalFieldsFromOrder } from 'pages/WirelineEngagements/shared/EditRowSidebar/utilities';
import { alwaysActiveFieldsForPM } from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EditRowSidebarForm/constants';
import { includedFields, controlledFieldsConfigs, additianalOrderFields } from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields/constants';
import {
  viewSowDataTitle,
  editSowDataTitle,
  getSowItemMessage,
  patchSalesSowItemActionMessage,
} from '@EngagementsSowData/constants';

const EditRowSidebarForm = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();
  const { getTableData } = useTableContext();
  const { isAvailableOrderDataSidebar } = useUserPermissions();

  const {
    activeRowMode, activeRowId, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode,
    activeRow,
  } = useTableActiveRowContext<ISowDataListItem>();

  const isTsdAtt = isOrderAttTSD(activeRow);
  const isVisibleOrderDataSidebar = isTsdAtt && isAvailableOrderDataSidebar;

  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const salesSowItem = useAppSelector((state) => state.wirelineEngagements.salesSowItem.result);
  const isLoadingSowItem = useAppSelector(
    (state) => state.wirelineEngagements.salesSowItem.isLoading,
  );
  const isLoadingEditSowItem = useAppSelector(
    (state) => state.wirelineEngagements.editSalesSowItem.isLoading,
  );

  const isLoading = isLoadingSowItem || isLoadingEditSowItem;

  const { editableSowData, salesSowItemKeys } = useMemo(() => {
    const editableData = getParsedRowData({
      includedFields,
      isEditable: true,
      rowData: salesSowItem,
    });
    const itemKeys = Object.keys(editableData);

    return { editableSowData: editableData, salesSowItemKeys: itemKeys };
  }, [salesSowItem]);

  const initialFormValues = useMemo(() => addAdditionalFieldsToOrder({
    order: salesSowItem,
    parsedOrder: editableSowData,
    fields: additianalOrderFields,
  }),
  [salesSowItem, editableSowData]);

  const disabledFields = useMemo(() => {
    const isCanceled = editableSowData.pmEngagementType === EngagementType.CANCELED;
    return salesSowItemKeys
      .reduce(
        (acc, str) => ({ ...acc, [str]: isCanceled && !alwaysActiveFieldsForPM.includes(str) }),
        {});
  },
  [editableSowData, salesSowItemKeys]);

  const title = isEditMode ? editSowDataTitle : viewSowDataTitle;
  const editRowId = (isEditMode || isViewMode) ? activeRowId : undefined;

  const topActions = (
    <HeaderActions
      isVisibleCommentsButton
      secondarySidebarMode={secondarySidebarMode}
      setSecondarySidebarMode={setSecondarySidebarMode}
      isVisibleOrderDataButton={isVisibleOrderDataSidebar}
    />
  );

  const onSubmit = async (params, { setErrors, resetForm }) => {
    if (!activeRowId || !isEditMode) return;

    const formattedParams = cloneDeep(params);

    map(
      controlledFieldsConfigs,
      ({ fieldName, switchFieldName, switchEnabledValues }) => {
        const switchFieldValue = formattedParams[switchFieldName];

        const isDisabled = !switchEnabledValues.includes(switchFieldValue);

        if (isDisabled) {
          set(formattedParams, fieldName, get(editableSowData, fieldName));
        }
      },
    );

    const clearedParamsFromAddtitinalData = omitAdditionalFieldsFromOrder({
      order: formattedParams,
      initialOrder: editableSowData,
      mandatoryFields: includedFields,
      additionalFields: additianalOrderFields,
    });

    try {
      await dispatch(patchSalesSowItemAction({
        id: activeRowId,
        params: clearedParamsFromAddtitinalData,
      })).unwrap();

      cleanActiveRow();
      setSecondarySidebarMode(null);
      showSuccessNotifier(patchSalesSowItemActionMessage.success);
      getTableData();
      resetForm();
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);
      showErrorNotifier(patchSalesSowItemActionMessage.error, definedError);
    }
  };

  const getSalesSowItemData = async () => {
    try {
      dispatch(getSalesSowItemAction(activeRowId as number))
        .unwrap();
    } catch (error) {
      showErrorNotifier(getSowItemMessage.error, error);
    }
  };

  useEffect(() => {
    if (!activeRowId || !(isViewMode || isEditMode)) return;
    getSalesSowItemData();

    return () => {
      dispatch(clearGetSalsesSowItemData());
      dispatch(clearEditSalsesSowItemData());
    };
  }, [activeRowId]);

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialFormValues}
    >
      <Form>
        <EditRowSidebar
          title={title}
          editRowId={editRowId}
          isViewMode={isViewMode}
          topActions={topActions}
          isLoadingItem={isLoading}
          cleanEditRow={cleanActiveRow}
          disabledFields={disabledFields}
          allowedFields={salesSowItemKeys}
          schemaName={dynamicProductSchemaName}
          secondarySidebarMode={secondarySidebarMode}
          setSecondarySidebarMode={setSecondarySidebarMode}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
