import { rowActionsName, rowSelectName } from 'shared/Table/constants';
import {
  BoolEnum, ROLE_STATUS, COMMISSION_TYPE, OrderActions,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import {
  PortDescription,
  Status,
  ORDER_TYPE,
  Carrier,
  WhosLogin,
  Term,
  System,
  PbxInterfaceType,
} from '@EngagementsSowData/sowData.model';

import styles from 'assets/styles/_resources.scss';

const { greenColor50, greenColor500 } = styles;

export const orderTypeLabels = {
  [ORDER_TYPE.NEW]: 'New',
  [ORDER_TYPE.CANCELED]: 'Canceled',
  [ORDER_TYPE.ADMIN_SUPPORT]: 'Admin support',
  [ORDER_TYPE.BILLING]: 'Billing',
  [ORDER_TYPE.DISCONNECT]: 'Disconnect',
  [ORDER_TYPE.ESCALATION]: 'Escalation',
  [ORDER_TYPE.FUTURE_ORDER]: 'Future Order',
  [ORDER_TYPE.GENERAL_QUESTIONS]: 'General Questions',
  [ORDER_TYPE.MACD]: 'MACD',
  [ORDER_TYPE.MOVE_BVOIP]: 'Move - BVOIP',
  [ORDER_TYPE.OUTAGE_MAINTENANCE]: 'Outage/Maintenance',
  [ORDER_TYPE.PORT_BVOIP]: 'Port - BVOIP',
  [ORDER_TYPE.IN_PROGRESS]: 'In Progress',
  [ORDER_TYPE.COMPLETED]: 'Completed',
  [ORDER_TYPE.RE_ORDER]: 'Re-Order',
  [ORDER_TYPE.NEW_42393_UPFRONT]: 'New - (42393/Upfront ID)',
  [ORDER_TYPE.NEW_50212_RESIDUAL]: 'New - (50212/Residual ID)',
  [ORDER_TYPE.NEW_NON_AT_T]: 'New - Non AT&T',
  [ORDER_TYPE.RE_ORDER_42393_UPFRONT]: 'Re-order - (42393/Upfront ID)',
  [ORDER_TYPE.RE_ORDER_50212_RESIDUAL]: 'Re-order - (50212/Residual ID)',
  [ORDER_TYPE.RE_ORDER_NON_AT_T]: 'Re-order - Non AT&T',
  [ORDER_TYPE.RENEWAL_50212_RESIDUAL]: 'Renewal - (50212/Residual ID)',
  [ORDER_TYPE.RENEWAL_52632_RESIDUAL]: 'Renewal - (52632/Residual ID)',
};

export const statusLabels = {
  [Status.NOT_SELECTED]: 'Not selected',
  [Status.FUTURE]: 'Future',
  [Status.IN_PROGRESS]: 'In Progress',
  [Status.COMPLETED]: 'Completed',
};

export const roleStatusLabels = {
  [ROLE_STATUS.FUTURE]: 'Future',
  [ROLE_STATUS.IN_PROGRESS]: 'In Progress',
  [ROLE_STATUS.COMPLETED]: 'Completed',
  [ROLE_STATUS.PENDING]: 'Pending',
  [ROLE_STATUS.CANCELED]: 'Canceled',
  [ROLE_STATUS.RESOLVED_PENDING_COMPLETION]: 'Resolved, pending completion',
  [ROLE_STATUS.PENDING_CANCELLATION]: 'Pending Cancellation',
};

export const carrierLabels = {
  [Carrier.ATT]: 'ATT',
  [Carrier.AVANT]: 'Avant',
  [Carrier.INTELISYS]: 'Intelisys',
  [Carrier.SANDLER]: 'Sandler',
  [Carrier.TBI]: 'TBI',
  [Carrier.TCG]: 'TCG',
  [Carrier.TELARUS]: 'Telarus',
};

export const whosLoginLabels = {
  [WhosLogin.CHARLES_BARKER]: 'Charles Barker',
  [WhosLogin.LANDON_SOUTHWELL]: 'Landon Southwell',
  [WhosLogin.LATANYA_COLLIER]: 'LaTanya Collier',
  [WhosLogin.SARAH_JANE_HARRIS]: 'Sarah Jane Harris',
};

export const termLabels = {
  [Term.ZERO]: 'Month To Month',
  [Term.ONE]: '12 - One Year',
  [Term.TWO]: '24 - Two Years',
  [Term.THREE]: '36 - Three Years',
  [Term.FOUR]: '48 - Four Years',
  [Term.FIVE]: '60 - Five Years',
};

export const systemLabels = {
  [System.ASAP]: 'ASAP',
  [System.BOOST]: 'BOOST',
  [System.NON_AT_T]: 'Non-AT&T',
  [System.NI_EXPRESS]: 'NI Express',
  [System.OPUS]: 'OPUS',
  [System.RDS]: 'RDS',
  [System.SALES_EXPRESS]: 'Sales Express',
  [System.USELL]: 'USELL',
  [System.ATT_ACC]: 'ATT-ACC',
  [System.BUSINESS_CENTER]: 'Business Center',
  [System.CALNET]: 'CalNet',
  [System.CLOUDRUNNER]: 'CloudRunner',
  [System.SOSP]: 'SOSP',
  [System.SSPP]: 'SSPP',
};

export const pbxInterfaceTypeLabels = {
  [PbxInterfaceType.SIP]: 'SIP',
  [PbxInterfaceType.ANALOG]: 'Analog',
  [PbxInterfaceType.PRI]: 'PRI',
};

export const portDescriptionLabels = {
  [PortDescription.NOT_SELECTED]: 'Not Selected',
  [PortDescription.PORT_BASED]: 'Port Based',
  [PortDescription.VLAN_BASED]: 'VLAN Based',
};

export const commissionTypeLabels = {
  [COMMISSION_TYPE.NEW]: 'New',
  [COMMISSION_TYPE.RENEWAL]: 'Renewal',
  [COMMISSION_TYPE.UPGRADE]: 'Upgrade',
  [COMMISSION_TYPE.RE_ORDER]: 'Re-order',
  [COMMISSION_TYPE.CANCELED]: 'Canceled',
  [COMMISSION_TYPE.DOWNGRADE]: 'Downgrade',
};

export const boolLabels = {
  [BoolEnum.YES]: 'Yes',
  [BoolEnum.NO]: 'No',
};

export const providedHeadCellStyle = {
  sx: {
    backgroundColor: greenColor50,
    borderTop: `2px solid ${greenColor500}`,
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
};

export const providedBodyCellStyle = {
  sx: {
    backgroundColor: greenColor50,
    fontSize: '14px',
  },
};

export const muiTableContainerProps = {
  sx: {
    maxHeight: '560px',
  },
};

export const defaultColumnPinning = { left: [rowSelectName, rowActionsName] };

export const requestQuoteNotifier = {
  success: 'Request quote was added successfully.',
  error: 'Request quote was failed.',
};

export const orderActionsTitle = {
  [OrderActions.REQUEST_QUOTES]: 'Are you sure you want to request a quote for the selected order/s?',
  [OrderActions.PROVIDE_QUOTES]: 'Are you sure you want to provide a quote for the selected order/s?',
  [OrderActions.REQUEST_CONTRACTS]: 'Are you sure you want to request a contract for the selected order/s?',
  [OrderActions.PROVIDE_CONTRACTS]: 'Are you sure you want to provide a contract for the selected order/s?',
  [OrderActions.PROVIDE_ORDERS]: 'Are you sure you want to provide an order for the selected order/s?',
  [OrderActions.REQUEST_ORDERS]: 'Are you sure you want to send an order request for these order/s?',
};

export const orderActionsItemTooltip = {
  [OrderActions.REQUEST_QUOTES]: 'The Quote has been requested',
  [OrderActions.PROVIDE_QUOTES]: 'The Quote has been provided',
  [OrderActions.PROVIDE_CONTRACTS]: 'The Contract has been provided',
  [OrderActions.PROVIDE_ORDERS]: 'The Order has been provided',
};
