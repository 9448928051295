import paths from 'core/routing/paths';
import ContractManagementList from 'pages/ContractManagment/ContractManagementList';
import ContractManagementContractFormView from 'pages/ContractManagment/ContractManagementContractFormView';

const contractManagementRoutes = [
  {
    path: paths.contractManagementList,
    component: ContractManagementList,
  },
  {
    path: paths.contractManagementListContractFormView,
    component: ContractManagementContractFormView,
  },
];

export default contractManagementRoutes;
