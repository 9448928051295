import React, { useEffect, useMemo } from 'react';
import map from 'lodash/map';
import merge from 'lodash/merge';
import groupBy from 'lodash/groupBy';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'core/store';
import getCustomerAction from 'core/actions/Customers/GetCustomerById/actions';
import { REQUEST_BUNDLES_TYPE } from 'core/actions/Sales/RequestBundles/constants';
import ContractForm from 'pages/WirelineEngagements/shared/ContractForm/ContractForm';
import schema from 'pages/WirelineEngagements/shared/ContractForm/ContractFormSchema';

interface IContractFormCreateProps {
  orderIds: string[]
  selectedOrders: any[]
  backRoutePath: string
  isLoadingOrders: boolean
  requestType: REQUEST_BUNDLES_TYPE
}

const ContractFormCreate: React.FC<IContractFormCreateProps> = ({
  orderIds,
  requestType,
  backRoutePath,
  selectedOrders,
  isLoadingOrders,
}) => {
  const dispatch = useAppDispatch();
  const { customerId } = useParams();

  const customerData = useAppSelector(
    (state) => state.customers.customer.customerData);
  const isLoadingCustomer = useAppSelector((state) => state.customers.customer.isLoading);

  const { subAccountId, contractSignerProfile } = customerData;

  const numberOfOrders = selectedOrders.length;
  const uniqueOrdersByLocation = groupBy(selectedOrders, (order) => order.customerLocation);

  const isLoading = isLoadingOrders || isLoadingCustomer;

  const contractDetailsOrders = map(uniqueOrdersByLocation, (value, key) => ({
    location: [key],
    product: value.map((order) => ({
      name: order.product,
      id: order.id,
    })),
  }));

  const initialValues = useMemo(() => {
    const defaultValues = schema.getDefault();
    const {
      firstName, lastName, phoneNumber, email,
    } = contractSignerProfile;

    const signerProfile = {
      signerName: `${firstName} ${lastName}`,
      signerPhone: phoneNumber,
      signerEmail: email,
    };

    return merge(defaultValues, signerProfile);
  }, [contractSignerProfile]);

  useEffect(() => {
    dispatch(getCustomerAction(customerId));
  }, []);

  return (
    <ContractForm
      orderIds={orderIds}
      isLoading={isLoading}
      requestType={requestType}
      subAccountId={subAccountId}
      backRoutePath={backRoutePath}
      initialValues={initialValues}
      selectedOrders={selectedOrders}
      numberOfOrders={numberOfOrders}
      contractDetailsOrders={contractDetailsOrders}
    />
  );
};

export default ContractFormCreate;
