import React from 'react';
import OrderModalItem from 'shared/OrderModalItem';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { orderActionsItemTooltip } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

interface IOrderItemProps {
  order: ISowDataListItem;
}

const OrderItem: React.FC<IOrderItemProps> = ({
  order,
}) => {
  const {
    customerLocation, product, requestOrderActionDatetime,
  } = order;

  return (
    <OrderModalItem
      product={product}
      location={customerLocation}
      accepted={!!requestOrderActionDatetime}
      withTooltip={!!requestOrderActionDatetime}
      tooltipTitle={orderActionsItemTooltip[OrderActions.REQUEST_ORDERS]}
    />
  );
};

export default OrderItem;
