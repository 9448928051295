import React, { memo } from 'react';
import Loader from 'shared/Loader';
import CustomSidebar from 'shared/CustomSidebar';
import { IOrderDataWithRelatedOrders } from 'core/models/order.models';
import OrderDataContent from 'pages/WirelineEngagements/shared/AccardionOrderDataSidebar/OrderDataContent';

interface IAccardionOrderDataSidebarProps {
  isOpen: boolean
  schemaKey: string
  isLoading: boolean
  onClose: () => void
  orderData: IOrderDataWithRelatedOrders
}

const AccardionOrderDataSidebar: React.FC<IAccardionOrderDataSidebarProps> = ({
  isOpen,
  onClose,
  isLoading,
  schemaKey,
  orderData,
}) => (
  <CustomSidebar
    isOpen={isOpen}
    title="Order Data"
    isConfirmDialog={false}
    areButtonsVisible={false}
    onCloseSideBar={onClose}
  >
    {
      isLoading
        ? <Loader />
        : (
          <OrderDataContent
            schemaKey={schemaKey}
            orderData={orderData}
          />
        )
    }
  </CustomSidebar>
);

export default memo(AccardionOrderDataSidebar);
