import { comments } from 'core/api/constants';

const products = 'products';
const sales = 'sales';
const booking = 'booking';
const orders = 'orders';
const sow = 'sow';
const meta = 'meta';
const quotes = 'quotes';
const provide = 'provide';
const contractBundles = 'contract-bundles';
const contractManagement = 'contract-management';
const contracts = 'contracts';
const orderForm = 'order-form';
const orderActions = 'order-actions';
const activeNumbers = 'active-numbers';
const acceptEngagement = 'accept-engagement';
const pmAcceptEngagement = 'pm-accept-engagement';
const billingAcceptEngagement = 'billing-accept-engagement';
const contractAcceptEngagement = 'contract-accept-engagement';
const bookedOrder = 'book';
const renewalSow = 'renewal-sow';
const escalationSupport = 'escalation-support';
const cancel = 'cancel';
const accept = 'accept';
const duplicate = 'duplicate';
const retentionBooking = 'retention-booking';
const exportData = 'export';
const tsd = 'tsd';
const productCategories = 'product-categories';
const carriers = 'carriers';
const requestBundles = 'request-bundles';
const decline = 'decline';
const requests = 'requests';
const complete = 'complete';
const contractSpecialist = 'contract-specialist';

export const salesOrdersUrl = `${sales}/${orders}/`;

export const productsPath = `${sales}/${products}/`;

export const salesSowPath = `${sales}/${sow}/`;

export const salesSowMataPath = `${salesSowPath}${meta}/`;

export const salesActiveNumbersMetaUrl = `${sales}/${activeNumbers}/${meta}/`;

export const salesRenewalSowMetaUrl = `${sales}/${renewalSow}/${meta}/`;

export const quoteRequestUrl = `${sales}/${quotes}/`;

export const requestBundlesUrl = `${sales}/${requestBundles}/`;

export const retentionBookingUrl = `${sales}/${retentionBooking}/`;

export const getQuoteProvideUrl = (quoteId) => `${quoteRequestUrl}${quoteId}/${provide}/`;

export const contractBundlesUrl = `${sales}/${contractBundles}/`;

export const getSalesSowOrderFormUrl = (id: string | number) => `${sales}/${orderForm}/${id}`;

export const salesOrderActionsUrl = `${sales}/${orderActions}/`;

export const getRequestAcceptedActionsUrl = (bundleId: number) => `${sales}/${requestBundles}/${bundleId}/${accept}/`;

export const getRequestCompleteActionsUrl = (contractId: number) => `${sales}/${requests}/${contractId}/${complete}/`;

export const getContractSpecialistActionsUrl = (bundleId: number) => `${sales}/${requestBundles}/${bundleId}/${contractSpecialist}/`;

export const getEditContractManagementItemUrl = (id: string | number) => `/${sales}/${contractManagement}/${id}/`;

export const getSalesOrderActionsProvideUrl = (id: string | number) => `${salesOrderActionsUrl}${id}/${provide}/`;

export const getContractProvideUrl = (contractId) => `${sales}/${contracts}/${contractId}/${provide}/`;

export const activeNumbersUrl = `${sales}/${activeNumbers}/`;

export const getContractBundlesByIdUrl = (bundleId) => `${contractBundlesUrl}${bundleId}/`;

export const getEngagementAcceptedUrl = (orderId) => `${sales}/${activeNumbers}/${orderId}/${acceptEngagement}/`;

export const getPmEngagementAcceptedUrl = (orderId) => `${sales}/${activeNumbers}/${orderId}/${pmAcceptEngagement}/`;

export const getBillingEngagementAcceptedUrl = (orderId) => `${sales}/${activeNumbers}/${orderId}/${billingAcceptEngagement}/`;

export const getSowDataEngagementAcceptedUrl = (orderId) => `${sales}/${sow}/${orderId}/${contractAcceptEngagement}/`;

export const renewalSowUrl = `${sales}/${renewalSow}/`;
export const bookingUrl = `${sales}/${booking}/`;

export const getRenewalSowItemUrl = (id: string | number) => `${renewalSowUrl}${id}/`;

export const getRenewalSowEngagementBillingAcceptedUrl = (id: string | number) => `${renewalSowUrl}${id}/${billingAcceptEngagement}/`;

export const getBookingInfoBookedOrderUrl = () => `${bookingUrl}${sales}/${bookedOrder}/`;

export const getEscalationSupportUrl = (id: string | number) => `${salesSowPath}${id}/${escalationSupport}/`;

export const getEscalationSupportDataUrl = `${sales}/${escalationSupport}/`;

export const getEscalationSupportDataByIdUrl = (id: number | string) => `${getEscalationSupportDataUrl}${id}/`;

export const getOrderCancelUrlById = (id: string | number) => `${salesOrdersUrl}${id}/${cancel}/`;

export const getOrderDeclineUrlById = (id: string | number) => `${salesOrdersUrl}${id}/${decline}/`;

export const getOrderDuplicateUrlById = (id: string | number) => `${salesOrdersUrl}${id}/${duplicate}/`;

export const getOrderCommentsListUrl = (orderId: number | string) => `${salesOrdersUrl}${orderId}/${comments}/`;

export const getOrderCommentsItemUrl = (orderId: number | string, commentId: number | string) => (
  `${salesOrdersUrl}${orderId}/${comments}/${commentId}/`
);

export const exportSowDataUrl = `${sales}/${sow}/${exportData}/`;

export const exportActiveNumbersDataUrl = `${sales}/${activeNumbers}/${exportData}/`;

export const getTSDListUrl = `${sales}/${tsd}/`;

export const getProductCategoriesListUrl = `${sales}/${productCategories}/`;

export const getCarriersListUrl = `${sales}/${carriers}/`;
