import React from 'react';
import OrderModalItem from 'shared/OrderModalItem';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import {
  RequestTypeLabels,
} from 'pages/ContractManagment/constants';

import './RequestAcceptedItems.scss';

interface IRequestAcceptedItemsProps {
  selectedContracts: IContract[];
}

const RequestAcceptedItems: React.FC<IRequestAcceptedItemsProps> = ({
  selectedContracts,
}) => (
  <div className="request-accepted-items">
    {selectedContracts.map(({ requestType, product, id }) => (
      <OrderModalItem key={id} location={RequestTypeLabels[requestType] || ''} product={product} />
    ))}
  </div>
);

export default RequestAcceptedItems;
