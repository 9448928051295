import { patchRenewalSowItemById } from 'core/api/sales.api';
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IOrderDataWithRelatedOrders } from 'core/models/order.models';

export interface IState {
  isLoading: boolean;
  error: object | null
  result: IOrderDataWithRelatedOrders;
}

const initialState: IState = {
  error: null,
  isLoading: false,
  result: {} as IOrderDataWithRelatedOrders,
};

export const editSalesRenewalSowItemAction = createAsyncThunk(
  'editRenewalSowItemAction',
  async (
    { id, params }: { id: string | number, params: object },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await patchRenewalSowItemById(id, params);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const editSalesRenewalSowItemSlice = createSlice({
  name: 'editSalesRenewalSowItemSlice',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(
        editSalesRenewalSowItemAction.pending,
        (state) => {
          state.isLoading = true;
          state.result = initialState.result;
        })
      .addCase(
        editSalesRenewalSowItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        editSalesRenewalSowItemAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error;
          state.result = initialState.result;
        });
  },
});

export const { clear: clearEditSalesRenewalSowItemData } = editSalesRenewalSowItemSlice.actions;

export const editSalesRenewalSowItemReducer = editSalesRenewalSowItemSlice.reducer;
