import React from 'react';
import { Grid } from '@mui/material';
import ButtonMenu, { MenuItems } from 'shared/ButtonMenu';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ADD_ORDER_TYPE } from 'pages/WirelineEngagements/WirelineEngagements.models';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const AddOrderButtonBlock = () => {
  const {
    setAddOrderModalType, isEditModeGeneral,
  } = useWirelineEngagementsCreateContext();

  const menuItems = [
    {
      label: 'Add new order',
      onClick: () => setAddOrderModalType(ADD_ORDER_TYPE.NEW_ORDER),
    },
    {
      label: 'Add renewal order',
      onClick: () => setAddOrderModalType(ADD_ORDER_TYPE.RENEWAL_ORDER),
    },
  ];

  return (
    <Grid
      item
      container
      direction="row"
      className="engagement-forms__add-order"
    >
      <ButtonMenu
        buttonContent="Add order"
        buttonVariant="contained"
        isDisabled={isEditModeGeneral}
        buttonIcon={<AddCircleOutlineIcon />}
      >
        <MenuItems menuItems={menuItems} />
      </ButtonMenu>
    </Grid>
  );
};

export default AddOrderButtonBlock;
