import {
  GET_ENGAGEMENTS_ATTACHMENTS_CLEAR,
  GET_ENGAGEMENTS_ATTACHMENTS_REQUEST,
  GET_ENGAGEMENTS_ATTACHMENTS_SUCCESS,
  GET_ENGAGEMENTS_ATTACHMENTS_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/GetEngagementsAttachments/actionTypes';

export const getEngagementsAttachmentsRequest = () => (
  { type: GET_ENGAGEMENTS_ATTACHMENTS_REQUEST });

export const getEngagementsAttachmentsSuccess = (attachments) => (
  { type: GET_ENGAGEMENTS_ATTACHMENTS_SUCCESS, attachments });

export const getEngagementsAttachmentsFailure = (error) => (
  { type: GET_ENGAGEMENTS_ATTACHMENTS_FAILURE, error });

export const getEngagementsAttachmentsClear = () => (
  { type: GET_ENGAGEMENTS_ATTACHMENTS_CLEAR });
