import React from 'react';
import OrderModalItem from 'shared/OrderModalItem';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { orderActionsItemTooltip } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

interface IOrderItemProps {
  order: IRenewalSowDataItem;
}

const OrderItem: React.FC<IOrderItemProps> = ({
  order,
}) => {
  const {
    customerLocation, product, requestQuoteDatetime,
  } = order;

  return (
    <OrderModalItem
      product={product}
      location={customerLocation}
      accepted={!!requestQuoteDatetime}
      withTooltip={!!requestQuoteDatetime}
      tooltipTitle={orderActionsItemTooltip[OrderActions.REQUEST_QUOTES]}
    />
  );
};

export default OrderItem;
