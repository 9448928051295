import { IEngagementAttachment } from 'pages/WirelineEngagements/WirelineEngagements.models';
import {
  CREATE_ENGAGEMENTS_ATTACHMENT_REQUEST,
  CREATE_ENGAGEMENTS_ATTACHMENT_SUCCESS,
  CREATE_ENGAGEMENTS_ATTACHMENT_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/CreateEngagementsAttachment/actionTypes';
import {
  EDIT_ENGAGEMENTS_ATTACHMENT_NAME_REQUEST,
  EDIT_ENGAGEMENTS_ATTACHMENT_NAME_SUCCESS,
  EDIT_ENGAGEMENTS_ATTACHMENT_NAME_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/EditEngagementsAttachmentName/actionTypes';

import {
  GET_ENGAGEMENTS_ATTACHMENTS_CLEAR,
  GET_ENGAGEMENTS_ATTACHMENTS_REQUEST,
  GET_ENGAGEMENTS_ATTACHMENTS_SUCCESS,
  GET_ENGAGEMENTS_ATTACHMENTS_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/GetEngagementsAttachments/actionTypes';

import {
  REMOVE_ENGAGEMENTS_ATTACHMENT_REQUEST,
  REMOVE_ENGAGEMENTS_ATTACHMENT_SUCCESS,
  REMOVE_ENGAGEMENTS_ATTACHMENT_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/RemoveEngagementsAttachment/actionTypes';
import {
  CLEAR_ERRORS_ENGAGEMENTS_ATTACHMENTS,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/ClearErrorsAttachments/actionTypes';

interface IAttachmentsState {
  error: string
  isLoading: boolean
  data: {
    results: IEngagementAttachment[]
    error: string
  }
}

const initialState: IAttachmentsState = {
  error: '',
  isLoading: false,
  data: {
    results: [],
    error: '',
  },
};

const attachmentsReducer = (state = initialState, {
  type,
  attachments,
  newAttachment,
  attachmentId,
  editedAttachmentName,
  error,
}) => {
  switch (type) {
    case GET_ENGAGEMENTS_ATTACHMENTS_REQUEST:
    case CREATE_ENGAGEMENTS_ATTACHMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ENGAGEMENTS_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          results: attachments,
          error: '',
        },
        error: '',
      };
    case CREATE_ENGAGEMENTS_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          results: [...state.data.results, newAttachment],
        },
        error: '',
      };
    case GET_ENGAGEMENTS_ATTACHMENTS_FAILURE:
    case CREATE_ENGAGEMENTS_ATTACHMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };
    case EDIT_ENGAGEMENTS_ATTACHMENT_NAME_REQUEST:
    case REMOVE_ENGAGEMENTS_ATTACHMENT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((attachment) => ((attachment.id === attachmentId) ? {
            ...attachment,
            isLoading: true,
          } : attachment)),
        },
      };
    case EDIT_ENGAGEMENTS_ATTACHMENT_NAME_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((attachment) => (
            (attachment.id === editedAttachmentName.id) ? {
              ...attachment,
              ...editedAttachmentName,
              isLoading: false,
              error: '',
            } : attachment)),
        },
      };
    case REMOVE_ENGAGEMENTS_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          results: state.data.results.filter(
            (attachment) => attachment.id !== attachmentId,
          ),
        },
      };
    case EDIT_ENGAGEMENTS_ATTACHMENT_NAME_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((attachment) => ((attachment.id === attachmentId) ? {
            ...attachment,
            isLoading: false,
            error,
          } : attachment)),
        },
      };
    case REMOVE_ENGAGEMENTS_ATTACHMENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((attachment) => ((attachment.id === attachmentId) ? {
            ...attachment,
            isLoading: false,
            error,
          } : attachment)),
        },
      };
    case CLEAR_ERRORS_ENGAGEMENTS_ATTACHMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((attachment) => ({
            ...attachment,
            error: '',
          })),
        },
        error: '',
      };
    case GET_ENGAGEMENTS_ATTACHMENTS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default attachmentsReducer;
