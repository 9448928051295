import React from 'react';
import compact from 'lodash/compact';
import { GroupMenuItems } from 'shared/ButtonMenu';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SowDataActions } from '@EngagementsSowData/SowDataOrdersActions/constsnts';
import { contractProvideAndOrderProvideDisabledConditions } from '@EngagementsSowData/utilities';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

interface ISowDataActionsProvideProps {
  selectedOrders: ISowDataListItem[],
  setOrderAction: (orderAction: OrderActions) => void,
  setIsActionsModalOpen: (isModalOpen: boolean) => void
}

const SowDataActionsProvide: React.FC<
  ISowDataActionsProvideProps
> = ({
  selectedOrders, setOrderAction, setIsActionsModalOpen,
}) => {
  const isSomeOrderNotAcceptedByCS = selectedOrders.some(
    (order) => !order.contractEngagementAcceptedDate,
  );
  const isContactAndOrderProvideDisabled = selectedOrders.some(
    contractProvideAndOrderProvideDisabledConditions,
  );

  const allOrdersHaveProvideQuoteDatetime = selectedOrders.every(
    (order) => order.provideQuoteDatetime,
  );
  const isQuotesProvideDisabledByAdditionalConditions = selectedOrders.some(
    ({ requestQuoteDatetime, newServiceMrc }) => !requestQuoteDatetime || !newServiceMrc,
  );
  const isDisableQuotesProvide = allOrdersHaveProvideQuoteDatetime
  || isQuotesProvideDisabledByAdditionalConditions
  || isSomeOrderNotAcceptedByCS;

  const allOrdersHaveProvideContractDatetime = selectedOrders.every(
    (order) => order.provideContractDatetime,
  );
  const someOrdersHaveEmptyRequestContractDatetime = selectedOrders.some(
    (order) => !order.requestContractDatetime,
  );
  const isDisableContractsProvide = allOrdersHaveProvideContractDatetime
  || someOrdersHaveEmptyRequestContractDatetime
  || isContactAndOrderProvideDisabled
  || isSomeOrderNotAcceptedByCS;

  const someOrderHasProvideOrdersActionDatetimeOrEmptyFields = selectedOrders.some((
    {
      orderNumber,
      provideOrderActionDatetime,
      requestOrderActionDatetime,
      contractCountersigningDate,
    },
  ) => provideOrderActionDatetime
  || !requestOrderActionDatetime
  || !orderNumber
  || !contractCountersigningDate);

  const isDisabledProvideOrders = someOrderHasProvideOrdersActionDatetimeOrEmptyFields
  || isContactAndOrderProvideDisabled
  || isSomeOrderNotAcceptedByCS;

  const onProvideQuotes = () => {
    setOrderAction(OrderActions.PROVIDE_QUOTES);
    setIsActionsModalOpen(true);
  };

  const onProvideContracts = () => {
    setOrderAction(OrderActions.PROVIDE_CONTRACTS);
    setIsActionsModalOpen(true);
  };

  const onProvideOrders = () => {
    setOrderAction(OrderActions.PROVIDE_ORDERS);
    setIsActionsModalOpen(true);
  };

  const menuItems = compact([
    {
      label: SowDataActions.quotes,
      onClick: onProvideQuotes,
      isDisabled: isDisableQuotesProvide,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__provided-icon" />,
    },
    {
      label: SowDataActions.contracts,
      onClick: onProvideContracts,
      isDisabled: isDisableContractsProvide,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__provided-icon" />,
    },
    {
      label: SowDataActions.orders,
      onClick: onProvideOrders,
      isDisabled: isDisabledProvideOrders,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__provided-icon" />,
    },
  ]);

  return (
    <GroupMenuItems
      groupLabel="Provide"
      listItems={menuItems}
    />
  );
};

export default SowDataActionsProvide;
