import React from 'react';
import compact from 'lodash/compact';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import OrderDataIcon from '@mui/icons-material/DescriptionOutlined';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { useRenewalSowPermissions } from '@EngagementsRenewalSowData/useRenewalSowPermission';

interface IRenewalSowDataTableRowActionsProps {
  row: IRenewalSowDataItem
}

const RenewalSowDataTableRowActions: React.FC<
  IRenewalSowDataTableRowActionsProps
> = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,

    setActiveRowView,
    setActiveRowEdit,
    setActiveRowComment,
    setActiveRowOrderData,
    setActiveRowCancelOrder,
    setActiveRowEscalationSupport,
    setActiveRowDeclineRenewal,
  } = useTableActiveRowContext<IRenewalSowDataItem>();
  const {
    isDataMode,
    isCommentMode,
    isCancelOrderMode,
    isEscalationSupportMode,
    isDeclineRenewalMode,
  } = getTableRowModeBoolean(activeRowMode);

  const {
    isAvailableEditOrder,
    isAvailableCancelOrder,
    isAvailableGetComments,
    isAvailableGetOrderData,
    isAvailableCreateEscalationSupport,
    isAvailableDeclineRenewal,
  } = useRenewalSowPermissions();

  const { id: rowId, cancelRequestDate } = row;

  const isAlreadyCanceledOrder = !!cancelRequestDate;

  const isEscalationSupportBtnSelected = isEscalationSupportMode && rowId === activeRowId;
  const isCommentButtonSelected = isCommentMode && rowId === activeRowId;
  const isOrderDataButtonSelected = isDataMode && rowId === activeRowId;
  const isCancelButtonSelected = isCancelOrderMode && rowId === activeRowId;
  const isDeclineRenewalSelected = isDeclineRenewalMode && rowId === activeRowId;

  const isCommentButtonDisabled = !!activeRowId && !isCommentButtonSelected;
  const isOrderDataButtonDisabled = !!activeRowId && !isOrderDataButtonSelected;
  const isEscalationSupportBtnDisabled = isAlreadyCanceledOrder
    || (!!activeRowId && !isEscalationSupportBtnSelected);
  const isCancelButtonDisabled = isAlreadyCanceledOrder
    || (!!activeRowId && !isCancelButtonSelected);
  const isDeclineRenewalDisabled = isAlreadyCanceledOrder
    || (!!activeRowId && !isDeclineRenewalSelected);

  const isMoreButtonDisabled = isCancelButtonDisabled
    && isCommentButtonDisabled
    && isOrderDataButtonDisabled
    && isEscalationSupportBtnDisabled
    && isDeclineRenewalDisabled;

  const moreButtonConfig = compact([
    (isAvailableCreateEscalationSupport && {
      onClick: () => setActiveRowEscalationSupport(row),
      isDisabled: isEscalationSupportBtnDisabled,
      icon: <NextWeekOutlinedIcon />,
      text: 'Escalation Support',
    }),
    (isAvailableCancelOrder && {
      onClick: () => setActiveRowCancelOrder(row),
      isDisabled: isCancelButtonDisabled,
      icon: <ClearIcon />,
      text: 'Cancel Request',
    }),
    (isAvailableGetComments && {
      onClick: () => setActiveRowComment(row),
      isDisabled: isCommentButtonDisabled,
      icon: <CommentIcon />,
      text: 'Order Comments',
    }),
    (isAvailableGetOrderData && {
      onClick: () => setActiveRowOrderData(row),
      isDisabled: isOrderDataButtonDisabled,
      icon: <OrderDataIcon />,
      text: 'Order Data',
    }),
    (isAvailableDeclineRenewal && {
      onClick: () => setActiveRowDeclineRenewal(row),
      isDisabled: isDeclineRenewalDisabled,
      icon: <CancelIcon />,
      text: 'Decline Renewal',
    }),
  ]);

  return (
    <Actions<IRenewalSowDataItem>
      row={row}
      activeRowId={activeRowId}
      activeRowMode={activeRowMode}
      setActiveRowView={setActiveRowView}
      setActiveRowEdit={setActiveRowEdit}
      moreButtonConfig={moreButtonConfig}
      isViewButton={isAlreadyCanceledOrder}
      moreButtonTooltipTitle={orderActionTitle}
      isMoreButtonDisabled={isMoreButtonDisabled}
      isActionButtonVisible={isAvailableEditOrder}
    />
  );
};

export default RenewalSowDataTableRowActions;
