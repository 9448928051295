import React from 'react';
import paths from 'core/routing/paths';
import ContractFormView from 'pages/WirelineEngagements/shared/ContractForm/ContractFormView';

const ContractManagementContractFormView = () => (
  <ContractFormView
    backRoutePath={paths.contractManagementList}
  />
);

export default ContractManagementContractFormView;
