import React from 'react';
import compact from 'lodash/compact';
import paths from 'core/routing/paths';
import { GroupMenuItems } from 'shared/ButtonMenu';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SowDataActions } from '@EngagementsSowData/SowDataOrdersActions/constsnts';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import {
  quoteRequestDisabledConditions,
  orderRequestDisabledConditions,
  contractRequestDisabledMainConditions,
  contractRequestAndOrderRequestDisabledConditions,
} from '@EngagementsSowData/utilities';

interface ISowDataActionsRequestProps {
  selectedOrders: ISowDataListItem[],
  setOrderAction: (orderAction: OrderActions) => void,
  setIsActionsModalOpen: (isModalOpen: boolean) => void
}

const SowDataActionsRequest: React.FC<
  ISowDataActionsRequestProps
> = ({
  selectedOrders, setOrderAction, setIsActionsModalOpen,
}) => {
  const navigate = useNavigate();

  const isContractAndOrderRequestDisabled = selectedOrders.some(
    contractRequestAndOrderRequestDisabledConditions,
  );

  const everyOrdersHasRequestQuoteDatetime = selectedOrders.every(
    (order) => order.requestQuoteDatetime,
  );
  const isQuoteRequestDisabledByAdditionalConditions = selectedOrders.some(
    quoteRequestDisabledConditions,
  );

  const isDisableQuotesRequest = everyOrdersHasRequestQuoteDatetime
  || isQuoteRequestDisabledByAdditionalConditions;

  const isContractRequestDisabledByMainConditions = selectedOrders.some(
    contractRequestDisabledMainConditions,
  );
  const isDisableContractRequest = isContractRequestDisabledByMainConditions
   || isContractAndOrderRequestDisabled;

  const isOrderRequestDisabledByAdditionalConditions = selectedOrders.some(
    orderRequestDisabledConditions,
  );
  const isDisabledRequestOrders = isOrderRequestDisabledByAdditionalConditions
   || isContractAndOrderRequestDisabled;

  const orderIds = selectedOrders.map(({ id }) => id).join(',');

  const onRequestQuotes = async () => {
    setOrderAction(OrderActions.REQUEST_QUOTES);
    setIsActionsModalOpen(true);
  };

  const onRequestContracts = () => {
    setOrderAction(OrderActions.REQUEST_CONTRACTS);
    navigate({
      pathname: paths.wirelineEngagementsSowDataContractForm.split('/').pop() as string,
      search: `?${createSearchParams({ orderIds })}`,
    });
  };

  const onRequestOrders = () => {
    setOrderAction(OrderActions.REQUEST_ORDERS);
    navigate({
      pathname: paths.wirelineEngagementsRequestOrder.split('/').pop() as string,
      search: `?${createSearchParams({ orderIds })}`,
    });
  };

  const menuItems = compact([
    {
      label: SowDataActions.quotes,
      onClick: onRequestQuotes,
      isDisabled: isDisableQuotesRequest,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__request-icon" />,
    },
    {
      label: SowDataActions.contracts,
      onClick: onRequestContracts,
      isDisabled: isDisableContractRequest,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__request-icon" />,
    },
    {
      label: SowDataActions.orders,
      onClick: onRequestOrders,
      isDisabled: isDisabledRequestOrders,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__request-icon" />,
    },
  ]);

  return (
    <GroupMenuItems
      groupLabel="Request"
      listItems={menuItems}
    />
  );
};

export default SowDataActionsRequest;
