import React, {
  createContext, ReactNode, useMemo, useContext,
} from 'react';

interface IButtonMenuProviderProps<T> {
  onClose: () => void
  children: ReactNode
}

interface IButtonMenuContext {
  onClose: () => void
}

export const ButtonMenuContext = createContext<IButtonMenuContext>({} as IButtonMenuContext);

export const ButtonMenuProvider = <T extends unknown> ({
  children, onClose,
}: IButtonMenuProviderProps<T>) => {
  const memoizedValue = useMemo(() => ({ onClose }), [onClose]);

  return (
    <ButtonMenuContext.Provider value={memoizedValue}>
      {children}
    </ButtonMenuContext.Provider>
  );
};

export const useButtonMenuContext = () => useContext(ButtonMenuContext);
