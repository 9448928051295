import React, { useMemo } from 'react';
import { useAppSelector } from 'core/store';
import CustomAccardion from 'shared/CustomAccardion';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { IOrderDataWithRelatedOrders } from 'core/models/order.models';
import OrderDataList from 'pages/WirelineEngagements/shared/OrderDataSidebar/OrderDataList';
import { getLabelesFromShema } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { getRenderOrdersList } from 'pages/WirelineEngagements/shared/AccardionOrderDataSidebar/utilities';

import './AccardionOrderDataSidebar.scss';

interface IOrderDataContentProps {
  schemaKey: string
  orderData: IOrderDataWithRelatedOrders
}

const OrderDataContent: React.FC<IOrderDataContentProps> = ({
  orderData,
  schemaKey,
}) => {
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);

  const shemaData = useMemo(() => {
    const parsedSchema = parseOpenapiSchema(openApiSchemas, schemaKey);

    return getLabelesFromShema(parsedSchema);
  }, [openApiSchemas]);

  const renderData = useMemo(
    () => getRenderOrdersList(orderData, shemaData),
    [shemaData, orderData],
  );

  return (
    <>
      {renderData.map(({ label, data }, index) => (
        <CustomAccardion
          key={label}
          title={label}
          defaultExpanded={!index}
          className="order-data-accardion"
          content={<OrderDataList data={data} />}
        />
      ))}
    </>
  );
};

export default OrderDataContent;
