import React, { memo } from 'react';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import DeclineOrderModalForm from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal/DeclineOrderModalForm';

const DeclineModal = () => {
  const {
    activeRow,
    activeRowMode,
    cleanActiveRow,
  } = useTableActiveRowContext<IRenewalSowDataItem>();

  const { isDeclineRenewalMode } = getTableRowModeBoolean(activeRowMode);

  return (
    <DeclineOrderModalForm
      activeRow={activeRow}
      isOpen={isDeclineRenewalMode}
      cleanActiveRow={cleanActiveRow}
    />
  );
};

export default memo(DeclineModal);
