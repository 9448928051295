import React, { useEffect } from 'react';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { dynamicRenewalProductSchemaName } from '@EngagementsRenewalSowData/constants';
import AccardionOrderDataSidebar from 'pages/WirelineEngagements/shared/AccardionOrderDataSidebar';
import { clearGetRenealSowItemData, getSalesRenewalSowItemAction } from 'core/actions/Sales/GetSalesRenewalSowItem';
import { getRenewalSowMessage } from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/constants';

const DataRowSidebar = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();

  const {
    activeRowId, activeRowMode, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode,
  } = useTableActiveRowContext();

  const isLoadingRenewalSowItem = useAppSelector(
    (state) => state.wirelineEngagements.renewalSowItem.isLoading,
  );
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);
  const renewalSowItem = useAppSelector((state) => state.wirelineEngagements.renewalSowItem.result);

  const isLoading = isLoadingRenewalSowItem || isLoadingSchemas;

  const { isDataMode } = getTableRowModeBoolean(activeRowMode);
  const { isDataMode: isDataInsideEditMode } = getTableRowModeBoolean(secondarySidebarMode);

  const isOpen = !!activeRowId && (isDataMode || isDataInsideEditMode);
  const isFetchData = !!activeRowId && isDataMode;

  const onCloseHandler = () => (secondarySidebarMode
    ? setSecondarySidebarMode(null)
    : cleanActiveRow());

  const getSalesRenewalSowItem = async (rowId: number | string) => {
    try {
      await dispatch(getSalesRenewalSowItemAction(rowId))
        .unwrap();
    } catch (error) {
      showErrorNotifier(getRenewalSowMessage.error, error);
    }
  };

  useEffect(() => {
    if (!isFetchData) return;

    getSalesRenewalSowItem(activeRowId);

    return () => {
      dispatch(clearGetRenealSowItemData());
    };
  }, [activeRowId]);

  return (
    <AccardionOrderDataSidebar
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onCloseHandler}
      orderData={renewalSowItem}
      schemaKey={dynamicRenewalProductSchemaName}
    />
  );
};

export default DataRowSidebar;
