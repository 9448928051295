import React, { memo } from 'react';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RequestTypeLabels } from 'pages/ContractManagment/constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import { getContractManagementContractFormView } from 'core/routing/paths';

interface IRequestTypeCellProps {
  rowData: IContract
}

const RequestTypeCell: React.FC<IRequestTypeCellProps> = ({
  rowData,
}) => {
  const navigate = useNavigate();

  const { requestType, customerId, contractBundleId } = rowData || {};
  const renderRequestType = RequestTypeLabels[requestType] || '';

  const onOpenContractForm = () => {
    const path = getContractManagementContractFormView(customerId, contractBundleId);
    navigate(path);
  };

  return (
    <div className="request-type-cell">
      <span className="request-type-cell__title">
        {renderRequestType}
      </span>
      {
        contractBundleId && (
          <IconButton
            onClick={onOpenContractForm}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        )
      }
    </div>
  );
};

export default memo(RequestTypeCell);
