export interface IRenewalSowDataItem {
    requestQuoteDatetime: string,
    provideQuoteDatetime: string,
    requestContractDatetime: string,
    provideContractDatetime: string,
    billingEngagementAcceptedDate: string,
    hasEscalationRequests: boolean,
    product: string,
    term: number,
    bandwidth: string,
    customerLocation: string,
    sellerRequestType: string,
    contractOrderSupport: string,
    billingSupport: string,
    customerAssistBilling: string,
    customerCompanyName: string,
    sellerFullname: string,
    technicalOverlayFullname: string,
    billingSpecialistFullname: string,
    billingSpecialistStatus: string,
    cancelRequestDate: string,
    id: number,
    quoteId: number,
    requestedContractId: number,
    contractBundleId: number,
    orderActionId: number,
    system: string,
    existingService: string,
    cosProfile: string,
    cosPackage: string,
    managementOptions: string,
    diversity: string,
    diversityOptions: string,
    handoffType: string,
    oneTimeInstallationOptions: string,
    numberOfIpAddresses: string,
    bgpRouting: string,
    pbx: string,
    enhancedFeatures: string,
    includeTollFree: string,
    autoDefaultToNewTfn: string,
    resporgExistingTollFreeNumber: string,
    copyOfBillForResporgProvided: string,
    customPricing: string,
    useExistingContract: string,
    whosLogin: string,
    creditBucket: string,
    verifyRequest: string,
    orderHandoffDate: string,
    fodScheduled: string,
    fodCompleted: string,
    focScheduled: string,
    focCompleted: string,
    eadScheduled: string,
    eadCompleted: string,
    ctaScheduled: string,
    ctaCompleted: string,
    ddScheduled: string,
    ddCompleted: string,
    customerRouterDeliveryDate: string,
    attRouterDeliveryDate: string,
    nextFollowUpDate: string,
    billingInitiatedDate: string,
    fdoc: string,
    creditApproved: string,
    gbrt: string,
    creditReflected: string,
    submittedCancelDiscoDate: string,
    disconnectCompletionDate: string,
    cancellationCompletionDate: string,
    existingMrc: string,
    existingNrc: string,
    quantityOfNewBvoip: string,
    numberOfConcurrentCalls: string,
    utilizationBandwidth: string,
    portBandwidth: string,
    cirBandwidth: string,
    customPricingBusinessCase: string,
    confirmCarrier: string,
    existingContractId: number,
    existingCktId: number,
    existingAccountNumber: string,
    existing999AccountNumber: string,
    existingTnInventoryToKeep: string,
    sdop: string,
    orderedBandwidth: string,
    oppId: number,
    mdsHaloId: number,
    existingServiceAccountNumber: string,
    newServiceAccountNumber: string,
    mcn: string,
    rdsTrackingNumber: string,
    osmLink: string,
    delayFactor: string,
    newServiceCircuitId: string,
    lecCircuitId: string,
    lecName: string,
    routerHostName: string,
    ipDetails: string,
    routerTrackingNumber: string,
    demarc: string,
    additionalInformation: string,
    creditGivenAmount: string,
    correctCharge: string,
    efficiency: string,
    erbTrackingNumber: string,
    disconnectTrackingNumber: string,
    existingProduct: string,
    businessCenterProfile?: string,
    businessCenterProfileUsername?: string,
    businessCenterProfileCompanyId?: string,
}

export enum RenewalStatusSow {
    DECLINED = 'DECLINED',
    RENEWED = 'RENEWED',
    IN_PROGRESS = 'IN_PROGRESS',
}
