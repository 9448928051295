import React, { useMemo, useEffect } from 'react';
import { Formik, Form } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { IServerValidationError } from 'core/services/HTTPService.models';
import HeaderActions from 'pages/WirelineEngagements/shared/HeaderActions';
import EditRowSidebar from 'pages/WirelineEngagements/shared/EditRowSidebar';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { dynamicRenewalProductSchemaName } from '@EngagementsRenewalSowData/constants';
import { ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS } from 'core/models/actionsPermissions';
import { getParsedRowData } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { getSalesRenewalSowItemAction, clearGetRenealSowItemData } from 'core/actions/Sales/GetSalesRenewalSowItem';
import { additianalOrderFields } from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields/constants';
import { getRenewalSowMessage } from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/constants';
import { editSalesRenewalSowItemAction, clearEditSalesRenewalSowItemData } from 'core/actions/Sales/EditSalesRenewalSowItem';
import {
  addAdditionalFieldsToOrder,
  omitAdditionalFieldsFromOrder,
} from 'pages/WirelineEngagements/shared/EditRowSidebar/utilities';
import {
  includedFields,
  editRenewalSowTitle,
  viewRenewalSowTitle,
  patchRenewalSowMessage,
} from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EditRowSidebarForm/constants';

const {
  ORDER_DATA_GET,
  COMMENTS_GENERAL_GET,
  COMMENTS_BILLING_GET,
} = ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS;

const actionsPaths = {
  isAvailableGetOrderData: ORDER_DATA_GET,
  isAvailableGetGeneralComments: COMMENTS_GENERAL_GET,
  isAvailableGetBillingComments: COMMENTS_BILLING_GET,
};

const EditRowSidebarForm = () => {
  const {
    activeRowMode, activeRowId, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode,
  } = useTableActiveRowContext<IRenewalSowDataItem>();
  const dispatch = useAppDispatch();
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();

  const { getTableData } = useTableContext();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const {
    isAvailableGetOrderData,
    isAvailableGetGeneralComments,
    isAvailableGetBillingComments,
  } = useActionsPermissions(actionsPaths);

  const isAvailableGetComments = isAvailableGetGeneralComments || isAvailableGetBillingComments;

  const renewalSowItem = useAppSelector(
    (state) => state.wirelineEngagements.renewalSowItem.result,
  );
  const isLoadingRenewalSowItem = useAppSelector(
    (state) => state.wirelineEngagements.renewalSowItem.isLoading,
  );

  const isLoadingEdidRenewalSowItem = useAppSelector(
    (state) => state.wirelineEngagements.editRenewalSowItem.isLoading,
  );

  const { editableRenewalSowData, salesRenewalSowItemKeys } = useMemo(() => {
    const editableData = getParsedRowData({
      includedFields,
      isEditable: true,
      rowData: renewalSowItem,
    });
    const itemKeys = Object.keys(editableData);

    return { editableRenewalSowData: editableData, salesRenewalSowItemKeys: itemKeys };
  }, [renewalSowItem]);

  const initialFormData = useMemo(() => addAdditionalFieldsToOrder({
    order: renewalSowItem,
    fields: additianalOrderFields,
    parsedOrder: editableRenewalSowData,
  }),
  [renewalSowItem, editableRenewalSowData]);

  const title = isEditMode ? editRenewalSowTitle : viewRenewalSowTitle;
  const editRowId = (isEditMode || isViewMode) ? activeRowId : undefined;
  const isLoading = isLoadingRenewalSowItem || isLoadingEdidRenewalSowItem;

  const showSidebar = activeRowId && (isViewMode || isEditMode);

  const topActions = (
    <HeaderActions
      secondarySidebarMode={secondarySidebarMode}
      isVisibleCommentsButton={isAvailableGetComments}
      setSecondarySidebarMode={setSecondarySidebarMode}
      isVisibleOrderDataButton={isAvailableGetOrderData}
    />
  );

  const onSubmit = async (params, { setErrors, resetForm }) => {
    if (!activeRowId || !isEditMode) return;

    const formatedParams = omitAdditionalFieldsFromOrder({
      order: params,
      mandatoryFields: includedFields,
      initialOrder: editableRenewalSowData,
      additionalFields: additianalOrderFields,
    });

    try {
      await dispatch(editSalesRenewalSowItemAction({ id: activeRowId, params: formatedParams }))
        .unwrap();

      cleanActiveRow();
      showSuccessNotifier(patchRenewalSowMessage.success);
      getTableData();
      resetForm();
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);
      showErrorNotifier(patchRenewalSowMessage.error, error);
    }
  };

  const getSalesRenewalSowItem = async (rowId: number | string) => {
    try {
      await dispatch(getSalesRenewalSowItemAction(rowId))
        .unwrap();
    } catch (error) {
      showErrorNotifier(getRenewalSowMessage.error, error);
    }
  };

  useEffect(() => {
    if (!showSidebar) return;

    getSalesRenewalSowItem(activeRowId);

    return () => {
      dispatch(clearGetRenealSowItemData);
      dispatch(clearEditSalesRenewalSowItemData);
    };
  }, [activeRowId]);

  if (!showSidebar) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialFormData}
    >
      <Form>
        <EditRowSidebar
          title={title}
          editRowId={editRowId}
          isViewMode={isViewMode}
          topActions={topActions}
          isLoadingItem={isLoading}
          cleanEditRow={cleanActiveRow}
          allowedFields={salesRenewalSowItemKeys}
          secondarySidebarMode={secondarySidebarMode}
          schemaName={dynamicRenewalProductSchemaName}
          setSecondarySidebarMode={setSecondarySidebarMode}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
