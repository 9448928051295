import omit from 'lodash/omit';
// eslint-disable-next-line no-unused-vars
import { IOrderData, IParsedOrderData } from 'core/models/order.models';

export const addAdditionalFieldsToOrder = ({
  order,
  fields,
  parsedOrder,
}: {
  fields: string[],
  order: IOrderData,
  parsedOrder: IParsedOrderData,
}) => fields.reduce(
  (value, field) => {
    const isExistingInParsedOrder = field in parsedOrder;
    const isExistingInOrder = field in order;

    return isExistingInParsedOrder || !isExistingInOrder
      ? value
      : { ...value, [field]: order?.[field]?.value };
  },
  parsedOrder);

export const omitAdditionalFieldsFromOrder = ({
  order,
  initialOrder,
  mandatoryFields,
  additionalFields,
}: {
    order: IParsedOrderData,
    mandatoryFields: string[],
    additionalFields: string[],
    initialOrder: IParsedOrderData,
  }) => {
  const omitFields = additionalFields.filter((field) => !(field in initialOrder));

  return omit(order, [...omitFields, ...mandatoryFields]);
};
