import React from 'react';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import EscalationSupportModalForm from 'pages/WirelineEngagements/shared/EscalationSupportModalForm';
import { escalationTypeRenewalOptions } from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/constants';

const escalationSupportRequestParams = { isRenewal: true };

const EscalationSupportModal = () => {
  const {
    activeRowMode, activeRow, activeRowId, cleanActiveRow,
  } = useTableActiveRowContext<IRenewalSowDataItem>();

  const { isEscalationSupportMode } = getTableRowModeBoolean(activeRowMode);

  const escalationSupportRowId = isEscalationSupportMode ? activeRowId : undefined;
  const escalationSupportRow = isEscalationSupportMode ? activeRow : undefined;

  return (
    <EscalationSupportModalForm
      cleanEscalationSupportRow={cleanActiveRow}
      escalationSupportRow={escalationSupportRow}
      requestParams={escalationSupportRequestParams}
      escalationSupportRowId={escalationSupportRowId}
      escalationTypeOptions={escalationTypeRenewalOptions}
    />
  );
};

export default EscalationSupportModal;
