import React, {
  memo, useState, ReactNode, ReactElement,
} from 'react';
import cx from 'classnames';
import CustomTooltip from 'shared/CustomTooltip';
import { OverridableStringUnion } from '@mui/types';
import { ButtonMenuProvider } from 'shared/ButtonMenu/ButtonMenuContext';
import { Menu, Button, ButtonPropsVariantOverrides } from '@mui/material';
import { TooltipPlacement } from 'shared/CustomTooltip/customTooltip.models';

import './ButtonMenu.scss';

type TButtonVariant = OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>

interface IButtonMenuProps {
  isDisabled: boolean
  tooltipTitle?: string
  buttonIcon?: ReactNode
  menuClassName?: string
  buttonClassName?: string
  buttonVariant?: TButtonVariant
  buttonContent: string | ReactElement
  children: ReactElement | ReactElement[]
}

const ButtonMenu: React.FC<IButtonMenuProps> = ({
  children,
  isDisabled,
  buttonIcon,
  tooltipTitle,
  buttonContent,
  buttonVariant,
  menuClassName,
  buttonClassName,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomTooltip
        isHidden={!tooltipTitle}
        title={tooltipTitle as string}
        placement={TooltipPlacement.TOP}
      >
        <Button
          disabled={isDisabled}
          startIcon={buttonIcon}
          onClick={handleOpenMenu}
          variant={buttonVariant as TButtonVariant}
          className={cx(buttonClassName, 'button-menu-btn')}
        >
          {buttonContent}
        </Button>
      </CustomTooltip>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={cx(menuClassName, 'button-menu-actions')}
      >
        <ButtonMenuProvider onClose={handleClose}>
          {children}
        </ButtonMenuProvider>
      </Menu>
    </>
  );
};

ButtonMenu.defaultProps = {
  tooltipTitle: '',
  menuClassName: '',
  buttonClassName: '',
  buttonIcon: undefined,
  buttonVariant: 'outlined',
};

export default memo(ButtonMenu);
