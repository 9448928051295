import { combineReducers } from '@reduxjs/toolkit';
import { requestAcceptedReducer } from 'core/actions/ContractManagement/RequestAccepted';
import { requestCompleteReducer } from 'core/actions/ContractManagement/RequestComplete';
import { editRowContractManagementReducer } from 'core/actions/ContractManagement/EditRowContractManagement';
import { contractSpecialistReducer } from 'core/actions/ContractManagement/ContractSpecialist';
import { getContractManagementListReducer } from './ContractManagementList/GetContractManagementList';
import {
  getContractManagementItemReducer,
} from './ContractManagementList/GetContractManagementItem/GetContractManagementItem';

const contractManagementReducer = combineReducers({
  list: getContractManagementListReducer,
  requestAccepted: requestAcceptedReducer,
  requestComplete: requestCompleteReducer,
  contractSpecialist: contractSpecialistReducer,
  editRowContractManagement: editRowContractManagementReducer,
  item: getContractManagementItemReducer,
});

export default contractManagementReducer;
