import React from 'react';
import WidgetTemplate from 'shared/widgets/WidgetTemplate';
import AttachmentsContent from 'shared/widgets/Attachments/AttachmentsContent';
// eslint-disable-next-line no-unused-vars
import { IAttachmentsProps } from 'shared/widgets/Attachments/Attachments.model';

const Attachments: React.FC<IAttachmentsProps> = ({
  isLoading, isAvailableAddAttachments = true, ...props
}) => (
  <WidgetTemplate
    title="Attachments"
    isLoading={isLoading}
    className="widget-attachments"
    content={(
      <AttachmentsContent
        {...props}
        isLoading={isLoading}
        isAvailableAddAttachments={isAvailableAddAttachments}
      />
    )}
  />
);

export default Attachments;
