import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
/* eslint-disable no-param-reassign */
import { patchEditRowContractManagementAction } from 'core/api/contractManagment.api';

const initialState = {
  isLoading: false,
};

export const editRowContractManagementAction = createAsyncThunk(
  'editRowAction',
  async (
    { orderId, params } : { orderId: number, params: object },
    { rejectWithValue },
  ) => {
    try {
      await patchEditRowContractManagementAction(orderId, params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const editRowContractManagementSlice = createSlice({
  name: 'editRowContractManagement',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        editRowContractManagementAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(
          editRowContractManagementAction.rejected,
          editRowContractManagementAction.fulfilled,
        ),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const editRowContractManagementReducer = editRowContractManagementSlice.reducer;
