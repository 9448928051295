import React from 'react';
import { TableRowMode } from 'shared/Table/Table.models';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import CommentsRowSidebar from 'pages/WirelineEngagements/shared/CommentsRowSidebar';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS } from 'core/models/actionsPermissions';
import { COMMENTS_ORDER_CATEGORY } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/constants';

const {
  GENERAL,
  BILLING,
} = COMMENTS_ORDER_CATEGORY;

const {
  COMMENTS_GENERAL_GET,
  COMMENTS_BILLING_GET,
  COMMENTS_GENERAL_CREATE,
  COMMENTS_BILLING_CREATE,
} = ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS;

const actionsPaths = {
  isAvailableGetGeneralComments: COMMENTS_GENERAL_GET,
  isAvailableGetBillingComments: COMMENTS_BILLING_GET,
  isAvailableCreateGeneralComments: COMMENTS_GENERAL_CREATE,
  isAvailableCreateBillingComments: COMMENTS_BILLING_CREATE,
};

const CommentsSidebar = () => {
  const {
    activeRowMode, activeRowId, cleanActiveRow,
    secondarySidebarMode, setSecondarySidebarMode,
  } = useTableActiveRowContext<IRenewalSowDataItem>();

  const {
    isAvailableGetGeneralComments,
    isAvailableGetBillingComments,
    isAvailableCreateGeneralComments,
    isAvailableCreateBillingComments,
  } = useActionsPermissions(actionsPaths);

  const availableCreateCategories = {
    [GENERAL]: isAvailableCreateGeneralComments,
    [BILLING]: isAvailableCreateBillingComments,
  };

  const availableVissibleCategories = {
    [GENERAL]: isAvailableGetGeneralComments,
    [BILLING]: isAvailableGetBillingComments,
  };

  const { isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const isCommentsInsideEditOpen = secondarySidebarMode === TableRowMode.COMMENT;

  const isActiveRow = isCommentMode || isCommentsInsideEditOpen;
  const commentsActiveRowId = (isActiveRow ? activeRowId : null) as string | number | null;

  return (
    <CommentsRowSidebar
      title="Order Comments"
      activeRowId={commentsActiveRowId}
      cleanActiveRow={cleanActiveRow}
      setSecondarySidebarMode={setSecondarySidebarMode}
      isCommentsInsideEditOpen={isCommentsInsideEditOpen}
      availableCreateCategories={availableCreateCategories}
      availableVissibleCategories={availableVissibleCategories}
    />
  );
};

export default CommentsSidebar;
