import {
  MRT_ColumnFiltersState as ColumnFiltersState,
  MRT_PaginationState as PaginationState,
  MRT_SortingState as SortingState,
} from 'material-react-table';

export type TRow<T = string | number | unknown > = {
    [key: string]: T;
};

export type ColumnSort = { id: string | null, desc: boolean | null }

export interface ITableCell<IDataItem, ICellValue = unknown> {
    row: { original: IDataItem }
    renderedCellValue: ICellValue,
}

export interface IMuiTableCellProps {
    sx: {
        backgroundColor?: string;
        borderTop?: string;
        paddingLeft?: string;
        paddingRight?: string;
        fontSize?: string;
        textTransform?: string;
    };
}

interface IFilterSelectOption {
    value: number;
    text: string;
}

export interface ITableColumn {
    accessorKey: string;
    size?: number;
    muiTableHeadCellProps?: IMuiTableCellProps | undefined;
    muiTableBodyCellProps?: IMuiTableCellProps | undefined;
    Cell?: React.FC<{ renderedCellValue: string | undefined }>;
    filterVariant?: string | undefined;
    filterFn?: string | undefined;
    filterSelectOptions?: IFilterSelectOption[] | undefined;
    muiTableHeadCellFilterTextFieldProps?: { placeholder: string } | undefined;
    sortDescFirst?: boolean | undefined;
    enableColumnFilter?: boolean | undefined;
}

export enum ParamTypes {
    PAGINATION = 'pagination',
    FILTERS = 'filters',
    SEARCH = 'search',
    SORTING = 'sorting',
}

export interface ITableFilters {
    pagination: PaginationState
    sorting: SortingState
    search: string
    filters: ColumnFiltersState
}

export type TableFiltersState = PaginationState | SortingState | string | ColumnFiltersState;

export interface ITableContainerProps {
    sx: {
        maxHeight: string,
    },
}

export enum TableRowMode {
    EDIT = 'EDIT',
    DATA = 'DATA',
    ESCALATION_SUPPORT = 'ESCALATION_SUPPORT',
    CANCEL_ORDER = 'CANCEL_ORDER',
    VIEW = 'VIEW',
    COMMENT = 'COMMENT',
    DUPLICATE ='DUPLICATE',
    DECLINE_RENEWAL ='DECLINE_RENEWAL'
}
