import React from 'react';
import { CustomDialogContent } from 'shared/CustomDialog';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import RequestAcceptedItems from 'pages/ContractManagment/ContractManagementList/RequestsActions/shared/RequestActionModalContent/RequestAcceptedItems';
import {
  buttonWidth,
  requestAcceptedButtonText,
} from 'pages/ContractManagment/ContractManagementList/RequestsActions/constants';

interface IRequestActionModalContentProps {
  title: string
  isLoading: boolean
  onClose: () => void
  contracts: IContract[]
  onClickConfirm: () => void
  children?: React.ReactNode
  confirmButtonDisable?: boolean
}

const RequestActionModalContent: React.FC<IRequestActionModalContentProps> = ({
  title,
  onClose,
  isLoading,
  contracts,
  onClickConfirm,
  confirmButtonDisable,
  children,
}) => (
  <CustomDialogContent
    title={title}
    onClose={onClose}
    areButtonsVisible
    isLoading={isLoading}
    buttonWidth={buttonWidth}
    onClickConfirm={onClickConfirm}
    cancelButtonText={requestAcceptedButtonText.cancelButtonText}
    confirmButtonText={requestAcceptedButtonText.confirmButtonText}
    confirmButtonDisable={confirmButtonDisable}
  >
    <>
      <RequestAcceptedItems
        selectedContracts={contracts}
      />
      {
        children
      }
    </>
  </CustomDialogContent>
);

RequestActionModalContent.defaultProps = {
  children: null,
  confirmButtonDisable: false,
};

export default RequestActionModalContent;
