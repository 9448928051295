import React, { ReactNode } from 'react';
import cx from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, ListItemText } from '@mui/material';

interface ICustomMenuItemProps {
  label: string
  icon?: ReactNode
  onAction: () => void
  onClose?: (() => void) | undefined
  isDisabled?: boolean | undefined
}

const CustomMenuItem: React.FC<ICustomMenuItemProps> = ({
  onAction, onClose, icon, isDisabled, label,
}) => {
  const onClick = () => {
    onClose?.();
    if (isDisabled) {
      return;
    }
    onAction();
  };

  return (
    <MenuItem
      onClick={onClick}
      disabled={!!isDisabled}
    >
      {
        icon && (
          <ListItemIcon
            className={cx({
              'item-disabled': isDisabled,
            })}
          >
            {icon}
          </ListItemIcon>
        )
      }
      <ListItemText>
        {label}
      </ListItemText>
    </MenuItem>
  );
};

CustomMenuItem.defaultProps = {
  icon: undefined,
  isDisabled: false,
  onClose: () => {},
};

export default CustomMenuItem;
