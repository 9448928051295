import React from 'react';
import { Formik, Form } from 'formik';
import { useAppDispatch } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
// eslint-disable-next-line no-unused-vars
import { IOrderDecline } from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal/DeclineOrder.model';
import DeclineOrderModal from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal/DeclineOrderModalForm/DeclineOrderModal';
import { declineOrderAction } from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal/DeclineOrderModalForm/DeclineOrder';
import { declineOrderFormSchema } from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal/DeclineOrderModalForm/DeclineOrderFormSchema';
import {
  reasonDecline,
  declineRenewalTitle,
} from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal/DeclineOrderModalForm/constants';

interface IDeclineOrderModalFormProps {
  isOpen: boolean;
  cleanActiveRow: () => void;
  activeRow: IRenewalSowDataItem | null | undefined;
}

const DeclineOrderModalForm: React.FC<IDeclineOrderModalFormProps> = ({
  isOpen,
  activeRow,
  cleanActiveRow,
}) => {
  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();

  const declineOrderRowId = activeRow?.id;

  const initialValues = declineOrderFormSchema.getDefault();

  const onSubmit = async (params: IOrderDecline, { setErrors, resetForm }) => {
    if (!declineOrderRowId) return;

    const requestParams = {
      text: `${reasonDecline}: ${params.text}`,
    };

    try {
      await dispatch(declineOrderAction({
        id: declineOrderRowId,
        params: requestParams,
      })).unwrap();

      cleanActiveRow();
      getTableData();
      resetForm();
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);
    }
  };

  if ((!declineOrderRowId || !isOpen)) {
    return null;
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={declineOrderFormSchema}
    >
      <Form>
        <DeclineOrderModal
          activeRow={activeRow}
          reasonLabel={reasonDecline}
          title={declineRenewalTitle}
          cleanActiveRow={cleanActiveRow}
        />
      </Form>
    </Formik>
  );
};

export default DeclineOrderModalForm;
