import { EngagementType, RequestType, CommissionType } from 'pages/ContractManagment/ContractManagement.models';

export const mockData = [
  {
    id: 1,
    companyName: 'BBC',
    sellerFullname: 'Peter',
    contractSpecialistFullname: 'Henry',
    engagementType: 'PENDING',
    requestType: 'QUOTE',
    comissionType: 'DOWNGRADE',
    acceptedDatetime: '2024-11-08T15:55:29.045013Z',
    product: 'TV',
    customerName: 'Lana',
  },
  {
    id: 2,
    companyName: 'Samsung',
    sellerFullname: 'Harry',
    contractSpecialistFullname: 'Mary',
    engagementType: 'IN PROGRESS',
    requestType: 'CONTRACT',
    comissionType: 'DOWNGRADE',
    acceptedDatetime: '2024-10-08T15:55:29.045013Z',
    product: 'Mobile',
    customerName: 'Bob',
  },
  {
    id: 3,
    companyName: 'Bosch',
    sellerFullname: 'Kim',
    contractSpecialistFullname: 'Kelly',
    engagementType: 'COMPLETED',
    requestType: 'ORDER',
    comissionType: 'DOWNGRADE',
    acceptedDatetime: '2024-12-08T15:55:29.045013Z',
    product: 'Mobile',
    customerName: 'Jacob',
  },
];

export const EngagementTypeLabels = {
  [EngagementType.PENDING]: 'Pending',
  [EngagementType.IN_PROGRESS]: 'In Progress',
  [EngagementType.COMPLETED]: 'Completed',
  [EngagementType.CANCELED]: 'Canceled',
  [EngagementType.DECLINED]: 'Declined',
};

export const EngagementTypeOptions = [
  {
    value: EngagementType.PENDING,
    label: EngagementTypeLabels[EngagementType.PENDING],
  },
  {
    value: EngagementType.IN_PROGRESS,
    label: EngagementTypeLabels[EngagementType.IN_PROGRESS],
  },
  {
    value: EngagementType.COMPLETED,
    label: EngagementTypeLabels[EngagementType.COMPLETED],
  },
  {
    value: EngagementType.CANCELED,
    label: EngagementTypeLabels[EngagementType.CANCELED],
  },
  {
    value: EngagementType.DECLINED,
    label: EngagementTypeLabels[EngagementType.DECLINED],
  },
];

export const RequestTypeLabels = {
  [RequestType.QUOTE]: 'Quote',
  [RequestType.CONTRACT]: 'Contract',
  [RequestType.ORDER]: 'Order',
  [RequestType.RENEWAL_QUOTE]: 'Renewal Quote',
  [RequestType.RENEWAL_CONTRACT]: 'Renewal Contract',
};

export const RequestTypeOptions = [
  {
    value: RequestType.QUOTE,
    label: RequestTypeLabels[RequestType.QUOTE],
  },
  {
    value: RequestType.CONTRACT,
    label: RequestTypeLabels[RequestType.CONTRACT],
  },
  {
    value: RequestType.ORDER,
    label: RequestTypeLabels[RequestType.ORDER],
  },
  {
    value: RequestType.RENEWAL_QUOTE,
    label: RequestTypeLabels[RequestType.RENEWAL_QUOTE],
  },
  {
    value: RequestType.RENEWAL_CONTRACT,
    label: RequestTypeLabels[RequestType.RENEWAL_CONTRACT],
  },
];

export const CommissionTypeLabels = {
  [CommissionType.NEW]: 'New',
  [CommissionType.RE_ORDER]: 'Re-Order',
  [CommissionType.RENEWAL]: 'Renewal',
  [CommissionType.DOWNGRADE]: 'Downgrade',
  [CommissionType.UPGRADE]: 'Upgrade',
  [CommissionType.CANCELED]: 'Canceled',
};
