import React from 'react';
import { Formik, Form } from 'formik';
import { useAppDispatch } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
// eslint-disable-next-line no-unused-vars
import { IOrderCancel } from '@EngagementsSowData/salesSow.model';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { reasonCancel } from 'pages/WirelineEngagements/shared/CancelOrderModalForm/constants';
import { cancelOrderAction } from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrder';
import CancelOrderModal from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrderModal';
import { CancelOrderFormSchema } from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrderFormSchema';
import { ICancelOrderRowData } from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrderModalForm.model';

interface ICancelOrderModalFormProps {
  title: string;
  isOpen: boolean;
  cleanActiveRow: () => void;
  activeRow: ICancelOrderRowData | null | undefined;
}

const CancelOrderModalForm: React.FC<ICancelOrderModalFormProps> = ({
  title,
  isOpen,
  activeRow,
  cleanActiveRow,
}) => {
  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();

  const cancelOrderRowId = activeRow?.id;

  const initialValues = CancelOrderFormSchema.getDefault();

  const onSubmit = async (params: IOrderCancel, { setErrors, resetForm }) => {
    if (!cancelOrderRowId) return;

    const manipulatedParams = {
      ...params,
      text: `${reasonCancel}: ${params.text}`,
    };

    try {
      await dispatch(cancelOrderAction({
        id: cancelOrderRowId,
        params: manipulatedParams,
      })).unwrap();

      cleanActiveRow();
      getTableData();
      resetForm();
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);
    }
  };

  if ((!cancelOrderRowId || !isOpen)) {
    return null;
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={CancelOrderFormSchema}
    >
      <Form>
        <CancelOrderModal
          title={title}
          activeRow={activeRow}
          cleanActiveRow={cleanActiveRow}
          reasonLabel={`${reasonCancel}.`}
        />
      </Form>
    </Formik>
  );
};

export default CancelOrderModalForm;
