import React from 'react';
import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import { ITableCellContract } from 'pages/ContractManagment/ContractManagement.models';
import { RequestTypeLabels, RequestTypeOptions } from 'pages/ContractManagment/constants';
import convertDataFromFormToTable from 'core/utilities/convertDataFromFormToTable';
import { dateFormat } from 'core/constants';

export const columns = [
  {
    header: 'Request type',
    accessorKey: 'requestType',
    Cell: ({ row }: ITableCellContract) => (
      <span>{RequestTypeLabels[row.original.requestType]}</span>
    ),
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(RequestTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select status' },
  },
  {
    header: 'Customer name',
    accessorKey: 'customerName',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter Customer Name' },
    sortDescFirst: false,
  },
  {
    header: 'Seller',
    accessorKey: 'sellerFullname',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter Seller' },
    sortDescFirst: false,
  },
  {
    header: 'Request created date',
    accessorKey: 'requestCreatedDate',
    minSize: 100,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue as string).format(dateFormat)
    ),
  },
  {
    header: 'Request accepted date',
    accessorKey: 'requestAcceptedDate',
    minSize: 100,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue as string).format(dateFormat)
    ),
  },
  {
    header: 'Contract Specialist',
    accessorKey: 'contractSpecialist',
    minWidth: '100px',
    sortDescFirst: false,
    enableColumnFilter: false,
  },
  {
    header: 'Canceled/ Declined Requested date',
    accessorKey: 'canceledDate',
    minSize: 100,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue as string).format(dateFormat)
    ),
  },
  {
    header: 'Completed date',
    accessorKey: 'completedDate',
    minSize: 100,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue as string).format(dateFormat)
    ),
  },
];
