import React, { memo } from 'react';
import { useAppSelector } from 'core/store';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { useRenewalSowPermissions } from '@EngagementsRenewalSowData/useRenewalSowPermission';
import OrdersActionsMenu from '@EngagementsRenewalSowData/RenewalSowOrdersActions/OrdersActionsMenu';
import EngagementAccepted from '@EngagementsRenewalSowData/RenewalSowOrdersActions/EngagementAccepted/EngagementAcceptedButton';

const RenewalSowOrdersActions = () => {
  const {
    isAvailableRequestActions,
    isAvailableEngagementAccept,
  } = useRenewalSowPermissions();

  const renewalSowData = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.results,
  );

  const selectedRenewalSowData = useTableSelectedRows<IRenewalSowDataItem>(
    { rows: renewalSowData },
  );

  return (
    <>
      {
        isAvailableRequestActions && (
          <OrdersActionsMenu
            selectedOrders={selectedRenewalSowData}
          />
        )
      }
      {
        isAvailableEngagementAccept && (
          <EngagementAccepted
            selectedOrders={selectedRenewalSowData}
          />
        )
      }
    </>
  );
};

export default memo(RenewalSowOrdersActions);
