import getContractManagementList, { getContractManagementByBundleList } from './api.service';
import {
  getContractManagementListByBundleFailure,
  getContractManagementListByBundleRequest,
  getContractManagementListByBundleSuccess,
  getContractManagementListFailure,
  getContractManagementListRequest,
  getContractManagementListSuccess,
} from './GetContractManagementList';

const getContractManagementListAction = (
  controller: AbortController,
  params: {
      page: number;
      page_size: number;
      search: string;
      ordering?: string | undefined;
    },
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getContractManagementListRequest());

    const { data } = await getContractManagementList(signal, params);

    dispatch(getContractManagementListSuccess(data));
  } catch (error) {
    dispatch(getContractManagementListFailure(error));
  }
};

export const getContractManagementListByBundleAction = (
  controller: AbortController,
  params: string,
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getContractManagementListByBundleRequest());

    const { data } = await getContractManagementByBundleList(signal, params);

    dispatch(getContractManagementListByBundleSuccess(data));
  } catch (error) {
    dispatch(getContractManagementListByBundleFailure(error));
  }
};

export default getContractManagementListAction;
