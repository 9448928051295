import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import ButtonMenu, { MenuItems } from 'shared/ButtonMenu';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IContract, RequestActionTypes } from 'pages/ContractManagment/ContractManagement.models';
import { useContractManagementPermissions } from 'pages/ContractManagment/useContractManagementPermission';
import { requestsActionsButtonTitle } from 'pages/ContractManagment/ContractManagementList/RequestsActions/constants';
import {
  isContractEngagementTypePending,
  isContractEngagementTypeInProgress,
  isContractEngagementTypeCanceled,
  isContractEngagementTypeDeclined,
} from 'pages/ContractManagment/utilities';

import './RequestsActionsMenuButton.scss';

const { ACCEPTED, COMPLETED, CONTRACT_SPECIALIST } = RequestActionTypes;

interface IRequestAcceptedMenuButton {
  isDisabled: boolean;
  selectedContracts: IContract[];
  onSelect: (type: string) => void;
}

const RequestsActionsMenuButton: React.FC<IRequestAcceptedMenuButton> = ({
  onSelect,
  isDisabled,
  selectedContracts,
}) => {
  const {
    isAvailableRequestAccepted,
    isAvailableRequestCompleted,
    isAvailableContractSpecialist,
  } = useContractManagementPermissions();

  const menuItems = useMemo(() => {
    const includesPending = selectedContracts.some(isContractEngagementTypePending);
    const includesInProgress = selectedContracts.some(isContractEngagementTypeInProgress);
    const includesCanceled = selectedContracts.some(isContractEngagementTypeCanceled);
    const includesDeclined = selectedContracts.some(isContractEngagementTypeDeclined);
    const isActionButtonsAvailable = !(
      includesPending && includesInProgress && includesDeclined && includesCanceled
    );

    const comboPendingCanceled = includesPending && includesCanceled;
    const comboPendingDeclined = includesPending && includesDeclined;
    const comboProgressCanceled = includesInProgress && includesCanceled;
    const comboProgressDeclined = includesInProgress && includesDeclined;

    const isAvailableRequestAcceptedCombo = isActionButtonsAvailable
      && (comboPendingCanceled || comboPendingDeclined);
    const isAvailableRequestCompletedAndContractSpecialist = isActionButtonsAvailable
      && (comboProgressCanceled || comboProgressDeclined);
    const allContractsPending = selectedContracts.every(isContractEngagementTypePending);
    const allContractsInProgress = selectedContracts.every(isContractEngagementTypeInProgress);

    const isDisabledRequestAccepted = !(
      allContractsPending || isAvailableRequestAcceptedCombo
    );
    const isDisabledRequestCompleted = !(
      allContractsInProgress || isAvailableRequestCompletedAndContractSpecialist
    );
    const isDisabledContractSpecialist = !(
      allContractsInProgress || isAvailableRequestCompletedAndContractSpecialist
    );

    return compact([
      (isAvailableRequestAccepted && {
        label: 'Request Accepted',
        onClick: () => onSelect(ACCEPTED),
        isDisabled: isDisabledRequestAccepted,
        icon: <CheckCircleOutlineIcon className="accepted_icon" />,
      }),
      (isAvailableRequestCompleted && {
        label: 'Request Completed',
        onClick: () => onSelect(COMPLETED),
        isDisabled: isDisabledRequestCompleted,
        icon: <CheckCircleOutlineIcon className="completed_icon" />,
      }),
      (isAvailableContractSpecialist && {
        label: 'Contract Specialist',
        onClick: () => onSelect(CONTRACT_SPECIALIST),
        isDisabled: isDisabledContractSpecialist,
        icon: <CheckCircleOutlineIcon className="completed_icon" />,
      }),
    ]);
  }, [selectedContracts]);

  return (
    <ButtonMenu
      buttonVariant="text"
      isDisabled={isDisabled}
      menuClassName="requests-actions-menu"
      buttonIcon={<CheckCircleOutlineIcon />}
      buttonContent={requestsActionsButtonTitle}
    >
      <MenuItems menuItems={menuItems} />
    </ButtonMenu>
  );
};

export default RequestsActionsMenuButton;
