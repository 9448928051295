import React, { useEffect, useState } from 'react';
import Loader from 'shared/Loader';
import { Box } from '@mui/material';
import { HTTPService } from 'core/services';
import { useSearchParams } from 'react-router-dom';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import getSalesSowAction from 'core/actions/Sales/GetSalesSow/actions';
import RequestOrderForm from '@EngagementsSowData/RequestOrderForm/RequestOrderForm';
import RequestOrderFormBackButton from '@EngagementsSowData/RequestOrderForm/RequestOrderFormBackButton';
import RequestOrderFormTabs from '@EngagementsSowData/RequestOrderForm/RequestOrderFormCreate/RequestOrderFormTabs';

const ordersInfoController = HTTPService.getController();

const RequestOrderFormCreate = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { showErrorNotifier } = useNotifiers();

  const orderIds = searchParams.get('orderIds')?.split(',') || [];

  const isLoadingSelectedOrders = useAppSelector(
    (state) => state.wirelineEngagements.salesSow.isLoading,
  );

  const [selectedOrderId, setSelectedOrderId] = useState(orderIds[0]);
  const [isEditMode, setIsEditMode] = useState(false);

  const getSalesSowOrders = async () => {
    const orderIdsParams = {
      page: 1,
      page_size: orderIds.length,
      orderId: orderIds.toString(),
    };

    try {
      await dispatch(getSalesSowAction(ordersInfoController, orderIdsParams));
    } catch (error) {
      showErrorNotifier(error);
    }
  };

  useEffect(() => {
    getSalesSowOrders();
  }, []);

  if (isLoadingSelectedOrders) {
    return <Loader />;
  }

  return (
    <Box className="request-order-form__wrapper">
      <RequestOrderFormBackButton isSendAllDisabled={isEditMode} />
      <RequestOrderFormTabs
        isDisabled={isEditMode}
        selectedOrderId={selectedOrderId}
        setSelectedOrderId={setSelectedOrderId}
      />
      <RequestOrderForm
        orderId={selectedOrderId}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        getSalesSowOrders={getSalesSowOrders}
      />
    </Box>
  );
};

export default RequestOrderFormCreate;
