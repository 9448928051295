/* eslint-disable no-param-reassign */
import { requestBundles } from 'core/api/sales.api/sales.api';
import { isAnyOf, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { IRequestBundlesParams } from 'core/actions/Sales/RequestBundles/RequestBundles.model';

const initialState = {
  isLoading: false,
};

export const requestBundlesAction = createAsyncThunk(
  'requestBundlesAction',
  async (params: IRequestBundlesParams, { rejectWithValue }) => {
    try {
      await requestBundles(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const requestBundlesSlice = createSlice({
  name: 'requestBundlesSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        requestBundlesAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(requestBundlesAction.rejected, requestBundlesAction.fulfilled),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const requestBundlerReducer = requestBundlesSlice.reducer;
