import { createSlice } from '@reduxjs/toolkit';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';

export interface IContractManagementListState {
  count: number
  isLoading: boolean
  errors: object | null
  results: IContract[]
  resultsByBundle: IContract[]
}

const initialState: IContractManagementListState = {
  isLoading: false,
  count: 0,
  results: [],
  resultsByBundle: [],
  errors: {},
};

const ContractManagementSlice = createSlice({
  name: 'getContractManagementData',
  initialState,
  reducers: {
    clearContractManagementList: () => initialState,
    clearContractManagementListByBundle: (state) => ({ ...state, resultsByBundle: [] }),
    getContractManagementListRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getContractManagementListSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    getContractManagementListFailure: (state, action) => ({
      ...state,
      results: [],
      isLoading: false,
      error: action.payload,
    }),
    getContractManagementListByBundleRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getContractManagementListByBundleSuccess: (state, action) => ({
      ...state,
      resultsByBundle: action.payload.results,
      isLoading: false,
    }),
    getContractManagementListByBundleFailure: (state, action) => ({
      ...state,
      resultsByBundle: [],
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const {
  getContractManagementListRequest,
  getContractManagementListSuccess,
  getContractManagementListFailure,
  getContractManagementListByBundleRequest,
  getContractManagementListByBundleSuccess,
  getContractManagementListByBundleFailure,
  clearContractManagementList,
  clearContractManagementListByBundle,
} = ContractManagementSlice.actions;

export const getContractManagementListReducer = ContractManagementSlice.reducer;
