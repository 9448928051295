import React, { useEffect } from 'react';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import AccardionOrderDataSidebar from 'pages/WirelineEngagements/shared/AccardionOrderDataSidebar';
import {
  getOrderMessage,
  contractManagementProductsSchema,
} from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/EditRowSidebarForm/constants';
import {
  getContractManagementItemAction,
  clearGetContractManagementItemData,
} from 'pages/ContractManagment/ContractManagementList/GetContractManagementItem/GetContractManagementItem';

const DataRowSidebar = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();

  const {
    activeRow,
    activeRowMode,
    secondarySidebarMode,
    cleanActiveRow,
    setSecondarySidebarMode,
  } = useTableActiveRowContext<IContract>();

  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);
  const contractItem = useAppSelector(
    (state) => state.contractManagement.item.result,
  );
  const isLoadingContractItem = useAppSelector(
    (state) => state.contractManagement.item.isLoading,
  );

  const isLoading = isLoadingContractItem || isLoadingSchemas;

  const { isDataMode } = getTableRowModeBoolean(activeRowMode);
  const { isDataMode: isDataInsideEditMode } = getTableRowModeBoolean(secondarySidebarMode);

  const isOpen = !!activeRow && (isDataMode || isDataInsideEditMode);
  const isFetchData = !!activeRow && isDataMode;

  const onCloseHandler = () => (secondarySidebarMode
    ? setSecondarySidebarMode(null)
    : cleanActiveRow());

  const getContractManagementItem = async (rowId: string | number) => {
    try {
      await dispatch(getContractManagementItemAction(rowId))
        .unwrap();
    } catch (error) {
      showErrorNotifier(getOrderMessage.error, error);
    }
  };

  useEffect(() => {
    if (!isFetchData) return;

    getContractManagementItem(activeRow.order);

    return () => {
      dispatch(clearGetContractManagementItemData());
    };
  }, [activeRow]);

  return (
    <AccardionOrderDataSidebar
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onCloseHandler}
      orderData={contractItem}
      schemaKey={contractManagementProductsSchema}
    />
  );
};

export default DataRowSidebar;
