import React from 'react';
import { useParams } from 'react-router-dom';
import { getRenewalSowPath } from 'core/routing/paths';
import ContractFormView from 'pages/WirelineEngagements/shared/ContractForm/ContractFormView';

const RenewalSowDataContractRequestView = () => {
  const { wirelineEngagementId, customerId } = useParams();

  const backRoutePath = getRenewalSowPath(wirelineEngagementId, customerId);

  return (
    <ContractFormView
      backRoutePath={backRoutePath}
    />
  );
};

export default RenewalSowDataContractRequestView;
