import React from 'react';
import CustomDialog from 'shared/CustomDialog';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import ContractSpecialistModalContent from './ContractSpecialistModalContent';

interface IRequestCompleteModalProps {
  isOpen: boolean;
  onCloseModal: ()=> void;
  selectedContracts: IContract[];
}

const RequestCompleteModal: React.FC<IRequestCompleteModalProps> = ({
  isOpen,
  onCloseModal,
  selectedContracts,
}) => (
  <CustomDialog
    isModalOpen={isOpen}
    onCloseModal={onCloseModal}
    renderChildren={(onClose) => (
      <ContractSpecialistModalContent
        onCloseModal={onClose}
        selectedContracts={selectedContracts}
      />
    )}
  />
);

export default RequestCompleteModal;
