import React, { useState } from 'react';
import noop from 'lodash/noop';
import { useFormikContext } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, Tooltip } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { requestBundlesAction } from 'core/actions/Sales/RequestBundles';
import { REQUEST_BUNDLES_TYPE } from 'core/actions/Sales/RequestBundles/constants';
import { sendRequestOrderNotifierMessage } from '@EngagementsSowData/RequestOrderForm/constants';
import ActionsModalRequestOrder from '@EngagementsSowData/RequestOrderForm/ActionsModalRequestOrder';

interface IRequestOrderFormButtonsProps {
  orderId: string
  isEditMode: boolean
  updateOrdersData?: (() => void) | undefined
  setIsEditMode: (isEditMode: boolean) => void
}

const RequestOrderFormButtons: React.FC<
  IRequestOrderFormButtonsProps
> = ({
  orderId,
  isEditMode,
  setIsEditMode,
  updateOrdersData,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { submitForm, resetForm } = useFormikContext();

  const ordersToSend = useAppSelector((state) => state.wirelineEngagements.salesSow.results);
  const isRequestOrdersActionLoading = useAppSelector(
    (state) => state.wirelineEngagements.requestBundler.isLoading,
  );
  const orderToSend = ordersToSend.find(({ id }) => id === Number(orderId));
  const modalRequestOrdersToSend = orderToSend ? [orderToSend] : [];

  const isOrderCreated = !!orderToSend?.requestOrderActionDatetime;
  const isSendButtonDisabled = isOrderCreated || isEditMode;

  const onCancelEdit = () => {
    resetForm();
    setIsEditMode(false);
  };

  const onRequestOrder = async () => {
    const requestParams = {
      orderIds: [Number(orderId)],
      requestType: REQUEST_BUNDLES_TYPE.ORDER,
    };

    try {
      await dispatch(requestBundlesAction(requestParams)).unwrap();

      showSuccessNotifier(sendRequestOrderNotifierMessage.success);
      setIsEditMode(false);
      updateOrdersData?.();
    } catch (error) {
      showErrorNotifier(sendRequestOrderNotifierMessage.error, error);
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  return (
    <Grid
      container
      direction="row"
      pb={2}
      pl={3}
    >
      <ActionsModalRequestOrder
        onConfirm={onRequestOrder}
        isModalOpen={isConfirmModalOpen}
        orders={modalRequestOrdersToSend}
        setIsModalOpen={setIsConfirmModalOpen}
        isLoading={isRequestOrdersActionLoading}
      />
      {
        isEditMode && (
          <span>
            <Button
              className="request-order-form__button"
              onClick={submitForm}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={isOrderCreated}
            >
              Save
            </Button>
            <Button
              className="request-order-form__button"
              onClick={onCancelEdit}
              variant="outlined"
              disabled={isOrderCreated}
            >
              Cancel
            </Button>
          </span>
        )
      }
      {
        !isEditMode && (
          <Button
            className="request-order-form__button"
            onClick={() => setIsEditMode(true)}
            variant="outlined"
            startIcon={<ModeEditIcon />}
            disabled={isOrderCreated}
          >
            Edit
          </Button>
        )
      }
      {isSendButtonDisabled || isRequestOrdersActionLoading ? (
        <Tooltip
          title="The order has been requested"
          arrow
          placement="top"
        >
          <Button
            onClick={noop}
            variant="contained"
            disabled
            sx={{ '&.Mui-disabled': { pointerEvents: 'all' } }}
          >
            send
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={() => setIsConfirmModalOpen(true)}
          variant="contained"
        >
          send
        </Button>
      ) }
    </Grid>
  );
};

RequestOrderFormButtons.defaultProps = {
  updateOrdersData: noop,
};

export default RequestOrderFormButtons;
