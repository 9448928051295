import React from 'react';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import { useContractManagementPermissions } from 'pages/ContractManagment/useContractManagementPermission';
import CommentsAccardion from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/LogAndFilesSidebar/CommentsAccardion';
import AttachmentsAccardion from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/LogAndFilesSidebar/AttachmentsAccardion';

interface ILogAndFilesSidebarContentParams {
  activeRow: IContract | null
}

const LogAndFilesSidebarContent: React.FC<ILogAndFilesSidebarContentParams> = ({
  activeRow,
}) => {
  const {
    isAvailableGetComments,
    isAvailableGetAttachments,
  } = useContractManagementPermissions();

  const { order: orderId, engagementId } = activeRow || {};

  const isAvailableComments = orderId && isAvailableGetComments;
  const isAvailableAttachments = engagementId && isAvailableGetAttachments;

  return (
    <>
      {isAvailableComments && <CommentsAccardion orderId={orderId} /> }
      {isAvailableAttachments && <AttachmentsAccardion engagementId={engagementId} />}
    </>
  );
};

export default LogAndFilesSidebarContent;
