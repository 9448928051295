/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { patchSalesSowById } from 'core/api/sales.api';
import { IOrderData } from 'core/models/order.models';

export interface IState {
  isLoading: boolean
  error: object | null
  result: IOrderData
}

const initialState: IState = {
  result: {},
  error: null,
  isLoading: false,
};

export const patchSalesSowItemAction = createAsyncThunk(
  'patchSalesSowItemAction',
  async ({ id, params }: { id: string | number, params: object }, { rejectWithValue }) => {
    try {
      const { data } = await patchSalesSowById(id, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const editSalesSowItemSlice = createSlice({
  name: 'editSalesSowItem',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(
        patchSalesSowItemAction.pending,
        (state) => {
          state.result = {};
          state.isLoading = true;
        })
      .addCase(
        patchSalesSowItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        patchSalesSowItemAction.rejected,
        (state, action) => {
          state.result = {};
          state.isLoading = false;
          state.error = action.error;
        });
  },
});

export const { clear: clearEditSalsesSowItemData } = editSalesSowItemSlice.actions;

export const editSalesSowItemReducer = editSalesSowItemSlice.reducer;
