import React from 'react';
import CustomModal from 'shared/CustomModal';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import OrderItem from '@EngagementsSowData/RequestOrderForm/ActionsModalRequestOrder/OrderItem';
import { orderActionsTitle } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

interface IActionsModalRequestOrderProps {
  isLoading: boolean
  isModalOpen: boolean;
  onConfirm: () => void;
  orders: ISowDataListItem[];
  setIsModalOpen: (isModalOpen: boolean) => void;
}

const ActionsModalRequestOrder: React.FC<
  IActionsModalRequestOrderProps
> = ({
  orders,
  isLoading,
  isModalOpen,
  onConfirm,
  setIsModalOpen,
}) => (
  <CustomModal
    areButtonsVisible
    isLoading={isLoading}
    cancelButtonText="No"
    confirmButtonText="Yes"
    isModalOpen={isModalOpen}
    onClickConfirm={onConfirm}
    onCloseModal={setIsModalOpen}
    title={orderActionsTitle[OrderActions.REQUEST_ORDERS]}
  >
    <>
      {orders.map((order) => (
        <OrderItem
          order={order}
          key={order.id}
        />
      ))}
    </>
  </CustomModal>
);

export default ActionsModalRequestOrder;
