import { netsparkAxios } from 'core/services/HTTPService';
import getContractManagementListUrl from './apiUrls';

const getContractManagementList = (signal, params) => netsparkAxios
  .get(getContractManagementListUrl, { signal, params });

export const getContractManagementByBundleList = (signal, params: string) => netsparkAxios
  .get(`${getContractManagementListUrl}${params}`, { signal });

export default getContractManagementList;
