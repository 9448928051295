/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { patchSalesActiveNumbersById } from 'core/api/sales.api';
import { IOrderData } from 'core/models/order.models';

export interface IState {
  isLoading: boolean;
  error: object | null
  result: IOrderData;
}

const initialState: IState = {
  result: {},
  error: null,
  isLoading: false,
};

export const patchSalesActiveNumbersItemAction = createAsyncThunk(
  'patchSalesActiveNumbersItemAction',
  async ({ id, params }: { id: string | number, params: object }, { rejectWithValue }) => {
    try {
      const { data } = await patchSalesActiveNumbersById(id, params);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const editSalesActiveNumbersItemSlice = createSlice({
  name: 'editSalesActiveNumbersItem',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(
        patchSalesActiveNumbersItemAction.pending,
        (state) => {
          state.result = {};
          state.isLoading = true;
        })
      .addCase(
        patchSalesActiveNumbersItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        patchSalesActiveNumbersItemAction.rejected,
        (state, action) => {
          state.result = {};
          state.isLoading = false;
          state.error = action.error;
        });
  },
});

export const { clear: clearEditActiveNumbersItemData } = editSalesActiveNumbersItemSlice.actions;

export const editSalesActiveNumbersItemReducer = editSalesActiveNumbersItemSlice.reducer;
