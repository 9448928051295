import React, { memo, useState } from 'react';
import Loader from 'shared/Loader';
import { Box, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ACCARDION_LOADER_SIZE,
  ACCARDION_LOADER_SIZE_EXPANDED,
} from 'shared/CustomAccardion/constants';

interface ICustomAccardionProps {
  title: string;
  className?: string;
  isLoading?: boolean;
  content: React.ReactNode;
  defaultExpanded?: boolean;
  showToggleButton?: boolean;
  controlledExpanded?: boolean;
  buttonBlock?: React.ReactNode;
  headerButton?: React.ReactNode;
}

const CustomAccardion: React.FC<ICustomAccardionProps> = ({
  title,
  content,
  isLoading,
  className,
  buttonBlock,
  headerButton,
  defaultExpanded,
  showToggleButton,
  controlledExpanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(!!defaultExpanded);

  const sizeLoading = isExpanded ? ACCARDION_LOADER_SIZE_EXPANDED : ACCARDION_LOADER_SIZE;

  const toggleIsExpanded = () => {
    setIsExpanded((isCurrentExpanded) => !isCurrentExpanded);
  };

  return (
    <Box className={`custom-accardion ${className}`}>
      <div className="custom-accardion__title-wrap">
        <p className="custom-accardion__title title">
          {title}
        </p>
        <div>
          {headerButton}
          {showToggleButton
            && (
              <IconButton
                onClick={toggleIsExpanded}
                aria-selected={isExpanded}
                className="custom-accardion__toggle"
              >
                <ExpandMoreIcon fontSize="large" />
              </IconButton>
            )}
        </div>
      </div>
      {isLoading && <Loader size={sizeLoading} />}
      <Collapse in={controlledExpanded || isExpanded}>
        <div className="custom-accardion__content">
          {content}
          <div className="custom-accardion__content-btn">
            {buttonBlock}
          </div>
        </div>
      </Collapse>
    </Box>
  );
};

CustomAccardion.defaultProps = {
  className: '',
  isLoading: false,
  buttonBlock: null,
  headerButton: null,
  defaultExpanded: false,
  showToggleButton: true,
  controlledExpanded: false,
};

export default memo(CustomAccardion);
