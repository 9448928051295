import { FileCategory } from 'pages/InsideSales/model';
import { insideSalesCategoryNames } from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm/InsideSalesEditAttachments/constants';

const { PROPOSAL, ATTACHMENT } = FileCategory;

interface IViewModeConfigItem {
    isVisible: boolean
    isForbiddenCreate: boolean
}

type ViewModeConfig = {
    [key in FileCategory]: IViewModeConfigItem
};

export const getInsideSalesAttachmentsConfig = (viewModeConfig: ViewModeConfig) => (
  [
    ...(viewModeConfig[ATTACHMENT].isVisible ? [{
      category: ATTACHMENT,
      tabName: insideSalesCategoryNames.attachments,
      isViewMode: viewModeConfig[ATTACHMENT].isForbiddenCreate,
    }] : []),
    ...(viewModeConfig[PROPOSAL].isVisible ? [{
      category: PROPOSAL,
      tabName: insideSalesCategoryNames.Proposals,
      isViewMode: viewModeConfig[PROPOSAL].isForbiddenCreate,
    }] : []),
  ]
);
