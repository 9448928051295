import { userTeamRolesTeamDepartmentsLabels } from 'pages/UserManagement/constants';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

const {
  SELLER, PROJECT_MANAGER, BILLING_SPECIALIST,
} = UserTeamRoleTeamDepartment;

export const reassignSellerModalSubtitle = 'You are about to update the responsible seller for this Customer/Engagement. The current users listed below will lose their access at the end of the transition period. Please ensure the correct seller and access end date are selected.';
export const alignedBackOfficeUsers = 'The following team will be assigned based on the new seller selection';
export const sellerFieldDescription = 'Please select a new seller for this Customer';
export const dateFieldDescription = 'Please select the last day current users will have access';

export const updateCustomerNotifications = {
  success: 'Seller updated successfully',
  error: 'Failed to update seller',
};

export const sellersParams = {
  excludeTechnicalOverlays: true,
  department: UserTeamRoleTeamDepartment.SELLER,
};

export const alignedBackOfficeUsersList = [
  {
    label: userTeamRolesTeamDepartmentsLabels[PROJECT_MANAGER],
    key: PROJECT_MANAGER,
  },
  {
    label: userTeamRolesTeamDepartmentsLabels[BILLING_SPECIALIST],
    key: BILLING_SPECIALIST,
  },
];

export const currentTeamUsersList = [
  {
    label: userTeamRolesTeamDepartmentsLabels[SELLER],
    key: SELLER,
  },
  ...alignedBackOfficeUsersList,
];
