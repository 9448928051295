import React, { memo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import ButtonMenu from 'shared/ButtonMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import RequestQuoteModal from '@EngagementsRenewalSowData/RenewalSowOrdersActions/OrdersActionsMenu/RequestQuoteModal';
import RequestActionsItems from '@EngagementsRenewalSowData/RenewalSowOrdersActions/OrdersActionsMenu/RequestActionsItems';
import { checkIfOrdersRequestTypeIsNonCotractSupport } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';

import './OrdersActionsMenu.scss';

interface IOrdersActionsMenuProps {
  selectedOrders: IRenewalSowDataItem[]
}

const OrdersActionsMenu: React.FC<IOrdersActionsMenuProps> = ({
  selectedOrders,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasRowRequestTypeIsNonContractOrder = checkIfOrdersRequestTypeIsNonCotractSupport(
    selectedOrders,
  );

  const isDisabled = isEmpty(selectedOrders) || hasRowRequestTypeIsNonContractOrder;

  return (
    <>
      <ButtonMenu
        buttonVariant="text"
        tooltipTitle="Actions"
        isDisabled={isDisabled}
        buttonContent={<MoreHorizIcon />}
        menuClassName="renewal-menu-actions"
      >
        <RequestActionsItems
          selectedOrders={selectedOrders}
          setIsModalOpen={setIsModalOpen}
        />
      </ButtonMenu>

      <RequestQuoteModal
        isModalOpen={isModalOpen}
        selectedOrders={selectedOrders}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default memo(OrdersActionsMenu);
