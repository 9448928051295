import React from 'react';
import { TableRowMode } from 'shared/Table/Table.models';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import CommentsRowSidebar from 'pages/WirelineEngagements/shared/CommentsRowSidebar';
import { COMMENTS_ORDER_CATEGORY } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/constants';
import { useUserPermission } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/useUserPermission';

const {
  GENERAL,
  BILLING,
} = COMMENTS_ORDER_CATEGORY;

const CommentsSidebar = () => {
  const {
    activeRowId,
    activeRowMode,
    secondarySidebarMode,
    cleanActiveRow,
    setSecondarySidebarMode,
  } = useTableActiveRowContext<ISowDataListItem>();

  const { isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const isCommentsInsideEditOpen = secondarySidebarMode === TableRowMode.COMMENT;

  const isActiveRow = isCommentMode || isCommentsInsideEditOpen;
  const commentsActiveRowId = (isActiveRow ? activeRowId : null) as string | number | null;

  // TODO: Remove when me will be ready for sow page
  const { isAvailableForGeneralCategory, isAvailableForBillingCategory } = useUserPermission();

  // TODO: Change when me will be ready for sow page
  const availableCreateCategories = {
    [GENERAL]: isAvailableForGeneralCategory,
    [BILLING]: isAvailableForBillingCategory,
  };
  // TODO: Change when me will be ready for sow page
  const availableVissibleCategories = {
    [GENERAL]: true,
    [BILLING]: true,
  };

  return (
    <CommentsRowSidebar
      title="Order Comments"
      activeRowId={commentsActiveRowId}
      availableCreateCategories={availableCreateCategories}
      availableVissibleCategories={availableVissibleCategories}
      isCommentsInsideEditOpen={isCommentsInsideEditOpen}
      cleanActiveRow={cleanActiveRow}
      setSecondarySidebarMode={setSecondarySidebarMode}
    />
  );
};

export default CommentsSidebar;
