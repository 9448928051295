import { useIsUserType } from 'core/hooks/useIsUserType';
import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserType, UserTeamRoleName, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

const { DEPARTMENT_HEAD } = UserTeamRoleName;
const {
  CONTRACT_SPECIALIST, BILLING_SPECIALIST, PROJECT_MANAGER, RETENTION_MANAGER,
} = UserTeamRoleTeamDepartment;

export const useUserPermissions = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);

  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);

  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);

  const isProjectManagerDepartmentHead = isProjectManager && isDepartmentHead;
  const isProjectManagerNotDepartmentHead = isProjectManager && !isDepartmentHead;

  const isVisibleOrderDataSidebar = isAdmin || isProjectManager || isBillingSpecialist;
  const isEditingAvailable = isProjectManager || isAdmin || isBillingSpecialist;
  const isCancelOrderAvailable = isProjectManager || isAdmin;
  const isToolbarUnavailable = isContractSpecialist || isRetentionManager;
  const isExportAvailable = isAdmin || isProjectManager || isBillingSpecialist;
  const isEngagementAcceptedDropdownAvalable = isAdmin || isProjectManagerDepartmentHead;
  const isContractManagementRequestAcceptedAvailable = isAdmin
    || isProjectManagerDepartmentHead
    || isContractSpecialist
    || isDepartmentHead;

  return {
    isAdmin,
    isProjectManager,
    isExportAvailable,
    isEditingAvailable,
    isBillingSpecialist,
    isToolbarUnavailable,
    isCancelOrderAvailable,
    isVisibleOrderDataSidebar,
    isProjectManagerDepartmentHead,
    isProjectManagerNotDepartmentHead,
    isEngagementAcceptedDropdownAvalable,
    isContractManagementRequestAcceptedAvailable,
  };
};
