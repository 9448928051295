import React, { useState } from 'react';
import {
  Grid, Typography, IconButton, Button,
} from '@mui/material';
import { getSowDataPath } from 'core/routing/paths';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'core/store';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { requestBundlesAction } from 'core/actions/Sales/RequestBundles';
import { REQUEST_BUNDLES_TYPE } from 'core/actions/Sales/RequestBundles/constants';
import { sendALLRequestOrderNotifierMessage } from '@EngagementsSowData/RequestOrderForm/constants';
import ActionsModalRequestOrder from '@EngagementsSowData/RequestOrderForm/ActionsModalRequestOrder';

interface RequestOrderFormBackButtonProps {
  isViewMode?: boolean;
  isSendAllDisabled?: boolean;
}

const RequestOrderFormBackButton: React.FC<
  RequestOrderFormBackButtonProps
> = ({
  isViewMode, isSendAllDisabled,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { wirelineEngagementId, customerId } = useParams();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const ordersToSend = useAppSelector((state) => state.wirelineEngagements.salesSow.results);
  const isLoadingSendAll = useAppSelector(
    (state) => state.wirelineEngagements.requestBundler.isLoading,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onGoBack = () => {
    navigate(getSowDataPath(wirelineEngagementId, customerId));
  };

  const onSendAll = async () => {
    const orderIds = ordersToSend.reduce<number[]>(
      (value, current) => [...value, ...(!current.requestOrderActionDatetime ? [current.id] : [])],
      [],
    );

    const requestParams = {
      orderIds,
      requestType: REQUEST_BUNDLES_TYPE.ORDER,
    };

    try {
      await dispatch(requestBundlesAction(requestParams))
        .unwrap();

      showSuccessNotifier(sendALLRequestOrderNotifierMessage.success);
      navigate(getSowDataPath(wirelineEngagementId, customerId));
    } catch (error) {
      showErrorNotifier(sendALLRequestOrderNotifierMessage.error, error);
    }
  };

  return (
    <>
      <Grid
        container
        pb={2}
      >
        <Grid
          item
          container
          xs={10}
          justifyContent="flex-start"
        >
          <IconButton
            className="request-order-form__back-button"
            color="primary"
            size="medium"
            onClick={onGoBack}
            data-testid="request-order-form-back-button"
          >
            <ArrowBackIosIcon
              color="action"
            />
          </IconButton>
          <Typography
            className="request-order-form__section-title"
            variant="h6"
            component="h2"
          >
            Request order
          </Typography>
        </Grid>
        {
          !isViewMode && (
            <Grid
              item
              container
              xs={2}
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                onClick={() => setIsModalOpen(true)}
                disabled={isSendAllDisabled as boolean}
              >
                send all
              </Button>
            </Grid>
          )
        }
      </Grid>

      <ActionsModalRequestOrder
        orders={ordersToSend}
        onConfirm={onSendAll}
        isModalOpen={isModalOpen}
        isLoading={isLoadingSendAll}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

RequestOrderFormBackButton.defaultProps = {
  isViewMode: false,
  isSendAllDisabled: false,
};

export default RequestOrderFormBackButton;
