import React from 'react';
import { Typography } from '@mui/material';
import MenuItems from 'shared/ButtonMenu/MenuItems';
import { IMenuItem } from 'shared/ButtonMenu/ButtonMenu.model';

interface IGroupMenuItemsProps {
  groupLabel: string
  listItems: IMenuItem[]
}

const GroupMenuItems: React.FC<IGroupMenuItemsProps> = ({
  listItems,
  groupLabel,
}) => (
  <>
    <Typography
      className="button-menu-actions__title"
      variant="h6"
      component="h2"
    >
      {groupLabel}
    </Typography>
    <MenuItems menuItems={listItems} />
  </>
);

export default GroupMenuItems;
