import React, { useState } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from 'shared/ConfirmDialog';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton, Typography, Tooltip } from '@mui/material';
import { IContractDetailsOrders } from 'pages/WirelineEngagements/shared/ContractForm/contractForm.model';

interface IContractDetailsProps {
  isViewMode: Boolean,
  backRoutePath: string,
  numberOfOrders: number,
  orders: IContractDetailsOrders[],
}

const ContractDetails: React.FC<IContractDetailsProps> = ({
  orders, numberOfOrders, isViewMode, backRoutePath,
}) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onGoBack = () => {
    navigate(backRoutePath);
  };

  const onClickGoBack = () => {
    if (isViewMode) {
      onGoBack();
    } else {
      setIsModalVisible(true);
    }
  };

  return (
    <div className="contract-form__details">
      <div className="contract-form__details-back_wrap">
        <div className="contract-form__details-back-button_wrap">
          <IconButton
            className="contract-form__details-back-button"
            color="primary"
            size="medium"
            onClick={onClickGoBack}
          >
            <ArrowBackIosIcon
              color="action"
            />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
          >
            Contract Details
          </Typography>
        </div>
        <div className="contract-form__details-order">
          {numberOfOrders}
          {' '}
          {numberOfOrders > 1 ? 'orders' : 'order'}
        </div>
      </div>
      <div className="contract-form__details-location">
        <Typography
          className="contract-form__details-location-name"
          variant="body1"
          component="p"
        >
          Location
        </Typography>
        {orders.map((order) => (
          <div key={String(order.location)} className="contract-form__details-location-item_wrap">
            <Tooltip title={order.location} placement="top">
              <Typography
                className="contract-form__details-location-item"
                variant="body1"
                component="p"
              >
                <Dotdotdot clamp={1}>
                  {order.location}
                </Dotdotdot>
              </Typography>
            </Tooltip>
          </div>
        ))}
      </div>
      <div className="contract-form__details-product">
        <Typography
          className="contract-form__details-product-name"
          variant="body1"
          component="p"
        >
          Product
        </Typography>
        {orders.map((order) => (
          <div key={String(order.location)} className="contract-form__details-product-item_wrap">
            <Typography
              className="contract-form__details-product-item"
              variant="body1"
              component="p"
            >
              {order.product[0].name}
            </Typography>
            {order.product.length > 1 ? (
              <Tooltip title={order.product.map((product) => product.name).join(', ')} placement="top">
                <Typography
                  variant="body1"
                  component="p"
                >
                  and
                  {'  '}
                  {order.product.length - 1}
                  {'  '}
                  more
                </Typography>
              </Tooltip>
            ) : null}
          </div>
        ))}
      </div>
      <ConfirmDialog
        confirmLabel="Leave"
        onConfirm={onGoBack}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default ContractDetails;
