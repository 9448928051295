import React, { memo } from 'react';
import { Grid } from '@mui/material';
import { IOrderDataSidebarItem } from 'core/models/order.models';

interface IOrderDataItemProps {
  data: IOrderDataSidebarItem
}

const OrderDataItem: React.FC<IOrderDataItemProps> = ({ data }) => {
  const { label, value } = data;

  return (
    <Grid container className="order-data-list__item">
      <Grid item xs={12} md={4} className="order-data-list__item__label">{label}</Grid>
      <Grid item xs={12} md={8} className="order-data-list__item__value">{value}</Grid>
    </Grid>
  );
};

export default memo(OrderDataItem);
