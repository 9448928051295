import React, { useMemo, memo } from 'react';
import { useAppSelector } from 'core/store';
import { IParsedOrderData } from 'core/models/order.models';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import OrderDataList from 'pages/WirelineEngagements/shared/OrderDataSidebar/OrderDataList';
import { getLabelesFromShema, getOrderDataFromMeta } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';

interface IOrderDataContentProps {
  schemaName: string
  orderData: IParsedOrderData
}

const OrderDataContent: React.FC<IOrderDataContentProps> = ({
  orderData,
  schemaName,
}) => {
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);

  const shemaData = useMemo(() => {
    const parsedSchema = parseOpenapiSchema(openApiSchemas, schemaName);

    return getLabelesFromShema(parsedSchema);
  }, [openApiSchemas]);

  const renderData = useMemo(() => getOrderDataFromMeta(shemaData, orderData),
    [shemaData, orderData]);

  return (
    <OrderDataList data={renderData} />
  );
};

export default memo(OrderDataContent);
