import React from 'react';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import EscalationSupportModalForm from 'pages/WirelineEngagements/shared/EscalationSupportModalForm';
import { escalationTypeOptions, escalationTypeCSOptions } from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/constants';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';

const EscalationSupportModal = () => {
  const {
    activeRow,
    activeRowId,
    activeRowMode,
    cleanActiveRow,
  } = useTableActiveRowContext<IActiveNumbersItem>();

  const { isEscalationSupportMode } = getTableRowModeBoolean(activeRowMode);
  const escalationSupportRow = isEscalationSupportMode ? activeRow : undefined;
  const escalationSupportRowId = isEscalationSupportMode ? activeRowId : undefined;

  const { CONTRACT_SPECIALIST } = UserTeamRoleTeamDepartment;
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const escalationTypesOptions = isContractSpecialist
    ? escalationTypeCSOptions
    : escalationTypeOptions;

  return (
    <EscalationSupportModalForm
      cleanEscalationSupportRow={cleanActiveRow}
      escalationSupportRow={escalationSupportRow}
      escalationTypeOptions={escalationTypesOptions}
      escalationSupportRowId={escalationSupportRowId}
    />
  );
};

export default EscalationSupportModal;
