import { COMMENTS_ORDER_CATEGORY } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/constants';
// eslint-disable-next-line no-unused-vars
import { ICommentsActionsPermissions } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/CommentsRowSidebar.model';

const { GENERAL, BILLING } = COMMENTS_ORDER_CATEGORY;

export const getCommentsTabsConfigs = (availableCategories: ICommentsActionsPermissions) => [
  ...(availableCategories[GENERAL] ? [{
    name: 'General comments',
    category: GENERAL,
  }] : []),
  ...(availableCategories[BILLING] ? [{
    name: 'Billing',
    category: BILLING,
  }] : []),
];
