import React, { useState } from 'react';
import { useAppDispatch } from 'core/store';
import CustomModal from 'shared/CustomModal';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import provideQuoteAction from '@EngagementsSowData/ProvideQuote';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import provideContractAction from '@EngagementsSowData/ProvideContract';
import { requestBundlesAction } from 'core/actions/Sales/RequestBundles';
import ProvideOrderAction from '@EngagementsSowData/ProvideOrder/ProvideOrder';
import { REQUEST_BUNDLES_TYPE } from 'core/actions/Sales/RequestBundles/constants';
import OrderItem from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal/OrderItem';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import { orderActionsTitle, requestQuoteNotifier } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import {
  provideQuoteNotifier,
  provideOrderNotifier,
  provideContractNotifier,
} from '@EngagementsSowData/constants';

interface IActionsModalProps {
  isModalOpen: boolean;
  orderAction: OrderActions;
  selectedOrders: ISowDataListItem[];
  setIsActionsModalOpen: (isModalOpen: boolean) => void;
}

const ActionsModal: React.FC<IActionsModalProps> = ({
  isModalOpen,
  setIsActionsModalOpen,
  orderAction,
  selectedOrders,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData, setRowSelection } = useTableContext();
  const [isLoading, setIsLoading] = useState(false);

  const onRequestQuotes = async () => {
    const orderIds = selectedOrders.reduce<number[]>(
      (value, current) => [...value, ...(!current.requestQuoteDatetime ? [current.id] : [])],
      [],
    );

    const requestParams = { orderIds, requestType: REQUEST_BUNDLES_TYPE.QUOTE };

    try {
      await dispatch(requestBundlesAction(requestParams))
        .unwrap();

      showSuccessNotifier(requestQuoteNotifier.success);
    } catch (error) {
      showErrorNotifier(requestQuoteNotifier.error, error);
    }
  };

  const onProvideQuotes = async () => {
    const ordersWithoutQuoteProvide = selectedOrders.filter(
      (order) => !order.provideQuoteDatetime && order.quoteId);

    await Promise.all(ordersWithoutQuoteProvide.map(
      ({ quoteId }) => dispatch(provideQuoteAction(quoteId as string | number))
        .unwrap()
        .then(() => {
          showSuccessNotifier(provideQuoteNotifier.success);
        })
        .catch((e) => {
          showErrorNotifier(provideQuoteNotifier.error, e);
        })),
    );
  };

  const onProvideContracts = async () => {
    const ordersWithoutContractProvide = selectedOrders.filter(
      (order) => !order.provideContractDatetime && order.requestedContractId);

    await Promise.all(ordersWithoutContractProvide.map(
      ({ requestedContractId }) => dispatch(
        provideContractAction(requestedContractId as string | number))
        .unwrap()
        .then(() => {
          showSuccessNotifier(provideContractNotifier.success);
        })
        .catch((e) => {
          showErrorNotifier(provideContractNotifier.error, e);
        })),
    );
  };

  const onProvideOrders = async () => {
    const ordersWithRequestOrder = selectedOrders.filter(
      (order) => !order.provideOrderActionDatetime && order.orderActionId);

    await Promise.all(ordersWithRequestOrder.map(
      ({ orderActionId }) => dispatch(
        ProvideOrderAction(orderActionId as number))
        .then(() => {
          showSuccessNotifier(provideOrderNotifier.success);
        })
        .catch((e) => {
          showErrorNotifier(provideOrderNotifier.error, e);
        })),
    );
  };

  const orderActionsListActions = {
    [OrderActions.REQUEST_QUOTES]: onRequestQuotes,
    [OrderActions.PROVIDE_QUOTES]: onProvideQuotes,
    [OrderActions.PROVIDE_CONTRACTS]: onProvideContracts,
    [OrderActions.PROVIDE_ORDERS]: onProvideOrders,
  };

  const onClose = () => {
    setIsActionsModalOpen(false);
  };

  const onConfirm = async () => {
    setIsLoading(true);
    await orderActionsListActions[orderAction]?.();

    setRowSelection({});
    getTableData();
    onClose();
    setIsLoading(false);
  };

  const orderList = (
    <div>
      {selectedOrders.map((order) => (
        <OrderItem key={order.id} order={order} orderAction={orderAction} />
      ))}
    </div>
  );

  return (
    <CustomModal
      areButtonsVisible
      isLoading={isLoading}
      cancelButtonText="No"
      onCloseModal={onClose}
      confirmButtonText="Yes"
      isModalOpen={isModalOpen}
      onClickConfirm={onConfirm}
      title={orderActionsTitle[orderAction]}
    >
      {orderList}
    </CustomModal>
  );
};

export default ActionsModal;
