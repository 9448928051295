import { Button } from '@mui/material';
import React, { memo } from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import DatadIcon from '@mui/icons-material/Description';
import { TableRowMode } from 'shared/Table/Table.models';
import './HeaderActions.scss';

interface IHeaderActionsProps {
  isVisibleCommentsButton: boolean
  isVisibleOrderDataButton: boolean
  secondarySidebarMode: TableRowMode | null
  setSecondarySidebarMode: (mode: TableRowMode | null) => void
}

const HeaderActions: React.FC<IHeaderActionsProps> = ({
  secondarySidebarMode,
  setSecondarySidebarMode,
  isVisibleCommentsButton,
  isVisibleOrderDataButton,
}) => {
  if (!isVisibleCommentsButton && !isVisibleOrderDataButton) return null;

  const disabledData = secondarySidebarMode === TableRowMode.COMMENT;
  const disabledComment = secondarySidebarMode === TableRowMode.DATA;

  const setSecondarySidebarInDataMode = () => setSecondarySidebarMode(TableRowMode.DATA);
  const setSecondarySidebarInCommentMode = () => setSecondarySidebarMode(TableRowMode.COMMENT);

  return (
    <div className="header-actions">
      {isVisibleCommentsButton
      && (
        <Button
          variant="outlined"
          disabled={disabledComment}
          startIcon={<CommentIcon />}
          className="header-actions__button"
          onClick={setSecondarySidebarInCommentMode}
        >
          Comments
        </Button>
      )}
      {isVisibleOrderDataButton
        && (
          <Button
            variant="outlined"
            disabled={disabledData}
            startIcon={<DatadIcon />}
            className="header-actions__button"
            onClick={setSecondarySidebarInDataMode}
          >
            Data
          </Button>
        )}
    </div>
  );
};

export default memo(HeaderActions);
