import React, { useMemo, useEffect } from 'react';
import { Formik, Form } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { IServerValidationError } from 'core/services/HTTPService.models';
import EditRowSidebar from 'pages/WirelineEngagements/shared/EditRowSidebar';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import { getParsedRowData } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { useContractManagementPermissions } from 'pages/ContractManagment/useContractManagementPermission';
import { editRowContractManagementAction } from 'core/actions/ContractManagement/EditRowContractManagement';
import { additianalOrderFields } from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields/constants';
import HeaderActions from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/EditRowSidebarForm/HeaderActions';
import {
  addAdditionalFieldsToOrder,
  omitAdditionalFieldsFromOrder,
} from 'pages/WirelineEngagements/shared/EditRowSidebar/utilities';
import {
  getContractManagementItemAction,
  clearGetContractManagementItemData,
} from 'pages/ContractManagment/ContractManagementList/GetContractManagementItem/GetContractManagementItem';
import {
  includedFields,
  viewOrderTitle,
  editOrderTitle,
  getOrderMessage,
  patchOrderMessage,
  contractManagementProductsSchema,
} from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/EditRowSidebarForm/constants';

const EditRowSidebarForm = () => {
  const {
    activeRowMode, activeRowId, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode, activeRow,
  } = useTableActiveRowContext<IContract>();
  const dispatch = useAppDispatch();
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();

  const { getTableData } = useTableContext();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const contractItem = useAppSelector(
    (state) => state.contractManagement.item.result,
  );
  const isLoadingContractItem = useAppSelector(
    (state) => state.contractManagement.item.isLoading,
  );

  const {
    isAvailableGetOrderData,
    isAvailableGetLogAndFiles,
  } = useContractManagementPermissions();

  const { editableContractData, contractItemKeys } = useMemo(() => {
    const editableData = getParsedRowData({
      includedFields,
      isEditable: true,
      rowData: contractItem,
    });
    const itemKeys = Object.keys(editableData);

    return { editableContractData: editableData, contractItemKeys: itemKeys };
  }, [contractItem]);

  const initialFormValues = useMemo(
    () => addAdditionalFieldsToOrder({
      order: contractItem,
      fields: additianalOrderFields,
      parsedOrder: editableContractData,
    }),
    [contractItem, editableContractData],
  );

  const title = isEditMode ? editOrderTitle : viewOrderTitle;
  const editRowId = (isEditMode || isViewMode) ? activeRowId : undefined;
  const isLoading = isLoadingContractItem;
  const showSidebar = activeRowId && (isViewMode || isEditMode);

  const topActions = (
    <HeaderActions
      secondarySidebarMode={secondarySidebarMode}
      setSecondarySidebarMode={setSecondarySidebarMode}
      isVisibleOrderDataButton={isAvailableGetOrderData}
      isVisibleLogAndFilesButton={isAvailableGetLogAndFiles}
    />
  );

  const onSubmit = async (params, { setErrors, resetForm }) => {
    if (!activeRow || !isEditMode) return;

    const requestParams = omitAdditionalFieldsFromOrder({
      order: params,
      mandatoryFields: includedFields,
      initialOrder: editableContractData,
      additionalFields: additianalOrderFields,
    });

    try {
      await dispatch(editRowContractManagementAction({
        orderId: activeRow.order,
        params: requestParams,
      })).unwrap();

      cleanActiveRow();
      showSuccessNotifier(patchOrderMessage.success);
      getTableData();
      resetForm();
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);
      showErrorNotifier(patchOrderMessage.error, error);
    }
  };

  const getContractManagementItem = async (rowId: string | number) => {
    try {
      await dispatch(getContractManagementItemAction(rowId))
        .unwrap();
    } catch (error) {
      showErrorNotifier(getOrderMessage.error, error);
    }
  };

  useEffect(() => {
    if (!showSidebar || !activeRow) return;

    getContractManagementItem(activeRow.order);

    return () => {
      dispatch(clearGetContractManagementItemData());
    };
  }, [activeRow]);

  if (!showSidebar) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialFormValues}
    >
      <Form>
        <EditRowSidebar
          title={title}
          editRowId={editRowId}
          topActions={topActions}
          isViewMode={isViewMode}
          isLoadingItem={isLoading}
          cleanEditRow={cleanActiveRow}
          allowedFields={contractItemKeys}
          secondarySidebarMode={secondarySidebarMode}
          schemaName={contractManagementProductsSchema}
          setSecondarySidebarMode={setSecondarySidebarMode}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
