import React, { useState } from 'react';
import CommentsList from 'shared/widgets/Comments/CommentsList';
import CommentsTextArea from 'shared/widgets/Comments/CommentsTextArea';
// eslint-disable-next-line no-unused-vars
import { ICommentsContentProps } from 'shared/widgets/Comments/Comments.models';

const CommentsContent: React.FC<ICommentsContentProps> = ({
  error,
  comments,
  isAvailableAddComment = true,
  addNewComment,
  updateComment,
  deleteComment,
  clearCommentsErrors,
}) => {
  const [newCommentText, setNewCommentText] = useState('');
  const [editCommentId, setEditCommentId] = useState('');
  const [editCommentText, setEditCommentText] = useState('');

  const onChangeComment = (e) => {
    setNewCommentText(e.target.value);
    clearCommentsErrors();
  };

  const onEditComment = (e) => {
    setEditCommentText(e.target.value);
  };

  const onFocusEditComment = (e, comment) => {
    e.preventDefault();
    const { id } = comment;
    setEditCommentId(id);
    setEditCommentText(e.target.value);
    clearCommentsErrors();
  };

  const onCreateNewComment = (e) => {
    e.preventDefault();
    addNewComment(newCommentText);
    setNewCommentText('');
  };

  const onUpdateComment = (e) => {
    e.preventDefault();
    updateComment(Number(editCommentId), editCommentText);
    setEditCommentId('');
    setEditCommentText('');
  };

  const onRemoveComment = (e, commentId) => {
    e.preventDefault();
    deleteComment(commentId);
  };

  const commentsList = (
    <CommentsList
      comments={comments}
      editCommentId={editCommentId}
      editCommentText={editCommentText}
      onFocusEditComment={onFocusEditComment}
      onEditComment={onEditComment}
      onRemoveComment={onRemoveComment}
    />
  );

  const textAreaBlock = isAvailableAddComment ? (
    <CommentsTextArea
      newCommentText={newCommentText}
      error={error}
      editCommentId={editCommentId}
      onChangeComment={onChangeComment}
      onCreateNewComment={onCreateNewComment}
      onUpdateComment={onUpdateComment}
    />
  ) : undefined;

  return (
    <>
      {commentsList}
      {textAreaBlock}
    </>
  );
};

export default CommentsContent;
