import React, { useState } from 'react';
import { useAppDispatch } from 'core/store';
import CustomModal from 'shared/CustomModal';
import OrderModalItem from 'shared/OrderModalItem';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import {
  engagementAcceptedModalTitle,
  engagementAcceptedButtonText,
  engagementAcceptedNotifierMessage,
} from '@EngagementsRenewalSowData/RenewalSowOrdersActions/EngagementAccepted/constants';
import renewalSowEngagementAcceptedAction from 'core/actions/Sales/RenewalSowEngagementAccepted/RenewalSowEngagementAccepted';

interface IEngagementAcceptedModalProps {
  selectedRenewalSowData: IRenewalSowDataItem[]
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
}

const EngagementAcceptedModal: React.FC<IEngagementAcceptedModalProps> = ({
  selectedRenewalSowData,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { getTableData, setRowSelection } = useTableContext();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const onEngagementAccepted = async () => {
    setIsLoading(true);

    await Promise.all(selectedRenewalSowData.map(
      async ({ id }) => {
        try {
          await dispatch(renewalSowEngagementAcceptedAction(id)).unwrap();
          showSuccessNotifier(engagementAcceptedNotifierMessage.success);
        } catch (error) {
          showErrorNotifier(engagementAcceptedNotifierMessage.error, error);
        }
      }));

    setIsLoading(false);
    setRowSelection({});
    getTableData();
    setIsModalOpen(false);
  };

  return (
    <CustomModal
      areButtonsVisible
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      onCloseModal={setIsModalOpen}
      title={engagementAcceptedModalTitle}
      onClickConfirm={onEngagementAccepted}
      cancelButtonText={engagementAcceptedButtonText.cancelButtonText}
      confirmButtonText={engagementAcceptedButtonText.confirmButtonText}
    >
      <>
        {
          selectedRenewalSowData.map(({ id, product, customerLocation }) => (
            <OrderModalItem
              key={id}
              product={product}
              location={customerLocation}
            />
          ))
        }
      </>
    </CustomModal>
  );
};

export default EngagementAcceptedModal;
