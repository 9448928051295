// eslint-disable-next-line no-unused-vars
import { EngagementType, IContract } from './ContractManagement.models';

export const isContractEngagementTypePending = (
  row: IContract,
) => row?.engagementType === EngagementType.PENDING;

export const isContractEngagementTypeInProgress = (
  row: IContract,
) => row?.engagementType === EngagementType.IN_PROGRESS;

export const isContractEngagementTypeCanceled = (
  row: IContract,
) => row?.engagementType === EngagementType.CANCELED;

export const isContractEngagementTypeDeclined = (
  row: IContract,
) => row?.engagementType === EngagementType.DECLINED;
