import isNull from 'lodash/isNull';
import { StateEnum } from '@EngagementsSowData/sowData.model';
// eslint-disable-next-line no-unused-vars
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';

const { EXISTING } = StateEnum;

export const validateCommonOrderField = (order: IRenewalSowDataItem) => {
  const { term, bandwidth, existingCktId } = order || {};
  const isEmptyExistingCktId = 'existingCktId' in order && !existingCktId;
  return isNull(term) || !bandwidth || isEmptyExistingCktId;
};

export const validateOrderBCPFields = (order: IRenewalSowDataItem) => {
  const {
    businessCenterProfile,
    businessCenterProfileUsername,
    businessCenterProfileCompanyId,
  } = order || {};

  const isExistBusinessCenterProfile = 'businessCenterProfile' in order;

  return isExistBusinessCenterProfile && (!businessCenterProfile
  || (businessCenterProfile === EXISTING
  && (!businessCenterProfileCompanyId || !businessCenterProfileUsername)));
};
