import { netsparkAxios } from 'core/services/HTTPService';
import {
  getContractSpecialistActionsUrl,
  getEditContractManagementItemUrl,
  getRequestAcceptedActionsUrl,
  getRequestCompleteActionsUrl,
} from 'core/api/sales.api/constants';

export const postRequestAcceptedActions = (params: { bundleId: number }) => (
  netsparkAxios.post(getRequestAcceptedActionsUrl(params.bundleId))
);

export const postRequestCompleteActions = (contractId) => (
  netsparkAxios.post(getRequestCompleteActionsUrl(contractId))
);

export const patchContractSpecialistActions = (params) => (
  netsparkAxios.patch(getContractSpecialistActionsUrl(params.bundleId), {
    contractSpecialistEmail: params.email,
  })
);

export const patchEditRowContractManagementAction = (orderId: number, params) => (
  netsparkAxios.patch(getEditContractManagementItemUrl(orderId), params)
);
