/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IOrderDataWithRelatedOrders } from 'core/models/order.models';
import getContractManagementItemById from './api.service';

export interface IState {
  isLoading: boolean;
  error: object | null
  result: IOrderDataWithRelatedOrders;
}

const initialState: IState = {
  result: {} as IOrderDataWithRelatedOrders,
  error: null,
  isLoading: false,
};

export const getContractManagementItemAction = createAsyncThunk(
  'getContractManagementItemAction',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getContractManagementItemById(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getContractManagementItemSlice = createSlice({
  name: 'getContractManagementItem',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(
        getContractManagementItemAction.pending,
        (state) => {
          state.isLoading = true;
          state.result = initialState.result;
        },
      )
      .addCase(
        getContractManagementItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        getContractManagementItemAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error;
          state.result = initialState.result;
        });
  },
});

export const { clear: clearGetContractManagementItemData } = getContractManagementItemSlice.actions;

export const getContractManagementItemReducer = getContractManagementItemSlice.reducer;
