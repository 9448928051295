export const engagementAcceptedTitle = 'Engagement accepted';

export const engagementAcceptedModalTitle = 'Are you sure you want to accept engagement for the selected order/s?';

export const engagementAcceptedButtonText = {
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
};

export const engagementAcceptedNotifierMessage = {
  success: 'Renewal Sow item updated successfully.',
  error: 'Failed to update Renewal Sow item.',
};
