// eslint-disable-next-line no-unused-vars
import { IOrderDataWithRelatedOrders } from 'core/models/order.models';
import { getOrderDataFromMeta, getParsedRowData } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
// eslint-disable-next-line no-unused-vars
import { IParsedSchemaDataItem } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

export const getRenderOrdersList = (
  orderData: IOrderDataWithRelatedOrders,
  shemaData: IParsedSchemaDataItem = {},
) => {
  const { orders, ...currentOrderData } = orderData || {};

  const currentParsedOrderData = getParsedRowData({
    isEditable: false,
    rowData: currentOrderData,
  });
  const currentRenderOrderData = {
    label: 'Current Order',
    data: getOrderDataFromMeta(shemaData, currentParsedOrderData),
  };

  const relatedOrdersData = orders?.value || [];
  const releatedRenderOrdersData = relatedOrdersData.map((relatedOrderData) => {
    const fieldsData = getOrderDataFromMeta(shemaData, relatedOrderData);
    const renderOrderNumber = relatedOrderData.orderNumber || '';
    const renderContractCountersigningDate = relatedOrderData.contractCountersigningDate || '';
    return {
      data: fieldsData,
      label: `Order ${renderOrderNumber} ${renderContractCountersigningDate}`,
    };
  });

  return [currentRenderOrderData, ...releatedRenderOrdersData];
};
