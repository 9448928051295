import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';
import { HTTPService } from 'core/services';
import CustomAccardion from 'shared/CustomAccardion';
import { useActionPermission } from 'core/hooks/useActionPermission';
import AttachmentsContent from 'shared/widgets/Attachments/AttachmentsContent';
// eslint-disable-next-line no-unused-vars
import { IDraftAttachment } from 'shared/widgets/Attachments/Attachments.model';
import { ACTIONS_CONTRACT_MANAGEMENT_PATHS } from 'core/models/actionsPermissions';
import clearAttachmentsErrorsAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/ClearErrorsAttachments/actions';
import getEngagementAttachmentsAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/GetEngagementsAttachments/actions';
import createEngagementsAttachmentAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/CreateEngagementsAttachment/actions';
import removeEngagementsAttachmentAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/RemoveEngagementsAttachment/actions';
import { attachmentsConfig } from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/LogAndFilesSidebar/constants';
import editEngagementsAttachmentNameAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/EditEngagementsAttachmentName/actions';
import { getEngagementsAttachmentsClear } from 'pages/WirelineEngagements/WirelineEngagementsAttachments/GetEngagementsAttachments/actionsCreators';

const { ATTACHMENTS_CREATE } = ACTIONS_CONTRACT_MANAGEMENT_PATHS;

let controller = HTTPService.getController();

const setNewController = () => {
  controller = HTTPService.getController();
};

const cancelRequest = () => HTTPService.cancelRequest(controller);

interface IAttachmentsAccardionProps {
  engagementId: number
}

const AttachmentsAccardion: React.FC<IAttachmentsAccardionProps> = ({
  engagementId,
}) => {
  const dispatch = useAppDispatch();
  const attachments = useAppSelector(
    (state) => state.wirelineEngagements.attachments.data.results,
  );
  const isLoading = useAppSelector((state) => state.wirelineEngagements.attachments.isLoading);

  const isAvailableCreateAttachments = useActionPermission(ATTACHMENTS_CREATE);

  const clearEngagementAttachmentsErrors = () => {
    dispatch(clearAttachmentsErrorsAction(attachments));
  };

  const getEngagementAttachments = () => {
    if (!engagementId) return;

    cancelRequest();
    setNewController();

    dispatch(getEngagementAttachmentsAction(controller, engagementId));
  };

  const editAttachment = (attachmentId: string | number, newAttachment: IDraftAttachment) => {
    dispatch(editEngagementsAttachmentNameAction(engagementId, attachmentId, newAttachment));
  };

  const createAttachment = (attachmentFile: FormData) => {
    dispatch(createEngagementsAttachmentAction(engagementId, attachmentFile));
  };

  const removeAttachment = (attachmentId: number) => {
    dispatch(removeEngagementsAttachmentAction(engagementId, attachmentId));
  };

  const clearAttachments = () => {
    dispatch(getEngagementsAttachmentsClear());
  };

  useEffect(() => {
    getEngagementAttachments();

    return () => {
      cancelRequest();
      clearAttachments();
    };
  }, [engagementId]);

  return (
    <CustomAccardion
      title="Attachments"
      isLoading={isLoading}
      className="log-and-file__accardion"
      content={(
        <AttachmentsContent
          isLoading={isLoading}
          attachments={attachments}
          config={attachmentsConfig}
          editAttachment={editAttachment}
          createAttachment={createAttachment}
          removeAttachment={removeAttachment}
          isAvailableAddAttachments={isAvailableCreateAttachments}
          clearAttachmentsErrors={clearEngagementAttachmentsErrors}
        />
)}
    />
  );
};

export default AttachmentsAccardion;
