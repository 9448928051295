import React, { useEffect } from 'react';
import { useAppDispatch } from 'core/store';
import { tableIds } from 'shared/Table/constants';
import { TRow } from 'shared/Table/Table.models';
import { TableProvider } from 'shared/Table/TableContext';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { TableActiveRowContextProvider } from 'shared/Table/TableActiveRowContext';
import { columns } from 'pages/ContractManagment/ContractManagementList/constants';
import { useContractManagementPermissions } from 'pages/ContractManagment/useContractManagementPermission';
import ContractManagementTable from 'pages/ContractManagment/ContractManagementList/ContractManagementTable';
import { clearContractManagementList } from 'pages/ContractManagment/ContractManagementList/GetContractManagementList';
import { isContractEngagementTypePending, isContractEngagementTypeInProgress } from 'pages/ContractManagment/utilities';
import getContractManagementListAction from 'pages/ContractManagment/ContractManagementList/GetContractManagementList/actions';
import './ContractManagementList.scss';

const ContractManagementList = () => {
  const dispatch = useAppDispatch();
  const {
    isAvailableSomeRowAction,
    isAvailableRequestsActions,
  } = useContractManagementPermissions();

  const enableRowSelection = isAvailableRequestsActions
    && ((row) => {
      const isInProgressType = isContractEngagementTypeInProgress(row.original);
      const isPendingType = isContractEngagementTypePending(row.original);

      return isInProgressType || isPendingType;
    });

  useEffect(() => () => {
    dispatch(clearContractManagementList());
  }, []);

  return (
    <div className="contract-list">
      <TableActiveRowContextProvider>
        <TableProvider
          id={tableIds.contractManagement}
          columns={columns as ColumnDef<TRow>[]}
          enableRowSelection={enableRowSelection}
          enableEditing={isAvailableSomeRowAction}
          getDataAction={getContractManagementListAction}
        >
          <ContractManagementTable />
        </TableProvider>
      </TableActiveRowContextProvider>
    </div>
  );
};

export default ContractManagementList;
