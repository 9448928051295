import React, { memo } from 'react';
import CustomSidebar from 'shared/CustomSidebar';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import LogAndFileSidebarContent from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/LogAndFilesSidebar/LogAndFilesSidebarContent';

import './LogAndFilesSidebar.scss';

const LogAndFilesSidebar = () => {
  const {
    activeRow,
    activeRowMode,
    secondarySidebarMode,
    cleanActiveRow,
    setSecondarySidebarMode,
  } = useTableActiveRowContext<IContract>();

  const { isCommentMode } = getTableRowModeBoolean(activeRowMode);
  const { isCommentMode: isCommentInsideEditMode } = getTableRowModeBoolean(secondarySidebarMode);

  const isOpen = !!activeRow && (isCommentMode || isCommentInsideEditMode);

  const onCloseHandler = () => (secondarySidebarMode
    ? setSecondarySidebarMode(null)
    : cleanActiveRow());

  return (
    <CustomSidebar
      isOpen={isOpen}
      title="Log&Files"
      isConfirmDialog={false}
      areButtonsVisible={false}
      onCloseSideBar={onCloseHandler}
    >
      {isOpen && <LogAndFileSidebarContent activeRow={activeRow} />}
    </CustomSidebar>
  );
};

export default memo(LogAndFilesSidebar);
