import React from 'react';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import { requestCompleteAction } from 'core/actions/ContractManagement/RequestComplete';
import RequestActionModalContent from 'pages/ContractManagment/ContractManagementList/RequestsActions/shared/RequestActionModalContent';
import {
  requestCompletedNotifier,
  requestCompleteModalTitle,
} from 'pages/ContractManagment/ContractManagementList/RequestsActions/constants';

interface IRequestCompleteModalContentProps {
  onCloseModal: ()=> void;
  selectedContracts: IContract[];
}

const RequestCompleteModalContent: React.FC<IRequestCompleteModalContentProps> = ({
  selectedContracts, onCloseModal,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData } = useTableContext();

  const isLoading = useAppSelector((state) => state.contractManagement.requestComplete.isLoading);

  const onRequestComplete = async () => {
    const contractsIds = selectedContracts.map((contract) => contract.id);

    await Promise.all(contractsIds.map(
      async (contractId) => {
        try {
          await dispatch(requestCompleteAction(contractId)).unwrap();

          showSuccessNotifier(requestCompletedNotifier.success);
          getTableData();
          onCloseModal();
        } catch (e) {
          showErrorNotifier(requestCompletedNotifier.error, e);
        }
      }),
    );
  };

  return (
    <RequestActionModalContent
      isLoading={isLoading}
      onClose={onCloseModal}
      contracts={selectedContracts}
      title={requestCompleteModalTitle}
      onClickConfirm={onRequestComplete}
    />
  );
};

export default RequestCompleteModalContent;
