/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRenewalSowItemById } from 'core/api/sales.api';
import { IOrderDataWithRelatedOrders } from 'core/models/order.models';

export interface IState {
  isLoading: boolean;
  error: object | null
  result: IOrderDataWithRelatedOrders;
}

const initialState: IState = {
  result: {} as IOrderDataWithRelatedOrders,
  error: null,
  isLoading: false,
};

export const getSalesRenewalSowItemAction = createAsyncThunk(
  'getSalesRenewalSowItemAction',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getRenewalSowItemById(id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getSalesRenewalSowItemSlice = createSlice({
  name: 'getSalesRenewalSowItem',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(
        getSalesRenewalSowItemAction.pending,
        (state) => {
          state.isLoading = true;
          state.result = initialState.result;
        },
      )
      .addCase(
        getSalesRenewalSowItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        getSalesRenewalSowItemAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error;
          state.result = initialState.result;
        });
  },
});

export const { clear: clearGetRenealSowItemData } = getSalesRenewalSowItemSlice.actions;

export const getSalesRenewalSowItemReducer = getSalesRenewalSowItemSlice.reducer;
