import { ENGAGEMENT_BILING_TYPE } from '@EngagementsSowData/sowData.model';
import { contractSuportValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { BoolEnum } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
// eslint-disable-next-line no-unused-vars
import { IControlledFieldsConfigItem } from 'pages/WirelineEngagements/shared/FormikFieldSelector/models';

export const controlledFieldsConfigs: IControlledFieldsConfigItem[] = [
  {
    fieldName: 'creditRequest',
    switchFieldName: 'engagementBillingType',
    switchEnabledValues: [ENGAGEMENT_BILING_TYPE.CREDIT_REQUEST],
  },
  {
    fieldName: 'debitRequest',
    switchFieldName: 'engagementBillingType',
    switchEnabledValues: [ENGAGEMENT_BILING_TYPE.DEBIT_REQUEST],
  },
  {
    fieldName: 'etf',
    switchFieldName: 'engagementBillingType',
    switchEnabledValues: [ENGAGEMENT_BILING_TYPE.EARLY_TERM_FREE],
  },
  {
    fieldName: 'existingMrc',
    switchFieldName: 'existingService',
    switchEnabledValues: [BoolEnum.YES],
  },
  {
    fieldName: 'existingNrc',
    switchFieldName: 'existingService',
    switchEnabledValues: [BoolEnum.YES],
  },
  {
    fieldName: 'existingServiceAccountNumber',
    switchFieldName: 'existingService',
    switchEnabledValues: [BoolEnum.YES],
  },
  {
    fieldName: 'existingMrc',
    switchFieldName: 'contractOrderSupport',
    switchEnabledValues: [contractSuportValues.renewal],
  },
  {
    fieldName: 'existingNrc',
    switchFieldName: 'contractOrderSupport',
    switchEnabledValues: [contractSuportValues.renewal],
  },
  {
    fieldName: 'existingServiceAccountNumber',
    switchFieldName: 'contractOrderSupport',
    switchEnabledValues: [contractSuportValues.renewal],
  },
];

export const includedFields = ['product'];

export const additianalOrderFields = ['existingService', 'contractOrderSupport'];
