/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { postRequestAcceptedActions } from 'core/api/contractManagment.api';

const initialState = {
  isLoading: false,
};

export const requestAcceptedAction = createAsyncThunk(
  'requestAcceptedAction',
  async (params: { bundleId: number }, { rejectWithValue }) => {
    try {
      await postRequestAcceptedActions(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const requestAcceptedSlice = createSlice({
  name: 'requestAccepted',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        requestAcceptedAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(requestAcceptedAction.rejected, requestAcceptedAction.fulfilled),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const requestAcceptedReducer = requestAcceptedSlice.reducer;
