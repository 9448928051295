import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getRenewalSowPath } from 'core/routing/paths';
import RequestProvidedDate from 'shared/Table/shared/RequestProvidedDate';

interface IRenewalSowDataTableDateColumnItemProps {
  viewPath?: string,
  isRequest?: boolean
  requestProvidedDate: string
}

const RenewalSowDataTableDateColumnItem: React.FC<IRenewalSowDataTableDateColumnItemProps> = ({
  viewPath,
  isRequest,
  requestProvidedDate,
}) => {
  const { wirelineEngagementId, customerId } = useParams();

  const path = useMemo(() => {
    const startPath = getRenewalSowPath(wirelineEngagementId, customerId);

    return `${startPath}${viewPath}`;
  }, [viewPath, customerId, wirelineEngagementId]);

  return (
    <RequestProvidedDate
      viewPath={path}
      isRequest={isRequest}
      requestProvidedDate={requestProvidedDate}
    />
  );
};

RenewalSowDataTableDateColumnItem.defaultProps = {
  isRequest: false,
  viewPath: '',
};

export default memo(RenewalSowDataTableDateColumnItem);
