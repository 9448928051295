import React, { useEffect, useState } from 'react';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import RequestActionModalContent from 'pages/ContractManagment/ContractManagementList/RequestsActions/shared/RequestActionModalContent';
import {
  contractSpecialistNotifier,
  contractSpecialistTitle,
} from 'pages/ContractManagment/ContractManagementList/RequestsActions/constants';
import { contractSpecialistAction } from 'core/actions/ContractManagement/ContractSpecialist';
import getUsersAction from 'core/actions/Customers/GetUsers/actions';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import ContractSpecialistSelect from 'pages/ContractManagment/ContractManagementList/RequestsActions/ContractSpecialistSelect';

interface IRequestCompleteModalContentProps {
  onCloseModal: () => void;
  selectedContracts: IContract[];
}

const ContractSpecialistModalContent: React.FC<IRequestCompleteModalContentProps> = ({
  selectedContracts, onCloseModal,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData } = useTableContext();
  const [selectedContractSpecialist, setSelectedContractSpecialist] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getUsersAction({ department: UserTeamRoleTeamDepartment.CONTRACT_SPECIALIST }));
  }, []);

  const isLoading = useAppSelector(
    (state) => state.contractManagement.contractSpecialist.isLoading,
  );

  const getBundleIds = () => Array.from(new Set(selectedContracts.map((item) => item.bundle)));

  const onContractSpecialist = async () => {
    if (selectedContractSpecialist) {
      const bundleIds = getBundleIds();

      await Promise.all(bundleIds.map(
        async (bundleId) => {
          try {
            await dispatch(contractSpecialistAction({
              bundleId, email: selectedContractSpecialist,
            })).unwrap();

            showSuccessNotifier(contractSpecialistNotifier.success);
            getTableData();
            onCloseModal();
          } catch (e) {
            showErrorNotifier(contractSpecialistNotifier.error, e);
          }
        }),
      );
    }
  };

  return (
    <RequestActionModalContent
      isLoading={isLoading}
      onClose={onCloseModal}
      contracts={selectedContracts}
      title={contractSpecialistTitle}
      onClickConfirm={onContractSpecialist}
      confirmButtonDisable={!selectedContractSpecialist}
    >
      <ContractSpecialistSelect
        selectedContractSpecialist={selectedContractSpecialist}
        setSelectedContractSpecialist={setSelectedContractSpecialist}
      />
    </RequestActionModalContent>
  );
};

export default ContractSpecialistModalContent;
