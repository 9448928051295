import { createSlice } from '@reduxjs/toolkit';
import { IBookingDataItem } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';

export interface IState {
  count: number
  isLoading: boolean
  error: object | null
  results: IBookingDataItem[]
}

const initialState: IState = {
  count: 0,
  results: [],
  error: null,
  isLoading: true,
};

const BookingCSDataSlice = createSlice({
  name: 'getBookingCSData',
  initialState,
  reducers: {
    getBookingCSDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getBookingCSDataSuccess: (state, action) => ({
      ...state,
      results: [...action.payload],
      count: action.payload.length,
      isLoading: false,
    }),
    getBookingCSDataFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
      results: [],
      count: 0,
    }),
    clearBookingCSDataAction: () => initialState,
  },
});

export const {
  getBookingCSDataRequest,
  getBookingCSDataSuccess,
  getBookingCSDataFailure,
  clearBookingCSDataAction,
} = BookingCSDataSlice.actions;

export const getBookingCSDataReducer = BookingCSDataSlice.reducer;
