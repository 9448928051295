import React from 'react';
import OrderModalItem from 'shared/OrderModalItem';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { orderActionsItemTooltip } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

import './OrderItem.scss';

interface IOrderItemProps {
  order: ISowDataListItem;
  orderAction: OrderActions;
}

const OrderItem: React.FC<IOrderItemProps> = ({
  order,
  orderAction,
}) => {
  const {
    customerLocation, product, requestQuoteDatetime,
    provideQuoteDatetime, provideContractDatetime,
    provideOrderActionDatetime, requestOrderActionDatetime,
  } = order;

  const checkedOrder = {
    [OrderActions.REQUEST_QUOTES]: requestQuoteDatetime,
    [OrderActions.PROVIDE_QUOTES]: provideQuoteDatetime,
    [OrderActions.PROVIDE_CONTRACTS]: provideContractDatetime,
    [OrderActions.PROVIDE_ORDERS]: provideOrderActionDatetime,
    [OrderActions.REQUEST_ORDERS]: requestOrderActionDatetime,
  };

  const tooltipTitle = orderActionsItemTooltip[orderAction];
  const isAccepted = checkedOrder[orderAction];

  const isTooltip = !!(tooltipTitle && isAccepted);

  return (
    <OrderModalItem
      product={product}
      accepted={isAccepted}
      withTooltip={isTooltip}
      location={customerLocation}
      tooltipTitle={tooltipTitle}
    />
  );
};

export default OrderItem;
