import React, { useState } from 'react';
import Loader from 'shared/Loader';
import { Formik, Form } from 'formik';
import { useAppDispatch } from 'core/store';
import { useNavigate } from 'react-router-dom';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { REQUEST_BUNDLES_TYPE } from 'core/actions/Sales/RequestBundles/constants';
import schema from 'pages/WirelineEngagements/shared/ContractForm/ContractFormSchema';
import ContractDetails from 'pages/WirelineEngagements/shared/ContractForm/ContractDetails';
import ContractFormInfo from 'pages/WirelineEngagements/shared/ContractForm/ContractFormInfo';
import contractBundlesAction from 'pages/WirelineEngagements/shared/ContractForm/contractBundles';
import ContractFormButtons from 'pages/WirelineEngagements/shared/ContractForm/ContractFormButtons';
import RequestContractModal from 'pages/WirelineEngagements/shared/ContractForm/RequestContractModal';
import { IContractFormInitialValues, IContractBundlesById } from '@EngagementsSowData/salesSow.model';
import ContractFormSettings from 'pages/WirelineEngagements/shared/ContractForm/ContractFormSettings';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';
import { requestContractsNotifierMessage } from 'pages/WirelineEngagements/shared/ContractForm/constants';
import { IContractDetailsOrders, IContractRequestOrders } from 'pages/WirelineEngagements/shared/ContractForm/contractForm.model';

import './ContractForm.scss';

interface IContractFormProps {
  isLoading: boolean
  orderIds?: string[]
  isViewMode?: boolean
  backRoutePath: string
  numberOfOrders: number
  subAccountId: number | string
  selectedOrders?: IContractRequestOrders
  requestType?: REQUEST_BUNDLES_TYPE | undefined
  contractDetailsOrders: IContractDetailsOrders[]
  initialValues: IContractFormInitialValues | IContractBundlesById
}

const ContractForm: React.FC<IContractFormProps> = ({
  orderIds,
  isLoading,
  isViewMode,
  requestType,
  subAccountId,
  backRoutePath,
  initialValues,
  selectedOrders,
  numberOfOrders,
  contractDetailsOrders,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);

  const onSubmit = async (params, { setErrors }) => {
    const paramsWithId = {
      orderIds,
      ...params,
      requestType,
      additionalContacts: params.additionalContacts ? params.additionalContacts.split(' ') : [],
    };

    try {
      await dispatch(contractBundlesAction(paramsWithId)).unwrap();

      showSuccessNotifier(requestContractsNotifierMessage.success);
      navigate(backRoutePath);
    } catch (error) {
      const definedError = error as IServerValidationError;
      setErrors(definedError?.response?.data);

      showErrorNotifier(requestContractsNotifierMessage.error, error);
    }
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div>
      <ContractDetails
        backRoutePath={backRoutePath}
        orders={contractDetailsOrders}
        numberOfOrders={numberOfOrders}
        isViewMode={Boolean(isViewMode)}
      />
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
      >
        <Form className="contract-form">
          <ContractFormInfo
            subAccountId={String(subAccountId)}
            isDisabled={Boolean(isViewMode)}
          />
          <ContractFormSettings
            isDisabled={Boolean(isViewMode)}
          />
          <ContractFormButtons
            setIsActionsModalOpen={setIsActionsModalOpen}
            isDisabled={Boolean(isViewMode)}
          />
          {!isViewMode && (
            <RequestContractModal
              isModalOpen={isActionsModalOpen}
              setIsModalOpen={setIsActionsModalOpen}
              selectedOrders={selectedOrders as IContractRequestOrders}
            />
          )}
          <RouterFormConfirmDialogFormik />
        </Form>
      </Formik>
    </div>
  );
};

ContractForm.defaultProps = {
  orderIds: [],
  isViewMode: false,
  selectedOrders: [],
  requestType: undefined,
};

export default ContractForm;
