import React from 'react';
import { Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getActiveNumbersPath, getRenewalSowPath, getSowDataPath } from 'core/routing/paths';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const EngagementsFormScopeDescriptionActions = () => {
  const navigate = useNavigate();
  const { wirelineEngagementId } = useParams();
  const { isEditModeGeneral, initialCustomerId } = useWirelineEngagementsCreateContext();
  const isCreateMode = !wirelineEngagementId;
  const isButtonDisabled = isCreateMode || isEditModeGeneral;

  const navigateToSowData = () => {
    navigate(getSowDataPath(wirelineEngagementId, initialCustomerId));
  };

  const navigateToActiveNumbers = () => {
    navigate(getActiveNumbersPath(wirelineEngagementId, initialCustomerId));
  };

  const navigateToRenewalSow = () => {
    navigate(getRenewalSowPath(wirelineEngagementId, initialCustomerId));
  };

  return (
    <>
      <Button
        variant="outlined"
        disabled={isButtonDisabled}
        onClick={navigateToRenewalSow}
        sx={{ mr: 2, mt: 3 }}
      >
        RENEWAL SOW
      </Button>
      <Button
        variant="outlined"
        disabled={isButtonDisabled}
        onClick={navigateToSowData}
        sx={{ mr: 2, mt: 3 }}
      >
        SOW DATA
      </Button>
      <Button
        variant="outlined"
        disabled={isButtonDisabled}
        onClick={navigateToActiveNumbers}
        sx={{ mr: 2, mt: 3 }}
      >
        ACTIVE NUMBERS
      </Button>
    </>
  );
};

export default EngagementsFormScopeDescriptionActions;
