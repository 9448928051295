export const editOrderTitle = 'Edit Order';
export const viewOrderTitle = 'View Order';
export const getOrderMessage = {
  error: 'Failed to fetch order',
};

export const patchOrderMessage = {
  error: 'Failed to update Order',
  success: 'Order successfully updated',
};

export const contractManagementProductsSchema = 'DynamicContractManagementProductsSerializerSchema';

export const includedFields = ['product'];
