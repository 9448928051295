import React from 'react';
import WidgetTemplate from 'shared/widgets/WidgetTemplate';
import CommentsContent from 'shared/widgets/Comments/CommentsContent';
// eslint-disable-next-line no-unused-vars
import { ICommentsProps } from 'shared/widgets/Comments/Comments.models';

const Comments: React.FC<ICommentsProps> = ({
  isLoading,
  isAvailableAddComment = true,
  ...props
}) => (
  <WidgetTemplate
    title="Comments"
    isLoading={isLoading}
    className="widget-comments"
    content={(
      <CommentsContent
        {...props}
        isAvailableAddComment={isAvailableAddComment}
      />
    )}
  />
);

export default Comments;
