import React from 'react';
import CustomModal from 'shared/CustomModal';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { requestBundlesAction } from 'core/actions/Sales/RequestBundles';
import { REQUEST_BUNDLES_TYPE } from 'core/actions/Sales/RequestBundles/constants';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import { orderActionsTitle, requestQuoteNotifier } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import OrderItem from '@EngagementsRenewalSowData/RenewalSowOrdersActions/OrdersActionsMenu/RequestQuoteModal/OrderItem';

interface IActionsModalProps {
  isModalOpen: boolean;
  selectedOrders: IRenewalSowDataItem[];
  setIsModalOpen: (isModalOpen: boolean) => void;
}

const ActionsModal: React.FC<IActionsModalProps> = ({
  isModalOpen,
  selectedOrders,
  setIsModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData, setRowSelection } = useTableContext();

  const isLoading = useAppSelector((state) => state.wirelineEngagements.requestBundler.isLoading);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onConfirm = async () => {
    const orderIds = selectedOrders.reduce<number[]>(
      (value, current) => [...value, ...(!current.requestQuoteDatetime ? [current.id] : [])],
      [],
    );

    const requestParams = { orderIds, requestType: REQUEST_BUNDLES_TYPE.RENEWAL_QUOTE };

    try {
      await dispatch(requestBundlesAction(requestParams))
        .unwrap();

      showSuccessNotifier(requestQuoteNotifier.success);
      setRowSelection({});
      getTableData();
      onClose();
    } catch (error) {
      showErrorNotifier(requestQuoteNotifier.error, error);
    }
  };

  return (
    <CustomModal
      areButtonsVisible
      isLoading={isLoading}
      cancelButtonText="No"
      onCloseModal={onClose}
      confirmButtonText="Yes"
      isModalOpen={isModalOpen}
      onClickConfirm={onConfirm}
      title={orderActionsTitle[OrderActions.REQUEST_QUOTES]}
    >
      <>
        {selectedOrders.map((order) => (
          <OrderItem key={order.id} order={order} />
        ))}
      </>
    </CustomModal>
  );
};

export default ActionsModal;
