import React from 'react';
import { useRenewalSowPermissions } from '@EngagementsRenewalSowData/useRenewalSowPermission';
import DataRowSidebar from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DataRowSidebar';
import CommentsSidebar from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/CommentsSidebar';
import CancelOrderModal from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/CancelOrderModal';
import DeclineOrderModal from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal';
import EditRowSidebarForm from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EditRowSidebarForm';
import EscalationSupportModal from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EscalationSupportModal';

const RenewalSowDataTableModals = () => {
  const {
    isAvailableEditOrder,
    isAvailableGetComments,
    isAvailableCancelOrder,
    isAvailableGetOrderData,
    isAvailableDeclineRenewal,
    isAvailableCreateEscalationSupport,
  } = useRenewalSowPermissions();

  return (
    <>
      { isAvailableEditOrder && <EditRowSidebarForm /> }
      { isAvailableGetOrderData && <DataRowSidebar /> }
      { isAvailableGetComments && <CommentsSidebar /> }
      { isAvailableCancelOrder && <CancelOrderModal /> }
      { isAvailableDeclineRenewal && <DeclineOrderModal /> }
      { isAvailableCreateEscalationSupport && <EscalationSupportModal /> }
    </>
  );
};

export default RenewalSowDataTableModals;
