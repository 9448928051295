import React from 'react';
import map from 'lodash/map';
import { useAppSelector } from 'core/store';
import CustomSingleSelect from 'shared/CustomSingleSelect';

interface IContractSpecialistSelectProps {
  setSelectedContractSpecialist: (value: string | null) => void;
  selectedContractSpecialist: string | null;
}

const ContractSpecialistSelect: React.FC<IContractSpecialistSelectProps> = ({
  selectedContractSpecialist, setSelectedContractSpecialist,
}) => {
  const contractSpecialistList = useAppSelector((state) => state.customers.users.results) || [];

  const options = map(contractSpecialistList, ({
    firstName, lastName, email,
  }) => ({ value: email, label: `${firstName} ${lastName}` }));

  return (
    <CustomSingleSelect
      label="Select contract specialist"
      options={options}
      value={selectedContractSpecialist}
      onChange={setSelectedContractSpecialist}
    />
  );
};

export default ContractSpecialistSelect;
