import React, { useEffect, useMemo } from 'react';
import Tabs from 'shared/Tabs';
import compact from 'lodash/compact';
import { HTTPService } from 'core/services';
import CustomAccardion from 'shared/CustomAccardion';
import { useAppDispatch, useAppSelector } from 'core/store';
import CommentsContent from 'shared/widgets/Comments/CommentsContent';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_CONTRACT_MANAGEMENT_PATHS } from 'core/models/actionsPermissions';
import { COMMENTS_ORDER_CATEGORY } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/constants';
import {
  clearCommentsAction,
  clearCommentsErrorsAction,
  createSalesOrderCommentAction,
  editSalesOrderCommentItemAction,
  getSalesOrderCommentsListAction,
  deleteSalesOrderCommentItemAction,
} from 'core/actions/Sales/OrderComments';

const { GENERAL, BILLING } = COMMENTS_ORDER_CATEGORY;
const {
  COMMENTS_BILLING_GET,
  COMMENTS_GENERAL_GET,
  COMMENTS_BILLING_CREATE,
  COMMENTS_GENERAL_CREATE,
} = ACTIONS_CONTRACT_MANAGEMENT_PATHS;

const actionsPaths = {
  isAvailableGetCommentsGeneral: COMMENTS_BILLING_GET,
  isAvailableGetCommentsBilling: COMMENTS_GENERAL_GET,
  isAvailableCreateCommentsGeneral: COMMENTS_GENERAL_CREATE,
  isAvailableCreateCommentsBilling: COMMENTS_BILLING_CREATE,
};

let controller = HTTPService.getController();

const setNewController = () => {
  controller = HTTPService.getController();
};
const cancelRequest = () => HTTPService.cancelRequest(controller);

interface ICommentsAccardion {
  orderId: number
}

const CommentsAccardion:React.FC<ICommentsAccardion> = ({
  orderId,
}) => {
  const dispatch = useAppDispatch();

  const {
    error,
    results,
    isLoading,
  } = useAppSelector((state) => state.wirelineEngagements.orderComments);

  const actionsPermissions = useActionsPermissions(actionsPaths);

  const commentsByCategory = useMemo(
    () => results.reduce((result, item) => (
      {
        ...result,
        [item.category]: result[item.category] ? [...result[item.category], item] : [item],
      }
    ),
    {}),
    [results],
  );

  const config = useMemo(
    () => {
      const {
        isAvailableGetCommentsGeneral,
        isAvailableGetCommentsBilling,
        isAvailableCreateCommentsGeneral,
        isAvailableCreateCommentsBilling,
      } = actionsPermissions;

      return compact([
        (isAvailableGetCommentsGeneral && {
          category: GENERAL,
          tabName: 'General comments',
          isAvailableAddComment: isAvailableCreateCommentsGeneral,
        }),
        (isAvailableGetCommentsBilling && {
          category: BILLING,
          tabName: 'Billing',
          isAvailableAddComment: isAvailableCreateCommentsBilling,
        }),
      ]);
    }, [actionsPermissions]);

  const onRemoveComment = (commentId: number) => {
    dispatch(deleteSalesOrderCommentItemAction({
      parentId: orderId as string | number,
      id: commentId,
    }));
  };

  const onClearCommentsErrors = () => {
    dispatch(clearCommentsErrorsAction());
  };

  const onCreateNewComment = (data: string, category: string) => {
    dispatch(createSalesOrderCommentAction({
      parentId: orderId as string | number,
      params: { text: data, category },
    }));
  };

  const onUpdateComment = (commentId: number, data: string) => {
    dispatch(editSalesOrderCommentItemAction({
      parentId: orderId,
      id: commentId,
      params: { text: data },
    }));
  };

  const tabItemsConfig = useMemo(() => config.map(({
    category, tabName, isAvailableAddComment,
  }) => {
    const comments = commentsByCategory[category] || [];

    return {
      name: tabName,
      children: (<CommentsContent
        error={error}
        comments={comments}
        updateComment={onUpdateComment}
        deleteComment={onRemoveComment}
        clearCommentsErrors={onClearCommentsErrors}
        isAvailableAddComment={isAvailableAddComment}
        addNewComment={(comment) => onCreateNewComment(comment, category)}
      />),
    };
  }), [config, error, commentsByCategory]);

  const getComments = () => {
    if (!orderId) {
      cancelRequest();
      return;
    }

    setNewController();
    dispatch(getSalesOrderCommentsListAction({
      parentId: orderId, controller,
    }));
  };

  const clearComments = () => {
    cancelRequest();
    dispatch(clearCommentsAction());
  };

  useEffect(() => {
    getComments();

    return () => {
      clearComments();
    };
  }, [orderId]);

  return (
    <CustomAccardion
      title="Comments"
      isLoading={isLoading}
      className="log-and-file__accardion"
      content={(
        <Tabs
          tabItemsConfig={tabItemsConfig}
        />
      )}
    />
  );
};

export default CommentsAccardion;
