import {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
} from './actionsCreators';
import getUserManagementList from './api.service';

const getUsersAction = (params) => async (dispatch) => {
  try {
    dispatch(getUsersRequest());
    const { data } = await getUserManagementList(params);
    dispatch(getUsersSuccess(data));
  } catch (error) {
    dispatch(getUsersFailure(error));

    throw error;
  }
};

export default getUsersAction;
