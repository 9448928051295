/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { postOrderDeclineById } from 'core/api/sales.api';
// eslint-disable-next-line no-unused-vars
import { IOrderDecline } from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal/DeclineOrder.model';

export interface IDeclineOrder {
  isLoading: boolean
}

const initialState: IDeclineOrder = {
  isLoading: false,
};

export const declineOrderAction = createAsyncThunk(
  'postDeclineOrderAction',
  async ({ id, params }: { id: string | number, params: IOrderDecline },
    { rejectWithValue }) => {
    try {
      await postOrderDeclineById(id, params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const declineOrderSlice = createSlice({
  name: 'requestBundlesSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        declineOrderAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(declineOrderAction.rejected, declineOrderAction.fulfilled),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const declineOrderReducer = declineOrderSlice.reducer;
