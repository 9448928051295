import React, { useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { HTTPService } from 'core/services';
import TabsHeader from 'shared/Tabs/TabsHeader';
import CustomSidebar from 'shared/CustomSidebar';
import { useAppDispatch, useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { TableRowMode } from 'shared/Table/Table.models';
import { getCommentsTabsConfigs } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/utilities';
import { clearCommentsAction, getSalesOrderCommentsListAction } from 'core/actions/Sales/OrderComments';
import CommentsRowContent from 'pages/WirelineEngagements/shared/CommentsRowSidebar/CommentsRowContent';
import { ICommentsActionsPermissions } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/CommentsRowSidebar.model';
import { COMMENTS_ORDER_CATEGORY } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/constants';

import './CommentsRowSidebar.scss';

let controller = HTTPService.getController();
const setNewController = () => {
  controller = HTTPService.getController();
};
const cancelRequest = () => HTTPService.cancelRequest(controller);

interface ICommentsRowSidebarProps {
  title: string;
  cleanActiveRow: () => void;
  isCommentsInsideEditOpen: boolean;
  activeRowId?: string | number | null;
  availableCreateCategories: ICommentsActionsPermissions;
  availableVissibleCategories: ICommentsActionsPermissions;
  setSecondarySidebarMode: (mode: TableRowMode | null) => void;
}

const CommentsRowSidebar: React.FC<ICommentsRowSidebarProps> = ({
  title,
  activeRowId,
  isCommentsInsideEditOpen,
  availableCreateCategories,
  availableVissibleCategories,
  cleanActiveRow,
  setSecondarySidebarMode,
}) => {
  const dispatch = useAppDispatch();

  const {
    error,
    results,
    isLoading,
  } = useAppSelector((state) => state.wirelineEngagements.orderComments);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const {
    [COMMENTS_ORDER_CATEGORY.GENERAL]: isAvailableGetGeneralComments,
    [COMMENTS_ORDER_CATEGORY.BILLING]: isAvailableGetBillingCommnets,
  } = availableVissibleCategories;

  const commentsByCategory = useMemo(
    () => results.reduce((result, item) => (
      {
        ...result,
        [item.category]: result[item.category] ? [...result[item.category], item] : [item],
      }
    ),
    {}),
    [results],
  );

  const commentsTabsConfigs = useMemo(
    () => getCommentsTabsConfigs(availableVissibleCategories),
    [isAvailableGetGeneralComments, isAvailableGetBillingCommnets]);

  const activeTabCategory = commentsTabsConfigs[activeTabIndex]?.category;
  const categoryComments = commentsByCategory[activeTabCategory] || [];
  const canLeaveComments = availableCreateCategories[activeTabCategory];

  const onCloseCallback = () => {
    if (isCommentsInsideEditOpen) {
      setSecondarySidebarMode(null);
    } else {
      cleanActiveRow();
    }
  };

  const closeEditRowSidebar = () => {
    setActiveTabIndex(0);
    dispatch(clearCommentsAction());
    onCloseCallback();
  };

  const onChangeActiveTab = (_, newCategory) => {
    setActiveTabIndex(newCategory);
  };

  useEffect(() => {
    if (!activeRowId) {
      cancelRequest();
      return;
    }

    setNewController();

    dispatch(getSalesOrderCommentsListAction({
      parentId: activeRowId, controller,
    }));

    return cancelRequest;
  }, [activeRowId]);

  return (
    <CustomSidebar
      title={title}
      isLoading={isLoading}
      isOpen={!!activeRowId}
      isConfirmDialog={false}
      areButtonsVisible={false}
      className="comments-sidebar"
      onCloseSideBar={closeEditRowSidebar}
    >
      <Box
        key={activeRowId}
        className="comments-sidebar__tabs"
      >
        <TabsHeader
          value={activeTabIndex}
          onChange={onChangeActiveTab}
          tabItemsConfig={commentsTabsConfigs}
        />
        <CommentsRowContent
          key={activeTabCategory}
          addCommentError={error}
          activeRowId={activeRowId}
          comments={categoryComments}
          category={activeTabCategory}
          canLeaveComments={canLeaveComments}
          closeEditRowSidebar={closeEditRowSidebar}
        />
      </Box>
    </CustomSidebar>
  );
};

CommentsRowSidebar.defaultProps = {
  activeRowId: null,
};

export default CommentsRowSidebar;
