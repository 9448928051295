import React, { useEffect } from 'react';
import { HTTPService } from 'core/services';
import { getRenewalSowPath } from 'core/routing/paths';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useParams, useSearchParams } from 'react-router-dom';
import { REQUEST_BUNDLES_TYPE } from 'core/actions/Sales/RequestBundles/constants';
import getSalesRenewalSowAction from 'core/actions/Sales/GetSalesRenewalSow/actions';
import ContractFormCreate from 'pages/WirelineEngagements/shared/ContractForm/ContractFormCreate';

const ordersInfoController = HTTPService.getController();

const RenewalSowDataContractRequestCreate = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { wirelineEngagementId, customerId } = useParams();

  const orders = useAppSelector((state) => state.wirelineEngagements.renewalSow.results);
  const isOrdersLoading = useAppSelector((state) => state.wirelineEngagements.renewalSow.isLoading);

  const orderIds = searchParams.get('orderIds')?.split(',') || [];
  const backRoutePath = getRenewalSowPath(wirelineEngagementId, customerId);

  const orderIdsParams = {
    page: 1,
    search: '',
    ordering: '',
    page_size: orderIds.length,
    orderId: orderIds.toString(),
  };

  useEffect(() => {
    dispatch(getSalesRenewalSowAction(ordersInfoController, orderIdsParams));
  }, []);

  return (
    <ContractFormCreate
      orderIds={orderIds}
      selectedOrders={orders}
      backRoutePath={backRoutePath}
      isLoadingOrders={isOrdersLoading}
      requestType={REQUEST_BUNDLES_TYPE.RENEWAL_CONTRACT}
    />
  );
};

export default RenewalSowDataContractRequestCreate;
