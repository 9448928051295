import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { dateFormatWithTime } from 'core/constants';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import './RequestProvidedDate.scss';

interface IRequestProvidedDateProps {
  viewPath?: string,
  requestProvidedDate: string,
  isRequest?: boolean | undefined,
}

const RequestProvidedDate: React.FC<IRequestProvidedDateProps> = ({
  requestProvidedDate, isRequest, viewPath,
}) => {
  const navigate = useNavigate();

  const iconClassName = cx({
    'request-icon': isRequest,
    'provided-icon': !isRequest,
  });

  const renderDate = dayjs(requestProvidedDate).format(dateFormatWithTime);

  const onClickView = () => {
    if (!viewPath) return;

    navigate(viewPath);
  };

  return (
    <div>
      <div className="actions-menu__icon_line">
        <CheckCircleOutlineIcon
          className={iconClassName}
          data-testid={iconClassName}
        />
        {
          viewPath && (
            <Tooltip
              title="View"
              arrow
              placement="top"
            >
              <span>
                <IconButton
                  onClick={onClickView}
                  data-testid="view-button"
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          )
        }
      </div>
      <div>{ renderDate }</div>
    </div>
  );
};

RequestProvidedDate.defaultProps = {
  isRequest: false,
  viewPath: '',
};

export default RequestProvidedDate;
