import React, { memo } from 'react';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import CancelOrderModalForm from 'pages/WirelineEngagements/shared/CancelOrderModalForm';
import { cancelOrderDiscoTitle } from 'pages/WirelineEngagements/shared/CancelOrderModalForm/constants';

const CancelOrderModal = () => {
  const {
    activeRow,
    activeRowMode,
    cleanActiveRow,
  } = useTableActiveRowContext<ISowDataListItem>();

  const { isCancelOrderMode } = getTableRowModeBoolean(activeRowMode);

  return (
    <CancelOrderModalForm
      activeRow={activeRow}
      isOpen={isCancelOrderMode}
      title={cancelOrderDiscoTitle}
      cleanActiveRow={cleanActiveRow}
    />
  );
};

export default memo(CancelOrderModal);
