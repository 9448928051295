/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { postRequestCompleteActions } from 'core/api/contractManagment.api';

export interface IRequestComplete {
  isLoading: boolean
}

const initialState: IRequestComplete = {
  isLoading: false,
};

export const requestCompleteAction = createAsyncThunk(
  'requestCompleteAction',
  async (contractId: number, { rejectWithValue }) => {
    try {
      await postRequestCompleteActions(contractId);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const requestCompleteSlice = createSlice({
  name: 'requestComplete',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        requestCompleteAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(
          requestCompleteAction.rejected,
          requestCompleteAction.fulfilled,
        ),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const requestCompleteReducer = requestCompleteSlice.reducer;
