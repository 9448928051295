import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import RequestsActionsModals from 'pages/ContractManagment/ContractManagementList/RequestsActions/RequestsActionsModals';
import RequestsActionsMenuButton from 'pages/ContractManagment/ContractManagementList/RequestsActions/RequestsActionsMenuButton';

const RequestsActions = () => {
  const { setRowSelection } = useTableContext();

  const [actionType, setActionType] = useState('');

  const contractsData = useAppSelector(
    (state) => state.contractManagement.list.results,
  );

  const selectedContracts = useTableSelectedRows<IContract>(
    { rows: contractsData },
  );

  const isDisabled = isEmpty(selectedContracts);

  const onCloseRequestAcceptedModal = () => {
    setActionType('');
    setRowSelection({});
  };

  return (
    <div className="contract-management__request-acceptance">
      <RequestsActionsMenuButton
        isDisabled={isDisabled}
        onSelect={setActionType}
        selectedContracts={selectedContracts}
      />
      <RequestsActionsModals
        actionType={actionType}
        selectedContracts={selectedContracts}
        onCloseModal={onCloseRequestAcceptedModal}
      />
    </div>
  );
};

export default RequestsActions;
