import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRenewalSowBillingEngagementAccepted } from 'core/api/sales.api';

const renewalSowEngagementAcceptedAction = createAsyncThunk(
  'engagementAcceptedAction',
  async (orderId: number | string, { rejectWithValue }) => {
    try {
      await postRenewalSowBillingEngagementAccepted(orderId);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default renewalSowEngagementAcceptedAction;
